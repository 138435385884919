@charset "UTF-8";

.service-guide-wrap {
    margin-bottom:48px;
    padding:0 40px;
    .box {
        max-width:1300px + 80px;
        margin:0 auto;
        padding:16px 40px;
        border-radius:8px;
        background:#f4f4f4;
        .bullet-list {
            margin:0;
            text-align:center;
            .b-txt {
                //display:inline-block;
                // BTOCSITE-27102 [원격상담] 안내 문구 영역 추가 및 PC/노트북 원격지원 프로그램 다운로드 버튼 제공
                @include pc {
                    .pc-only {
                        display: inline-block;
                    }
                }
                .point {
                    color:$color-primary;
                }
            }
        }
    }
    @include mobile {
        margin-bottom:32px;
        padding:0 16px;
        .box {
            padding:16px;
            .bullet-list {
                text-align:left;
                // BTOCSITE-27102 [원격상담] 안내 문구 영역 추가 및 PC/노트북 원격지원 프로그램 다운로드 버튼 제공
                // .b-txt {
                //     display:block;
                // }
            }
        }
    }
}

// S : BTOCSITE-28875 PC원격상담 프로그램 다운로드용 텍스트 버튼 디자인 가이드 요청 건
.support.remote-call {
    .service-guide-wrap {
        margin-bottom:40px;
        .box {
            max-width:1380px;
            padding:26px 48px 20px;
            background:#fff;
            box-shadow:2px 4px 16px 0 rgba(0,0,0,.14);
            .service-guide-list {
                position:relative;
                display:flex;
                justify-content:space-between;
                &:after {
                    content:'';
                    position:absolute;
                    display:block;
                    top:50%;
                    left:50%;
                    width:1px;
                    height:47%;
                    transform:translateY(-50%);
                    background:#ddd;
                }
                li {
                    position:relative;
                    flex:1 1 50%;
                    min-height:102px;
                    padding:0 48px 0 120px;
                    &:before {
                        content:'';
                        position:absolute;
                        display:block;
                        top:0;
                        left:0;
                        width:96px;
                        height:96px;
                        border-radius:50%;
                    }
                    &:first-child {
                        &:before {
                            background:url(/lg5-common/images/CS/icon_wifi_64.svg) no-repeat center #f9f9f9;
                            background-size:48px 48px;
                        }
                    }
                    &:last-child {
                        padding:0 0 0 168px;
                        &:before {
                            left:48px;
                            background:url(/lg5-common/images/CS/icon_download_64.svg) no-repeat center #f9f9f9;
                            background-size:48px 48px;
                        }
                    }
                    .txtBox {
                        padding-top:7px;
                        em.point {
                            color:$color-primary;
                        }
                        .download {
                            margin-top:12px;
                            font-size:0;
                            .btn {
                                margin-right:12px;
                                padding:3px 36px 3px 16px;
                                vertical-align:middle;
                                &:after {
                                    content:'';
                                    position:absolute;
                                    top:50%;
                                    right:16px;
                                    width:16px;
                                    height:16px;
                                    margin-top:-8px;
                                    background:url(/lg5-common/images/CS/ic_download_16.svg) center no-repeat;
                                    background-size:100%;
                                } 
                                &:hover {
                                    &:after {
                                        background:url(/lg5-common/images/CS/ic_download_16_white.svg) center no-repeat;
                                    }
                                }
                            }
                            & > span {
                                font-size:14px;
                                line-height:24px;
                                color:#666;
                                vertical-align:middle;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width:821px) {
            .box {
                width:100%;
                padding:24px 16px;
                .service-guide-list {
                    display:block;
                    &:after {
                        content:none;
                    }
                    li {
                        min-height:76px;
                        padding:0 0 0 72px;
                        &:before {
                            width:60px;
                            height:60px;
                        }
                        &:first-child {
                            &:before {
                                background-size:32px 32px;
                            }
                            .txtBox {
                                border-bottom:1px solid #ddd;
                                .bullet-text {
                                    padding-bottom:16px;
                                }
                            }
                        }
                        &:last-child {
                            padding:0 0 0 72px;
                            margin-top:16px;
                            &:before {
                                left:0;
                                background-size:32px 32px;
                            }
                        }
                        .txtBox {
                            min-height:76px;
                            padding-top:0;
                            .bullet-text.mo-only {
                                margin-top:6px;
                            }
                        }
                    }
                }
            }    
        }
    }
}
// E : BTOCSITE-28875 PC원격상담 프로그램 다운로드용 텍스트 버튼 디자인 가이드 요청 건

.my-product-wrap {
    background:url(/lg5-common/images/CS/img-banner01.jpg) no-repeat;
    background-position:center 15%;
    background-size:cover;
    .box {
        max-width:1380 + 80px;
        margin:0 auto;
        // BTOCSITE-7947
        //padding:64px 40px;
        padding:64px 40px 34px 40px;
        .title {
            position:relative;
            height:52px;
            .tit {
                position:absolute;
                top:0;
                left:0;
                font-size:32px;
                font-weight:700;
                line-height:52px;
                white-space:nowrap;
                transform:translateX(0);
                transition:transform 0.4s, left 0.4s;
            }
            .btn-toggle {
                position:absolute; 
                right:0;
                bottom:3px;
                padding-right:20px;
                @include font-small;
                font-weight:500;
                &:after {
                    content:'';
                    position:absolute;
                    right:0;
                    top:4px;
                    width:16px;
                    height:16px;
                    background:url(/lg5-common/images/icons/btn-down-16-black.svg) no-repeat;
                    background-size:16px;
                    transform:rotate(0);
                    transition:transform 0.3s;
                }
            }
        }
        &.open {
            .title {
                .tit {
                    left:50%;
                    transform:translateX(-50%);
                }
                .btn-toggle:after {
                    transform:rotate(-180deg);
                }
            }
        }
    }
    &.no-item {
        .box {
            text-align:center;
            .txt {
                margin-bottom:16px;
                font-weight:700;
                @include font-large-1;
            }
        }
    }
    .my-product-slider {
        // BTOCSITE-7947
        // padding:32px 0 44px;
        padding:0;
        &.slide-solo {
            padding-bottom:0;
        }
        .indi-wrap {
            position:absolute;
            bottom:0;
            width:100%;
            .indi-conts {
                li:only-child {
                    display: none; // BTOCSITE-32320 보유 제품 1개 일 때 스와이프 버튼 일시적으로 노출되는 현상
                }
            }
        }
        .slide {
            &-controls {
                // BTOCSITE-7947
                margin-top:-2px;
                button {
                    display: none; // BTOCSITE-32320 보유 제품 1개 일 때 스와이프 버튼 일시적으로 노출되는 현상
                    &.prev {
                        left:-20px;
                    }
                    &.next {
                        right:-20px;
                    }
                }
            }
            &-content {
                margin:0 -12px;
            }
            &-track {
                font-size: 0; //여백 제거
                white-space:nowrap;
                transform: translate3d(0px, 0px, 0px);
                @include clearfix;
            }
            &-conts {
                display:inline-block;
                float:none !important;
                width:calc(100% / 3);
                vertical-align:top; 
                 // BTOCSITE-7947
                 padding: 28px 0 30px 0;
                
                 // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
             }
            &-box {
                display:block;
                position:relative;
                margin:0 12px;
                padding:48px 32px;
                border-radius:8px;
                background:#fff;
                box-shadow:0 8px 20px 0 rgba(34, 34, 34, 0.11);
                .category {
                    position:relative;
                    min-height:40px;
                    margin-bottom:12px;
                    @include font-medium;
                    font-weight:500;
                    .thumb {
                        display:inline-block;
                        position:relative;
                        width:40px;
                        height:40px;
                        margin-right:8px;
                        vertical-align: middle;
                        border-radius:50%;
                        background-color: #f4f4f4; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
                        img {
                            position:absolute;
                            top:50%;
                            left:50%;
                            transform:translate(-50%, -50%);
                            width:70%;
                            vertical-align:top;
                        }
                    }
                }   
                .name {
                    @include font-large-1;
                    font-weight:700;
                }
                .status {
                    position:absolute;
                    top:16px;
                    right:16px;
                    padding:5px 12px;
                    font-size:12px;
                    font-weight:500;
                    line-height:1;
                    border:1px solid rgba(0, 0, 0, 0.4);
                    border-radius:4px;
                }
                // BTOCSITE-7947 s
                &.regist-type {
                    text-align: center;
                    padding:20px 0 20px 0;
                    background: rgba(255,255,255,0.8);
                    .card-tit {
                        margin-bottom: 16px;
                        font-size: 20px;
                        line-height:30px;
                        color: #000;
                        font-weight: 700;
                    }
                    .icon-regist {
                        display: inline-block;
                        padding-top: 68px;
                        font-size: 16px;
                        line-height: 24px;
                        background: url("/lg5-common/images/CS/img_PD_regi_64.svg") no-repeat top center;
                        background-size: 64px 64px;
                    }
                    @include mobile{
                        padding:18px 0 19px 0;
                        .card-tit {
                            margin-bottom:7px;
                            font-size: 12px;
                            line-height:20px;
                        }
                        .icon-regist {
                            padding-top: 42px;
                            font-size: 13px;
                            line-height: 22px;
                            background: url("/lg5-common/images/CS/img_PD_regi_MO_128.svg") no-repeat top center;
                            background-size: 40px 40px;
                        }
                    }
                }
                // BTOCSITE-7947 e
            }
        }
    }
    @include tablet {
        .my-product-slider {
            .slide {
                &-conts {
                    width:calc((100vw - 80px) / 2);
                }
            }
        }
    }
    @include mobile {
        .box {
            // BTOCSITE-7947
            // padding:40px 16px;
            padding:40px 16px 10px 16px;
            &.open {
                .title {
                    .tit {
                        transform:translateX(0);
                        transition:none;
                    }
                }
            }
            .title {
                height:auto;
                padding-right:100px;
                .tit {
                    position:static;
                    white-space:normal;
                    font-size:20px;
                    line-height:28px;
                    transform:translateX(0);
                    .user-name {
                        display:block;
                    }
                }
                .btn-toggle {
                    bottom:0;
                }
            }
        }
        &.no-item {
            .box {
                .txt {
                    margin-bottom:12px;
                }
            }
        }
        .my-product-slider {
            // BTOCSITE-7947
            // padding:32px 0;
            &.slide-solo {
                padding-bottom:0;
            }
            .slide {
                &-controls {
                    display:none;
                }
                &-content {
                    margin:0 -16px 0 -6px;
                }
                &-conts {
                    width:calc((100vw - 16px) / 3 * 2);
                }
                &-box {
                    margin:0 6px;
                    padding:34px 20px 30px;
                    .category {
                        min-height:28px;
                        margin-bottom:12px;
                        .thumb {
                            width:28px;
                            height:28px;
                            margin-right:6px;
                        }
                    }   
                    .status {
                        top:12px;
                        right:12px;
                    }
                }
            }
        }
    }
}

.prod-selected-wrap {
    background-color: #AC4EAC; // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
    .box {
        position:relative;
        max-width:1380 + 80px;
        margin:0 auto;
        padding:24px 40px;
        .prod-info {
            font-size:0;
            .tit {
                color:#fff;
                @include font-medium;
                font-weight:700;
            }
            .product {
                display:inline-block;
                font-size:0;
                vertical-align:middle;
                li {
                    display:inline-block;
                    color:#fff;
                    @include font-medium;
                    font-weight:$font-weight-bold;
                    vertical-align:middle;
                    & + li {
                        position:relative;
                        margin-left:24px;
                        padding-left:40px;
                        &:before {
                            content:'';
                            position:absolute;
                            left:0;
                            top:6px;
                            width:16px;
                            height:16px;
                            background:url(/lg5-common/images/icons/icon-arr-24-wt.svg) no-repeat;
                            background-size:100%;
                        }
                        span {
                            display:inline-block;
                            margin-right:8px;
                            padding:4px 12px 6px;
                            font-size:12px;
                            font-weight:$font-weight-medium;
                            line-height:1;
                            vertical-align:top;
                            border-radius:4px;
                            border:1px solid rgba(255, 255, 255, .4);
                            transform:translateY(2px);
                        }
                    }
                }
            }
            .btn-add-product {
                display:inline-block;
                margin-left:24px;
                padding-right:20px;
                vertical-align:middle;
                background:url(/lg5-common/images/icons/icon-more-plus_16_w.svg) no-repeat;
                background-size:16px;
                background-position:right center;
                span {
                    color:#fff;
                    @include font-medium;
                    text-decoration:underline;
                }
            }
        }
        .prod-btn {
            position:absolute;
            right:40px;
            top:50%;
            transform:translateY(-50%);
            .btn-reset {
                &.reset {
                    position:relative;
                    padding-left:40px;
                    color:#fff;
                    border-color:#fff;
                    background:transparent;
                    &:before {
                        content:'';
                        position:absolute;
                        left:16px;
                        top:50%;
                        width:20px;
                        height:20px;
                        background:url(/lg5-common/images/icons/icon-reset-20-white.svg) no-repeat;
                        background-size:100%;
                        transform:translateY(-50%);
                    }
                }
            }
        }
    }
    @include mobile {
        .box {
            padding:20px 16px;
            text-align:center;
            .prod-info {
                .product {
                    display:block;
                    li {
                        & + li {
                            margin-left:6px;
                            padding-left:22px;
                            &:before {
                                top:4px;
                            }
                            span {
                                padding:3px 12px 5px;
                            }
                        }
                    }
                }
                .btn-add-product {
                    margin:10px 0 0;
                }
            }
            .prod-btn {
                position:static;
                margin-top:20px;
                transform:translateY(0);
            }
        }
    }
}

.prod-search-wrap {
    .keyword-search {
        .search-input {
            input[type=text] {
                text-transform:uppercase;
            }
        }
    }
    
    @include mobile {
        padding:0 16px;
    }
    // 키워드 검색
    .keyword-box {
        display:none;
        margin-bottom:64px;
        &.active {
            display:block;
        }
        .search-desc {
            display:none;
        }
    }

    // 카테고리 선택
    .category-box {
        display:none;
        &.active {
            display:block;
        }
        .category-list-wrap > .category-list {
            margin:-24px -12px 0;
            @include clearfix;
            & > li {
                float:left;
                width:25%;
                padding:24px 12px 0; 
                .box {
                    position:relative;
                    border-radius:8px;
                    box-shadow:2px 4px 16px 0 rgba(0, 0, 0, 0.14);

                    &:after {
                        content:"";
                        position:absolute;
                        right:16px;
                        bottom:16px;
                        width:24px;
                        height:24px;
                        border-radius:50%;
                        background:url(/lg5-common/images/icons/icon-plus-40.svg) no-repeat;
                        background-size:100%;
                        opacity:1;
                        transition:opacity .3s;
                        transition-delay: .7s;
                    }
                    .category {
                        > a {
                            display:block;
                            position:relative;
                            overflow:hidden;
                            border-radius:8px;
                            padding:40px 16px 64px;
                            z-index:1;
                            &:after {
                                content:'';
                                position:absolute;
                                overflow:hidden;
                                right:16px;
                                bottom:16px;
                                width:24px;
                                height:24px;
                                border-radius:50%;
                                //background:url(/lg5-common/images/icons/icon-plus-40.svg) no-repeat;
                                background-size:100%;
                                animation-duration: 1s;
                                animation-fill-mode: forwards;
                            }
                            p {
                                margin-bottom:16px;
                                font-size:22px;
                                font-weight:700;
                                line-height:32px;
                                text-align:center;
                            }
                            .category-thumb {
                                position:relative;
                                width:100%;
                                max-width:200px;
                                margin:0 auto;
                                &:before {
                                    content:'';
                                    display:block;
                                    padding-top:100%;
                                }
                                img {
                                    position:absolute;
                                    left:0;
                                    top:0;
                                    width:100%;
                                    height:100%;
                                }
                            }
                        }
                    }
                    .sub-category {
                        display:none;
                        position:absolute;
                        opacity:0;
                        left:0;
                        top:0;
                        width:100%;
                        height:100%;
                        padding:62px 0;
                        text-align:center;
                        animation-duration: 1s;
                        animation-fill-mode: forwards;
                        animation-name:delayedFadeOut;
                        z-index:1;
                        &-box {
                            height:100%;
                        }
                        &-list {
                            padding:0 16px;
                            li {
                                button {
                                    color:#fff;
                                    font-weight:700;
                                    @include font-large;
                                    &:hover {
                                        color:$color-primary;
                                        text-decoration:underline;
                                    }
                                }
                                & + li {
                                    margin-top:12px;
                                }
                            }
                        }
                        .btn-close {
                            position:absolute;
                            right:16px;
                            bottom:16px;
                            width:24px;
                            height:24px;
                            background:url(/lg5-common/images/icons/btn-close-24-bg-white.svg) no-repeat;
                            background-size:100%;
                            z-index:1;
                        }
                    }

                    &.off {                        
                        .category > a {
                            &:after {
                                animation-name:btnReduce;
                            }
                        }
                    }
                    &.on {
                        &:after {
                            opacity:0;
                        }
                        .category > a {
                            &:after {
                                background-image: none;
                                animation-name:btnExpand;
                            }
                        }
                        .sub-category {
                            display:block;
                            animation-name:delayedFadeIn;
                            @at-root {
                                .mobile &-list {
                                    position: relative; height:100%; overflow:auto;
                                    &::-webkit-scrollbar {
                                        width:3px;
                                    }
                                    &::-webkit-scrollbar-thumb {
                                        background:#a9a9a9;
                                    } 
                                }
                            }
                        }
                    }
                }
            }
        }
        @include tablet {
            .category-list-wrap > .category-list {
                & > li {
                    width:calc(100% / 3);
                }
            }
        }
        @include mobile {
            .category-list-wrap > .category-list {
                margin:-12px -6px 0;
                & > li {
                    width:50%;
                    padding:12px 6px 0; 
                    .box {
                        &:after {
                            right:12px;
                            bottom:12px;
                        }
                        .category {
                            > a {
                                padding:20px 12px 40px;
                                &:after {
                                    right:12px;
                                    bottom:12px;
                                }
                                p {
                                    margin-bottom:24px;
                                    font-size:16px;
                                    line-height:24px;
                                }
                                .category-thumb {
                                    max-width:120px;
                                }
                            }
                        }
                        .sub-category {
                            padding:32px 0;
                            &-list {
                                li {
                                    button {
                                        &:hover {
                                            color:#ff6276;
                                            text-decoration:underline;
                                        }
                                    }
                                    & + li {
                                        margin-top:10px;
                                    }
                                }
                            }
                            .btn-close {
                                right:12px;
                                bottom:12px;
                            }
                        }
                    }
                }
            }
        }
    }

    // 모델 선택
    .model-box {
        display:none;
        &.active {
            display:block;
        }
        .form-wrap {
            margin-bottom:48px;
            .forms {
                .tit {
                    padding-bottom:8px;
                    label {
                        @include font-small;
                        font-weight:500;
                    }
                }
                .conts {
                    @include clearfix;
                    .select-wrap {
                        float:left;
                        width:calc(50% - 12px);
                        & + .select-wrap {
                            float:right;
                            margin:0;
                        }
                    }
                }
            }
        }
        .keyword-search {
            display:none;
            margin-bottom:32px;
            .search-desc {
                display:block;
            }
        }
        .no-data {
            .word {
                color:#000;
                font-weight:700;
            }
        }
        .model-slider {
            margin-top:-24px;
            .slide-controls {
                button {
                    &.prev {
                        left:-24px;
                    }
                    &.next {
                        right:-24px;
                    }
                }
            }
            .indi-wrap {
                position:absolute;
                bottom:0;
                width:100%;
            }
            .slide-content {
                margin:0 -12px;
            }
            .slide-track {
                padding:24px 0 36px;
                @include clearfix;
                .ui_carousel_slide {
                    float:left;
                    padding:0 12px;
                    & > div {
                        @include clearfix;
                        margin:24px -12px 0;
                        &:first-child {
                            margin-top:0;
                        }
                    }
                }
            }
            .slide-conts {
                float:left;
                width:25%;
                height:1px;
                min-height:120px;
                padding:0 12px;
                .item{
                    display:block;
                    position:relative;
                    height:100%; 
                    text-align:center;
                    font-size:0;
                    border-radius: 8px;
                    box-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.14);
                    &:before {
                        content:'';
                        display:inline-block;
                        width:0;
                        height:100%;
                        vertical-align:middle;
                    }
                    &:hover:after {
                        content:'';
                        position:absolute;
                        left:0;
                        top:0;
                        width:100%;
                        height:100%;
                        border-radius:8px;
                        border:2px solid $color-primary;
                    }
                    &.no-model {
                        .category span {
                            position:relative;
                            padding-right:20px;
                            text-decoration:underline;
                            &:after {
                                content:'';
                                position:absolute;
                                right:0;
                                top:5px;
                                width:16px;
                                height:16px;
                                background:url(/lg5-common/images/icons/btn-arr-16-black.svg) no-repeat;
                            }
                        }
                    }
                    .info {
                        display:inline-block;
                        width:100%;
                        padding:32px;
                        vertical-align:middle;
                    }
                    .category {
                        margin-top:4px;
                        @include font-medium;
                
                    }
                    .name {
                        @include font-medium;
                        font-weight:700;
                        .word {
                            color:$color-primary;
                        }
                    }
                }
            } 
        }
        @include mobile {
            .form-wrap {
                margin-bottom:32px;
                .forms {
                    .tit {
                        padding-bottom:6px;
                    }
                    .conts {
                        margin:0;
                        .select-wrap {
                            width:calc(50% - 6px);
                        }
                    }
                }
            }
            .model-slider {
                margin-top:-12px;
                .slide-controls {
                    button {
                        &.prev {
                            left:-16px;
                        }
                        &.next {
                            right:-16px;
                        }
                    }
                }
                .slide-content {
                    margin:-12px -16px 0;
                    .slide-track {
                        padding:12px 0 36px;
                        .ui_carousel_slide {
                            padding:0 16px;
                            & > div {
                                margin:12px -6px 0;
                                &:first-child {
                                    margin-top:0;
                                }
                            }
                        }
                    }
                    .slide-conts {
                        width:50%;
                        min-height:80px;
                        padding:0 6px;
                        .item {
                            .info {
                                padding:16px;
                            }
                            .category {
                                span {
                                    display:none;
                                }
                            }
                            &.no-model {
                                .category span {
                                    display:inline-block;
                                    &:after {
                                        top:4px;
                                    }
                                }
                            }
                        }
                    }    
                }
            }
        }
    }
}

.bullet-list.top-border {
    .b-txt .point {
        color:$color-primary;
    }
}

@keyframes btnExpand {
    0% {
        opacity:0.8;
        transform:scale(1);
        background:#000;
        outline:0;
    }
    100% {
        opacity:0.8;
        transform:scale(50);
        background:#000;
        outline:0;
    }
}

@keyframes btnReduce {
    0% {
        opacity:0.8;
        transform:scale(50);
        background:#000;
        outline:0;
    }
    90% {
        opacity:0.8;
        background:#000;
    }
    100% {
        opacity:1;
        transform:scale(1);
    }
}
  
@keyframes delayedFadeIn {
    0% {
        opacity: 0
    }
    95% {
        opacity: 1
    }
    100% {
        opacity: 1
    }
}

@keyframes delayedFadeOut {
    0% {
        opacity: 1
    }
    90% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}


@charset "UTF-8";

/* button */
.btn-group {
    margin:64px auto 0;
    @include mobile {
        margin-top: 32px;
        padding: 0 16px;
    }
}
.btn-group.bestshop {
    @include mobile {
        margin: 0 !important;
        padding: 0 !important;
    }  
} // 210622 추가
.btn-delete {
    position:absolute;
    top:50%;
    right:54px;
    width:20px;
    height:20px;
    margin-top:-10px;
    background:url('/lg5-common/images/icons/icon-delete-20.svg') no-repeat 0 0;

    @include mobile {
        right: 68px;
    }
}

.btn-search {
    position:absolute;
    top:0;
    right:0;
    width:54px;
    height:48px;
    background:center/20px 20px url(/lg5-common/images/icons/icon-search-20.svg) no-repeat;

    @include mobile {
        width:68px;
        height:46px;
    }
}

.keyword-search {
    position: relative;
    text-align:center;
    z-index:2;

    .btn-delete {
        display: none;
    }
    &.default {
        .search-box {
            width:100%;
        }
        .search-input {
            width:100%;
            input[type=text] {
                padding:10px 54px 10px 15px;
                border:1px solid #ddd;
                border-radius:8px;
                box-shadow:none;
                &:disabled {
                    border-color:#eee;
                    background:#8f8f8f;
                }
            }
            &.clear {
                input[type=text] {
                    padding-right:80px;
                }
            }
            @include mobile {
                input[type=text] {
                    padding:11px 68px 11px 11px;
                }
                &.clear {
                    input[type=text] {
                        padding-right:95px;
                    }
                }
            }
        }
        .search-layer {
            box-shadow:0.1px 4px 16px 0 rgba(0, 0, 0, 0.14);
            border-radius:8px;
        }
    }
    &.on {
        z-index:11; // BTOCSITE-26494 [검색창] 입력필드 노출 인기검색어 영역 클릭 시 오인식 수정
        .search-layer {
            display:block;
        }
        .search-input {
            position:relative;
            z-index:2;
            input[type=text] {
                border-color:transparent;
                box-shadow:none;
            }
        }
    }
    .search-box {
        display:inline-block;
        position:relative;
        width:443px;
        text-align:left;
        @include mobile {
            display:block;
            width:100%;
        }
    }
    .search-input {
        position:relative;
        width:443px;
        margin:0 auto;
        text-align:left;
        input[type=text] {
            width:100%;
            padding:10px 54px 10px 24px;
            @include font-medium;
            border-radius:24px;
            border:0 none;
            background:#fff;
            box-shadow:2px 4px 12px 0 rgba(0, 0, 0, 0.14);
            // text-transform:uppercase;
        }
        .btn-delete {
            display:none;
        }
        &.clear {
            input[type=text] {
                padding-right:80px;
            }
        }
        
        @include mobile {
            width:100%;
            &.clear {
                input[type=text] {
                    padding-right:95px;
                }
            }
        }
    }
    .search-layer {
        display:none;
        position:absolute;
        overflow:auto;
        bottom:auto;
        top:0;
        right:0;
        left:0;
        margin-top:0;
        z-index:1;
        padding:69px 24px 56px;
        border-radius:28px;
        background-color:#fff;
        box-shadow:2px 4px 12px 0 rgba(0, 0, 0, 0.14);
        .btn-close {
            position:absolute;
            right:24px;
            top:auto;
            left:auto;
            bottom:16px;
            width:24px;
            height:24px;
            background:url("/lg5-common/images/icons/btn-close-24-gray.svg") no-repeat 0 0;
        }
        .tit {
            display:block;
            margin-bottom:12px;
            font-weight:$font-weight-bold;
            font-size:16px;
            line-height:30px;
        }
        .keyword-list {
            & > ul {
                display:none;
                & > li {
                    margin-top:8px;
                    font-size:0;
                    &:first-child {
                        margin-top:0;
                    }
                    a {
                        display:inline-block;
                        max-width:calc(100% - 22px);
                        font-size:16px;
                        line-height:26px;
                        vertical-align:middle;
                        @include textEllipsis;
                    }
                    .btn-delete {
                        display:inline-block;
                        position:static;
                        margin-left:8px;
                        margin-top:4px;
                        vertical-align:middle;
                        width:14px;
                        height:14px;
                        background:url('/lg5-common/images/icons/icon-delete-20.svg') no-repeat 0 0;
                        background-size:14px;
                    }
                }
            }
            .no-keyword {
                color:#666;
                font-size:16px;
                line-height:30px;
                text-align:center;
            }
        }
        .autocomplete-box {
            display:none;
            font-size:0;

            .keyword-tit {
                display: block;
                position:relative;
                padding:10px 0 14px;
                font-size:16px;
                font-weight:bold;
                color:#000;
            }
            .keyword-list {
                ul {
                    margin-left:-12px;
                    margin-right:-12px;
                    li {
                        padding:4px 12px;
                        &:hover {
                            border-radius:16px;
                            background-color:#f7f7f7; // BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지
                        }
                    }
                }
            }
            .keyword-list:not(:only-child) {
                border-top:1px solid #ddd;
                border-bottom:1px solid #ddd;
                ul {
                    margin:0;
                    li {
                        position:relative;
                        margin-top:0;
                        margin-left:-24px;
                        margin-right:-24px;
                        padding:0;
                        &:hover {
                            border-radius:0;
                            background:transparent;
                        }
                        a { 
                            display:block;
                            max-width:none;
                            padding:13px 24px 14px;
                            transition:.3s background-color ease-in-out;
                            &:hover, &:focus {
                                background-color:#f7f7f7; // BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지
                            }
    
                            .list-head {
                                font-size:0;
                                .list-category,.list-sub-category {
                                    display: inline-block;
                                    font-size:14px;
                                    line-height:24px;
                                    vertical-align: top;
                                }
                                .list-category {
                                    color:$color-primary;
                                }
                                .list-sub-category {
                                    position:relative;
                                    color:#666;
                                    margin-left:7px;
                                    padding-left:8px;
    
                                    &:before {
                                        content:"";
                                        display:block;
                                        position:absolute;
                                        top:50%;
                                        left:0;
                                        width:1px;
                                        height:14px;
                                        background: #ddd;
                                        transform:translateY(-50%);
                                    }
                                }
                            }
                            .list-desc {
                                overflow:hidden;
                                text-overflow:ellipsis;
                                white-space:nowrap;
                                font-size:16px;
                                color:#000;
                                line-height:1.63;
                            }
                        }
                    }
                }
                .no-keyword {
                    line-height:80px;
                }
            }
            .btn-list-all {
                display: inline-block;
                position:absolute;
                bottom:20px;
                left:24px;
                padding-right:20px;

                &:after {
                    content:"";
                    display:block;
                    position:absolute;
                    top:50%;
                    right:0;
                    width:16px;
                    height:16px;                
                    background: url("/lg5-common/images/icons/btn-arr-16-black.svg") no-repeat 100% center;
                    background-size:100% 100%;
                    transform:translateY(-50%);
                }

                span {
                    display: inline-block;
                    font-size:16px;
                    padding-bottom:2px;
                    color:#000;
                    border-bottom:1px solid #000;
                }
            }
            
            @include mobile {
                .keyword-tit {
                    font-size:14px;
                }
                .keyword-list {
                    ul > li {
                        a { 
                            .list-head {
                                .list-category,.list-sub-category {
                                    font-size:13px;
                                }
                                .list-sub-category {
                                    display:none;
                                }
                            }
                            .list-desc {
                                font-size:13px;
                            }
                        }
                    }
                }
            }
        }
        .keyword-box {
            @include clearfix;
            .recently-keyword {
                float:left;
                width:calc(50% - 12px);
            }
            .popular-keyword {
                float:right;
                width:calc(50% - 12px);
            }
            .keyword-list {
                margin-left:-12px;
                margin-right:-12px;
                li {
                    padding:4px 12px;
                    &:hover {
                        border-radius:16px;
                        background-color:#f7f7f7; // BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지
                    }
                }
            }
        }
        @include mobile {
            &:before {
                content:none;
            }   
        }
    }
    .search-more {
        position:absolute;
        left:calc(100% + 16px);
        top:23px;
        white-space:nowrap;
        transform:translateY(-50%);
        @include mobile {
            position:static;
            margin-top:16px;
            text-align:center;
            transform:translateY(0);
        }
    }
    .search-error {
        display:none;
        margin-top:6px;
        color:#f00;
        @include font-small;
        vertical-align:top;
        &::before {
            content:' ';
            display:inline-block;
            width:20px;
            height:20px;
            margin:2px 4px 0 0;
            background: url("/lg5-common/images/icons/icon-error.svg") center no-repeat;
            vertical-align:top;
            @include mobile {
                margin-top:1px;
            }
        }
    }
    .search-desc {
        margin-top:12px;
        @include font-medium
    }
}

.keyword-search {
    &.type-A {
        .input-wrap {
            input {
                border-radius: 8px;
                border: solid 1px #ddd;
                box-shadow: none;
            }
        }
    }
    &.type-B {
        .input-wrap {
            input {
                box-shadow: none;
                border-radius: 8px;
            }
        }
        @include mobile {
            .input-wrap {
                input[type=text] {
                    border-radius: 8px;
                    border: solid 1px #ddd;
                }
            }
        }
    }
    @include mobile {
        .input-wrap {
            input[type=text] {
                padding:12px 95px 12px 24px;
                border-radius:28px;
            }
            
        }
        &.type-A {
            .input-wrap {
                input {
                    border-radius: 8px;
                    padding: 12px 43px 12px 12px;
                }
            }
        }
    }
}   

.form-wrap {
    .form-row {
        display:flex;
        flex-direction: row;
    }
    .form-col {
        flex:1;
        & + .form-col {
            margin-left:24px;
        }
    }
    .forms {
        .tit {
            width:calc(18.4% + 24px);
        }
        .conts {
            width:calc(100% - (18.4% + 24px));
            .form-text {
                @include font-medium;
            }
        }

        &.align-type-col {
            .tit {
                display: block;
                width:100%;
            }
            .conts {
                display: block;
                width:100%;
            }
        }
    }
    @include mobile {
        .form-row {
            display:flex;
            flex-direction: column;
        }
        .form-col {
            flex:1;
            & + .form-col {
                margin-top:32px;
                margin-left:0;
            }
        }
        .forms {
            .tit {
                width:100%;
            }
            .conts {
                margin-top:12px;
                width:100%;
            }
        }
    }
}

.req {
    &::after {
        content:'*';
        display:inline-block;
        margin-left:1px;
        color:$color-primary;
        font-size:inherit;
        line-height:inherit;
        vertical-align:top;
    }
}

.input-wrap {
    input {
        &[readonly='readonly'] {    
            background:#eee;
            border-color:#8f8f8f;
        }
        &[readonly=''] {    
            background:#eee;
            border-color:#8f8f8f;
            &::placeholder {
                color:#8f8f8f; // BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지
            }
        }
        &[disabled='disabled']:-ms-input-placeholder {
            color:#8f8f8f; // BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지
        }
        &[disabled='']:-ms-input-placeholder {
            color:#8f8f8f; // BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지
        }
        &[readonly='readonly']:-ms-input-placeholder {
            color:#8f8f8f; // BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지
        }
        &[readonly='']:-ms-input-placeholder {
            color:#8f8f8f; // BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지
        }
    }
}
.text-form {
    textarea {
        &[readonly='readonly']:-ms-input-placeholder {
            color:#8f8f8f; // BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지
        }
        &[readonly='']:-ms-input-placeholder {
            color:#8f8f8f; // BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지
        }
        &[disabled='disabled']:-ms-input-placeholder {
            color:#8f8f8f; // BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지
        }
        &[disabled='']:-ms-input-placeholder {
            color:#8f8f8f; // BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지
        }
        &[readonly=''] {
            background:#eee;
            border-color:#8f8f8f;
        }
        &[disabled=''] {
            background:#eee;
            border-color:#8f8f8f;
        }
        &[readonly=''] + .inner-text {
            background:#eee;
            border-color:#8f8f8f;
        }
        &[disabled=''] + .inner-text {
            background:#eee;
            border-color:#8f8f8f;
        }
        & + .inner-text {
            display: inline-block;
            width: 100%;
            padding: 0 16px 8px;
            border: 1px solid #ddd;
            border-top: 0;
            border-radius: 0 0 8px 8px;
            vertical-align: top;
            @include font-small;
            text-align: right;
        }
    }
    input[type=text] {
        &:focus {
            outline:none;
            border-color:#000;
        }
        & + .inner-text {
            color:#666;
            em {
                color:#000;
            }
        }
    }
}

.thumb-rdo-wrap {
    label {
        display:block;
        cursor:pointer;
        .img {
            display:block;
            overflow:hidden;
            margin-bottom:12px;
            border-radius:8px;
            img {
                width:100%;
            }
        }
        .txt {
            display:block;
            position:relative;
            padding-left:30px;
            @include font-medium;
            &:before {
                content: ' ';
                position: absolute;
                top: 4px;
                left: 2px;
                width: 20px;
                height: 20px;
                background: url("/lg5-common/images/bg-input-default.svg") center no-repeat;
            }
            &:after {
                content: ' ';
                position: absolute;
                top: 7px;
                left: 5px;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background: transparent;
            }
        }
        input[type=radio] {
            @include blind;
            &:checked ~ .txt:before {
                background: url("/lg5-common/images/bg-input-checked.svg") center no-repeat;
            }
            &:checked ~ .txt:after {
                background:$color-primary;
            }
        }
    }
    @include mobile {
        label {
            .img {
                margin-bottom:10px;
            }
            .txt {
                &:before {
                    top:2px;
                }
                &:after {
                    top:5px;
                }
            }
        }
    }
}

.rdo-list-wrap {
    .rdo-list {
        margin:-20px 0 0 -24px;
        font-size:0;
        li {
            display:inline-block;
            // min-width:152px;
            padding:20px 0 0 24px;
            vertical-align:top;
            &.block {
                width:100%;
                max-width:none;
                .rdo-wrap {
                    vertical-align:middle;
                    & ~ .input-wrap {
                        width:420px;
                        margin-left:12px;
                        vertical-align:middle;
                    }
                }
            }
        }
    }
    .err-msg {
        display:none;
        margin-top:8px;
    }
    &.w-auto {
        .rdo-list {
            li {
                min-width:auto;
            }
        }
    }
    &.thumb-type {
        .rdo-list {
            margin-top:-32px;
            li {
                width:20%;
                padding-top:32px;
                &.block {
                    width:100%;
                    max-width:none;
                }
            }
        }
    }
    &.btn-type {
        .rdo-list {
            margin-top:-16px;
            li {
                width:20%;
                padding-top:16px;
                &.block {
                    width:100%;
                    max-width:none;
                }
                .rdo-wrap { 
                    display:block;
                    label {
                        width:100%;
                    }
                }
            }
        }
    }
    //BTOCSITE-3200 출장서비스 예약 > 추가정보_건물유형 수정 등 
    &.w-full {
        .rdo-list {
            li {
                display: block;
                width:100%;
                padding-top:10px;
            }
        }
    }
    @include tablet {
        &.thumb-type {
            .rdo-list li {
                width:calc(100% / 3);
            }
        }
        &.btn-type {
            .rdo-list li {
                width:calc(100% / 3);
            }
        }
    }
    @include mobile {
        .rdo-list {
            margin:-18px -6px 0;
            li {
                width:50%;
                min-width:0;
                padding:18px 6px 0;
                &.block {
                    .rdo-wrap {
                        & ~ .input-wrap {
                            width:100%;
                            margin:12px 0 0 0;
                        }
                    }
                }
            }
        }
        &.w-auto {
            .rdo-list {
                li {
                    width:auto;
                    min-width:50%;
                    &.block {
                        width:100%;
                        min-width:auto;
                    }
                }
            }
        }
        &.thumb-type {
            .rdo-list {
                margin-top:-24px;
                li {
                    width:50%;
                    padding-top:24px;
                }
            }
        }
        &.btn-type {
            .rdo-list {
                margin-top:-8px;
                li {
                    width:50%;
                    padding-top:8px;
                }
            }
        }
    }
}

.calendar-area {
    font-size:0;
    .calendar-info {
        display:inline-block;
        // width:230px + 28px;
        flex:1;
        padding-right:28px;
        vertical-align:top;
        .text {
            @include font-medium;
            word-break:keep-all;
            .date {
                color:$color-primary;
            }
        }
        & + .calendar-box {
            display:inline-block;
            width:888px;
            vertical-align:top;
        }
    }
    .info {            
        & > li {
            @include font-small;
            & + li {
                margin-top:4px;
            }
            &:before {
                content:'';
                display:inline-block;
                width:12px;
                height:12px;
                border-radius:50%;
                vertical-align:middle;
            }
            &.black:before {
                border:1px solid #000;
                background:#fff;
            }
            &.red:before {
                background:$color-primary;
            }
            &.white:before {
                border:1px solid #000;
                background:#fff;
            }
        }
    }
    .text {
        +.info {
            margin-top:24px;
        }
    }
    .calendar-box {
        display:block;
        width:100%;
        font-size:0;
        .box-inner {
            display:inline-block;
            vertical-align:top;
            &.date {
                width:392px;
            }
            &.time {
                width:472px;
                margin-left:24px;
            }
            .box-tit {
                margin-bottom:16px;
                .tit {
                    @include font-small;
                    font-weight:$font-weight-bold;
                }
            }
            table {
                table-layout: fixed;
                width:100%;
                th ,td {
                    @include font-small;
                    text-align: center;
                }
                td {
                    button {
                        display:inline-block;
                        font-weight:bold;
                        @include font-small;
                        border:1px solid #000;
                    }
                    &.disabled {
                        button {
                            font-weight:normal;
                            color:#8f8f8f; // BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지
                            border:0 none;
                            cursor:default;
                        }
                    }
                    &.choice {
                        button {
                            color: #fff;
                            border:0 none;
                            background:$color-primary;
                        }
                    }
                }
                &.tb-calendar {
                    th {
                        height:50px;
                        padding:0 6px 18px;
                        &.sun {
                            color:$color-primary;
                        }
                    }
                    td {
                        padding:5px 0;
                        button {
                            width:32px;
                            height:32px;
                            border-radius:16px;
                        }
                    }
                }
                &.tb-timetable {
                    th {
                        @include font-medium;
                        text-align:left;
                    }
                    th, td {
                        padding:8px 0;
                        button {
                            width:100%;
                            line-height:28px;
                            border-radius: 18px;
                        }
                    }
                }
            }
            .err-msg {
                display:none;
                margin-top:8px;
            }
        }
    }

    .date-wrap {
        height:468px;
        padding:36px 48px 40px;
        border-radius:8px;
        box-shadow:2px 4px 16px 0 rgba(0, 0, 0, 0.14);
        .month-wrap {
            position:relative;
            margin-bottom:40px;
            padding:0 32px;
            .month {
                display:block;
                @include font-large-1;
                line-height:32px;
                font-weight:700;
                text-align:center;
            }
            .arrow {
                position:absolute;
                top:0;
                width: 32px;
                height: 32px;
                background: url(/lg5-common/images/icons/btn-arr-32x32-black.svg) 0 50% no-repeat;
                background-size: 100% 100%;
                &.prev {
                    left:0;
                    transform:rotate(180deg);
                }
                &.next {
                    right:0;
                }
                &.disabled {
                    display:none;
                }
            }
        }
    } 
    .time-wrap {
        height:468px;
        padding:25px 32px;
        border-radius:8px;
        box-shadow:2px 4px 16px 0 rgba(0, 0, 0, 0.14);
        &.night {
            height: auto; //BTOCSITE-45859 홈페이지 (야간) 출장 예약 서비스 준비
        }
        .box-desc {
            padding:183px 0;
            @include font-medium;
            text-align:center;
        }
    }
    
    @media screen and (min-width: 1285px) {
        display: flex;
    }
    @media screen and (max-width: #{$desktop-s-width}) {
        .calendar-info {
            display:block;
            width:100%;
            margin-bottom:40px;
            padding-right:0;
            .info {         
                margin-top:16px;   
                font-size:0;
                & > li {
                    display:inline-block;
                    & + li {
                        margin-left:16px;
                    }
                }
            }
            & + .calendar-box {
                display:block;
                width:100%;
            }
        }
        .calendar-box {
            .box-inner {
                &.date {
                    width:45%;
                }
                &.time {
                    width:55%;
                    margin-left:0;
                    padding-left:24px;
                }
            }
        }
	}
    @include mobile {
        .calendar-box {
            .box-inner {
                &.date {
                    width:100%;
                }
                &.time {
                    width:100%;
                    margin-top:40px;
                    padding-left:0;
                }
                .box-tit {
                    margin-bottom:8px;
                }
            }
        }
    }
}

//BTOCSITE-64827 고객지원 > 출장예약 > 모바일 > 날짜/시간 선택 UI 변경
.calendar-area.pc-renewal {
    .calendar-box {
        .box-inner {
            &.date {
                width: calc(47% - 10px);

                @include mobile {
                    width: 100%;
                }
            }
            &.time {
                width: calc(53% - 10px);
                margin-left: 20px;
                padding-left: 0;
                
                @include mobile {
                    width: 100%;
                    margin-left: 0;
                }
            }

            //달력
            table.tb-calendar {
                th {
                    height: 40px;
                    padding: 0 6px 0;
                    font-size: 16px;
                    line-height: 24px;
                    color: #666;
                    font-weight: 400;
                    &:first-child {
                        color: $color-primary;
                    }
                    &:last-child {
                        color: #327AF4;
                    }
                    @include mobile {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }

                td {
                    padding: 4px 0;
                    @include mobile {
                        font-size: 14px;
                        line-height: 20px;
                    }

                    &:first-child {
                        &.disabled button {
                            color: $color-primary;
                            opacity: 0.3;
                        }
                    }
                    &:last-child {
                        &.disabled button {
                            color: #327AF4;
                            opacity: 0.3;
                        }
                    }

                    &.choice {
                        button {
                            border-color: $color-primary;
                            color: white;
                        }
                    }
                    button {
                        width: 40px;
                        height: 40px;
                        border-radius: 40px;
                        font-size: 16px;
                        line-height: 24px;
                        color: #000;
                        &.disabled {
                            color: #666;

                        }
                        @include mobile {
                            width: 32px;
                            height: 32px;
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    } 
    //범례
    .calendar-info {
        padding-right: 20px;

        .info {
            li {
                font-size: 16px;
                line-height: 24px;
                color: #666;
                @include mobile {
                    font-size: 14px;
                    line-height: 20px;
                }
                &::before {
                    margin-bottom: 3px;
                }
                
                &.gray:before {
                    background:#D9D9D9;
                }
                
                &.evening:before {
                    margin-bottom: 4px;
                    background: url('/lg5-common/images/CS/icon/icon_evening.svg') no-repeat 0 0 / auto 14px;
                }
            }
        }
        & + .calendar-box {
            width:1098px;
        }


    }
    //달력
    .date-wrap {
        border: 1px solid #AAA;
        border-radius: 16px;
        box-shadow:none;
        height: auto;
        min-height: 430px;
        padding: 32px 40px 32px 40px;
        @include mobile {
            padding: 32px 30px 32px 30px;
        }
        .month-wrap {
            margin-bottom: 24px;
            
            .month {
                font-size: 24px;
                line-height: 32px;
                @include mobile {
                    font-size: 22px;
                    line-height: 30px;
                }
            }
        }
    }
    //시간
    .time-wrap-new {
        border: 1px solid #AAA;
        border-radius: 16px;
        box-shadow:none;
        height: auto;
        min-height: 430px;
        padding: 30px 40px 40px 40px;
        @include mobile {
            padding: 32px 30px 32px 30px;
        }
       
        .box-tit {
            margin-bottom: 32px;
            .tit {
                color: #000; // BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지
                font-size: 16px; 
                font-weight: 700;  
                line-height: 24px;
            }
            .info {
                color: #666;
                font-size: 16px; 
                line-height: 24px;
                margin-top: 10px;
                @include mobile {
                    font-size: 14px; 
                    line-height: 20px;
                }
            }
        }
        .box-cont {
            dl {
                display: flex;
                justify-content: space-between;
                @include mobile {
                    flex-direction: column;
                }

                &:not(:first-child) {
                    margin-top: 10px;
                }

                @include mobile {
                    &:not(:first-child) {
                        margin-top: 30px;
                    }
                }

                dt {
                    width: 80px;
                    display: flex;
                    align-items: flex-start;
                    text-align: left;
                    font-size: 16px;
                    line-height: 24px;
                    color: #666;
                    @include mobile {
                        font-size: 14px;
                        line-height: 20px;
                        margin-bottom: 10px;
                    }
                }
                dd {
                    width: 100%;
                    margin: 0 -10px -10px 0;
                    ul {
                        li {
                            display: inline-block;
                            width: calc(25% - 10px);
                            margin-right: 10px;
                            margin-bottom: 10px;
                            //선택
                            &.choice {
                                button {
                                    background-color: $color-primary;
                                    border-color:  $color-primary;
                                    color: white;

                                    //야간
                                    &.evening::after {
                                        background: url('/lg5-common/images/CS/icon/icon_evening_on.svg') no-repeat 0 0 / auto 14px;
                                    }
                                }
                            }
                        }
                    }
                    button {
                        font-family:'Noto Sans', sans-serif;
                        border: 1px solid #ddd;
                        border-radius: 6px;
                        padding: 6px 0;
                        font-size: 16px;
                        line-height: 24px;
                        color: #000;
                        width: 100%;
                        min-width: 60px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        white-space: nowrap;

                        //야간
                        &.evening::after {
                            content: '';
                            display: block;
                            width: 14px;
                            height: 14px;
                            background: url('/lg5-common/images/CS/icon/icon_evening.svg') no-repeat 0 0 / auto 14px;
                            margin-left: 4px;
                        }
                        //선택불가
                        &:disabled {
                            border-color: #ededed;
                            color: #b3b3b3;
                        }

                        @include mobile {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                    @include mobile {
                        margin-bottom: -10px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: #{$desktop-s-width}) {
        .calendar-info {
            position: relative;
            width: 100%;
            margin-bottom: 40px;
        }
        .calendar-info + .calendar-box {
            width: 100%;
            margin-bottom: 40px;
        }
    }
}


//BTOCSITE-64827 고객지원 > 출장예약 > 모바일 > 날짜/시간 선택 UI 변경
.calendar-area.mo-renewal {
    .calendar-box {
        .box-inner {
            //날짜
            &.date {
                .calendar-info {
                    margin-top: 25px;
                }
            }
            //시간
            &.time {
                margin-top: 24px;
                padding-top: 24px;
                border-top: 1px solid #E4E4E4;
                .calendar-info {
                    margin-top: 25px;
                }
            }

            table.tb-calendar {
                th {
                    padding: 0 6px 0;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: #666;
                    &:first-child {
                        color: $color-primary;
                    }
                    &:last-child {
                        color: #327AF4;
                    }
                }
                td {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: #666; 

                    &:first-child {
                        &.disabled button {
                            color: $color-primary;
                            opacity: 0.3;
                        }
                    }
                    &:last-child {
                        &.disabled button {
                            color: #327AF4;
                            opacity: 0.3;
                        }
                    }
                    button {
                        @include mobile {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                    &.choice {
                        button {
                            border: 1px solid $color-primary;
                            background: $color-primary;
                        }
                    }
                }
            }
        }
    }

    //범례
    .calendar-info {
        position: relative;
        .info {
            text-align: right;
            li {
                font-size: 12px;
                line-height: auto;
                color: #666;
                &::before {
                    margin-bottom: 3px;
                }
                
                &.gray:before {
                    background:#D9D9D9;
                }
                
                &.evening:before {
                    margin-bottom: 4px;
                    background: url('/lg5-common/images/CS/icon/icon_evening.svg') no-repeat 0 0 / auto 14px;
                }
            }
        }
    }

    //날짜
    .date-wrap {
        border: none;
        box-shadow:none;
        padding: 0 8px;
        .month-wrap {
            margin-bottom: 24px;
            
            .month {
                font-size: 20px;
                line-height: 25px;
            }
            .week {
                display: block;
                font-size: 12px;
                line-height: 16px;
                color: #666;
                text-align: center;
                margin-top: 6px;
            }
            .arrow {
                width: 24px;
                height: 24px;
            }
            .arrow.prev {
                left: 38px;
            }
            .arrow.next {
                right: 38px;
            }
        }
    }

    //시간
    .time-wrap-new {
        .box-tit {
            margin-bottom: 20px;
            .tit {
                color: #000; // BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지
                font-size: 16px; 
                font-weight: 700;  
                line-height: 20px;
            }
            .info {
                color: #666;
                font-size: 12px; 
                line-height: 18px;
                margin-top: 10px;
            }
        }
        .box-cont {
            dl {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
               
                &:first-child {
                    margin-bottom: -8px;
                }
                &:not(:first-child) {
                    margin-top: 16px;
                }

                dt {
                    width: 80px;
                    display: flex;
                    align-items: flex-start;
                    text-align: left;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 18px;
                    color: #666;
                    margin-bottom: 8px;
                    
                }
                dd {
                    width: 100%;
                    margin: 0;
                    ul {
                        margin-right: -6px;
                        li {
                            display: inline-block;
                            width: calc(25% - 6px);
                            margin-right: 6px;
                            margin-bottom: 8px;
                            //선택
                            &.choice {
                                button {
                                    background-color: $color-primary;
                                    border-color:  $color-primary;
                                    color: white;

                                    //야간
                                    &.evening::after {
                                        background: url('/lg5-common/images/CS/icon/icon_evening_on.svg') no-repeat 0 0 / auto 14px;
                                    }
                                }
                            }
                        }
                    }
                    button {
                        font-family:'Noto Sans', sans-serif;
                        border: 1px solid #ddd;
                        border-radius: 6px;
                        padding: 6px 0;
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 19px;
                        color: #000;
                        width: 100%;
                        min-width: 60px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        white-space: nowrap;

                        //야간
                        &.evening::after {
                            content: '';
                            display: block;
                            width: 14px;
                            height: 14px;
                            background: url('/lg5-common/images/CS/icon/icon_evening.svg') no-repeat 0 0 / auto 14px;
                            margin-left: 4px;
                        }
                        //선택불가
                        &:disabled {
                            border-color: #ededed;
                            color: #b3b3b3;
                        }
                    }
                }
            }
        }
    }
}

.form-infos {
    @include mobile {
        margin-bottom: 40px;
    }
}

.rdo-wrap.btn-type3 input + label:before, .rdo-wrap.btn-type3 input:checked + label {
    color:$color-primary;
}
.rdo-wrap.btn-type3 input + label:before, .rdo-wrap.btn-type3 input:checked + label:after {
    background: none;
    border-color:$color-primary;
}

@include mobile {
    .rdo-wrap.btn-type3 input + label span {
        font-size:14px;
    }
}

// board add file list
.addfile-wrap{
    line-height:32px;
    .addfile-button{
        @include font-small;
        background:url("/lg5-common/images/icons/icon-download-32.svg") no-repeat 100% center;
        padding-right:36px;
    }
    .addfilebox-list{
        display:none;
        z-index:100;
        position:absolute;
        margin: 12px 0 0 0;
        padding: 4px 0 10px;
        border-radius: 4px;
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
        background-color: #ffffff;
        text-align:left;
        li {
            padding:17px 24px;
            a {
                color:#808080;
                @include font-medium;
                font-weight:$font-weight-medium;
            }
            &:hover a {
                color:#000;
            }
        }
    }
    &.open {
        .addfilebox-list{
            display:block;
        }
    }
}

//add file new
.image-file-wrap {
    @include clearfix;
    .file-item {
        float:left;
        width:93px;
        .file-image {
            position:relative;
            margin-bottom:10px;
            .file-preview {
                overflow:hidden;
                width:93px;
                height:93px;
                text-align:center;
                border:1px solid #8F8F8F;
                border-radius:8px;
                background:url(/lg5-common/images/icons/icon-no-image.svg) no-repeat;
                background-size:100%;
                &:before {
                    content:'';
                    display:inline-block;
                    width:0;
                    height:100%;
                    vertical-align:middle;
                }
                img {
                    max-width:100%;
                    max-height:100%;
                }
            }
            .file-btns {
                position:absolute;
                bottom:-8px;
                right:-8px;
                .btn-upload {
                    display:block;
                    width:24px;
                    height:24px;
                    border-radius:50%;
                    background:url(/lg5-common/images/icons/btn-imgadd-24.svg) no-repeat;
                    cursor:pointer;
                }
                input[type="file"]:focus + .btn-upload {
                    outline:1px solid #000;
                }
                .btn-file-del, // BTOCSITE-4357 스탠바이미 PDP 내 고객문의 Q&A 생성 요청
                .btn-del {
                    display:none;
                    width:24px;
                    height:24px;
                    border-radius:50%;
                    background:url(/lg5-common/images/icons/btn-imgdelet-24.svg) no-repeat;
                }
                input[type="file"] {
                    @include blind;
                }
            }
        }
        .file-name{
            .name {
                display:block;
                width:100%;
                text-align:center;
                color:#000;
                font-size:14px;
                line-height:24px;
                background:transparent;
                &::placeholder{
                    color:#000;
                }
            }
        }
        &.on{
            .file-preview {
                background:none;
            }
            .file-btns {
                .btn-upload {
                    display:none;
                }
                .btn-del {
                    display:block;
                }
            }
        }
        & + .file-item {
            margin-left:24px;
        }
    }
}

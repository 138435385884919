@charset "UTF-8";

/* list summary */
// BTOCSITE-9921 공통 페이지네이션 수정으로 인한 해당 속성 제거
// .pagination {
//     .page_num {
//         margin:0 10px;

//         a, button  {
//             color:#666;
//         }
//     }
// }

.sorting-wrap {
    padding-bottom:12px;
    @include clearfix;
    .total {
        float:left;
        font-weight:700;
        @include font-small;
        .count {
            color:$color-primary;
        }
    }
    .sort-box {
        float:right;
        .sort-select-wrap {
            float:left;
            margin-left:40px;
            &:first-child {
                margin-left:0;
            }
        }
        .unit {
            @include font-small;
        }
    }
    @include mobile {
        .total {
            font-size:13px;
        }
        .sort-box {
            .sort-select-wrap {
                margin-left:20px;
            }
        }
        &.row-type {
            .total {
                float:none;
            }
            .sort-box {
                float:none;
                margin-top:16px;
                @include clearfix;
            }
        }
    }
}

/* notice list */
.board-list-wrap {
    .board-list {
        > li {
            margin-top:8px;
            border-radius:4px;
            background:#fff;    
            &:first-child {
                margin-top:0;
            }
            > a {
                display:flex;
                position:relative;
                padding:32px 278px 32px 56px;
                font-size:0;
            }
        }
        .flag-wrap {
            display:inline-block;
            margin:4px 12px 0 0;
            vertical-align:top;
            .flag {
                height:20px;
                padding:3px 8px;
                line-height:1;
            }
        }
        .title {
            font-size:16px;
            font-weight:400;
            line-height:24px;
            vertical-align:top;
            @include textEllipsis;
        }
        .infos {
            position:absolute;
            top:32px;
            right:56px;
            @include clearfix;
            > li {
                float:left;
                font-size:14px;
                line-height:24px;
                & + li {
                    position:relative;
                    margin-left:16px;
                    padding-left:17px;
                    &:before {
                        content:'';
                        position:absolute;
                        left:0;
                        top:6px;
                        width:1px;
                        height:12px;
                        background:#ddd;
                    }
                }
            }
            .view {
                padding-left:28px;
                background:url('/lg5-common/images/icons/icon-view.svg') no-repeat left center/24px ;
            }    
        }
        
        @include mobile {
            > li {
                margin-top:4px;
                border-radius:0;    
                > a {
                    display:block;
                    padding:24px 20px;
                    font-size:0;
                }
            }
            .tag {
                margin:0 0 4px 0;
            }
            .title {
                max-height:48px;
                font-weight:500;
                white-space:normal;
                @include textEllipsisClamp(2);
            }
            .infos {
                position:static;
                margin-top:10px;
                > li {
                    float:left;
                    font-size:12px;
                    line-height:16px;
                    & + li {
                        margin-left:12px;
                        padding-left:13px;
                        &:before {
                            top:4px;
                            height:10px;
                        }
                    }
                }
                .view {
                    padding-left:24px;
                    background:url('/lg5-common/images/icons/icon-view.svg') no-repeat left center/20px ;
                }  
            }
        }
    }
}

/* list */
.list-title {
    position:relative;
    margin-bottom:12px;
    .tit {
        font-size:20px;
        font-weight:$font-weight-bold;
        line-height:30px;
    }
    .btn-link {
        position:absolute;
        right:0;
        bottom:0;
    }
    @include mobile {
        .btn-link {
            bottom: 50%;
            transform: translateY(50%);
        }
    }
}
.list-wrap {
    .tit {
        display:block;
        @include font-medium;
        font-weight:700;
        a, button {
            font-size:inherit;
            font-weight:inherit;
            line-height:inherit;
            vertical-align:top;
            text-align:left;
        }
    }
    .desc {
        color:#767676;
        @include font-small;
    }
    .options {
        margin-top:8px;
        color:#000;
        @include font-small;
        @include clearfix;
        & > li {
            position:relative;
            display:inline;
            &:after {
                content:'';
                display:inline-block;
                width:1px;
                height:10px;
                margin:7px 8px 0;
                background:#ddd;
            }
            &:last-child {
                margin:0;
                padding:0;
                &:after {
                    content:none;
                }
            }
        }
    }
    @include mobile {
        .desc {
            margin-top:2px;
        }
        .options {
            & > li {
                &:after {
                    margin:6px 6px 0;
                }
            }
        }
    }
    
    .list {
        border-top:1px solid #666;
        > li {
            position:relative;
            border-bottom:1px solid #ddd;
            &.video-type {
                .item {
                    padding-right:112px;
                }
                .icon-movie {
                    position:absolute;
                    top:50%;
                    right:0px;
                    width:48px;
                    height:48px;
                    transform:translateY(-50%);
                    background:url(/lg5-common/images/icons/btn-play-32-black.svg) no-repeat;
                    background-size:48px;
                }
            }
            .item {
                display:block;
                padding:24px 0;
                .tit {
                    @include textEllipsis;
                }
                // BTOCSITE-32213 고객지원 제품 문제해결 페이지 내 검색창 결과 개선
                .cnt_txt {
                    margin-top: 8px;
                    @include font(16px,26px);
                    @include textEllipsisClamp(2);
                }
            }
        }
        // BTOCSITE-32213 고객지원 제품 문제해결 페이지 내 검색창 결과 개선
        @include tablet {
            & > li {   
                .item {
                    .cnt_txt {
                        @include textEllipsisClamp(3);
                    }
                }
            }
        }

        @include mobile {
            & > li {   
                &.video-type {
                    .item {
                        padding-right:60px;
                    }
                    .icon-movie {
                        right:12px;
                        width:24px;
                        height:24px;
                        background-size: cover;
                    }
                }
                .item {
                    padding:16px 0;
                    // BTOCSITE-32213 고객지원 제품 문제해결 페이지 내 검색창 결과 개선
                    .cnt_txt {
                        @include font(13px,19px);
                        @include textEllipsisClamp(4);
                    }
                }
            }
        }
    }
    &.icon-type {
        .list {
            border-top: 2px solid #222;
            > li {
                a {
                    position:relative;
                    padding-left:120px;
                    &:before {
                        content:'';
                        position:absolute;
                        left:0;
                        top:50%;
                        width:80px;
                        height:80px;
                        background-repeat:no-repeat;
                        background-size:100%;
                        transform:translateY(-50%);
                    }
                    .flag-wrap {
                        margin-bottom:2px;
                    }
                    .options {
                        margin-top:12px;
                    }
                }
                &.menu-email a:before {
                    background-image:url('/lg5-common/images/icons/icon_email-inquiry.svg');
                }
                &.menu-request a:before {
                    background-image:url('/lg5-common/images/icons/icon_request-reservation.svg');
                }   
                &.menu-remote a:before {
                    background-image:url('/lg5-common/images/icons/icon_remote-reservation.svg');
                }
                &.menu-video a:before {
                    //background-image:url('/lg5-common/images/icons/icon_video-reservation.svg');
                    background-image:url('/kr/support/images/icon/icon_video-reservation.svg');
                }
                &.menu-engineer a:before {
                    //background-image:url('/lg5-common/images/icons/icon_engineer-reservation.svg');
                    background-image:url('/kr/support/images/icon/icon_engineer-reservation.svg');
                }
                &.menu-installation a:before {
                    //background-image:url('/lg5-common/images/icons/icon_installation-reservation.svg');
                    background-image:url('/kr/support/images/icon/icon_installation-reservation.svg');
                }
                &.menu-center a:before {
                    //background-image:url('/lg5-common/images/icons/icon_center-reservation.svg');
                    background-image:url('/kr/support/images/icon/icon_center-reservation.svg');
                }
                &.menu-resolution a:before {
                    //background-image:url('/lg5-common/images/icons/icon-product-resolution-64.svg');
                    background-image:url('/kr/support/images/icon/icon_product-resolution-64.svg');
                    width: 64px;
                    height: 64px;
                    border-radius: 50%;
                    background-color: #f4f4f4;
                    left: 24px;
                }
            }
        }
        @include mobile {
            .list {
                > li {
                    a {
                        padding-left:80px;
                        &:before {
                            left:0;
                            top:16px;
                            width:60px;
                            height:60px;
                            transform:translateY(0);
                        }
                        .options {
                            li {
                                float:none;
                                margin:0;
                                padding:0;
                                &:before {
                                    display:none;
                                }
                            }
                        }
                    }
                    &.menu-resolution a:before {
                        width: 40px;
                        height: 40px;
                        left: 12px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
    &.thumb-type {
        .list {
            > li {
                .item {
                    width:100%;
                    display:flex;
                    align-items: center;
                    justify-content: flex-start;
                    &-image {
                        position: relative;
                        width:120px;
                        height: 80px;
                        vertical-align:middle;
                        border-radius:4px;
                        overflow:hidden;
                        img {
                            position: absolute;
                            top:50%;
                            left:50%;
                            width:100%;
                            height: 100%;
                            transform: translate(-50%, -50%);
                        }
                    }
                    &-conts {
                        padding-left:32px;
                        vertical-align:middle;
                        width: calc(100% - 120px);
                        .flag-wrap {
                            margin-bottom:4px;
                        }
                        .tit {
                            @include font-large;
                            font-weight:700;
                        }
                        .options {
                            margin-top:8px;
                        }
                    }
                }
                @include mobile {
                    .item {
                        padding:16px 0;
                        &-image {
                            width: 105px;
                            height: 70px;
                        }
                        &-conts {
                            padding-left:16px;
                            width: calc(100% - 105px);
                            .flag-wrap {
                                margin-bottom:2px;
                            }
                            .tit {
                                font-weight:500;
                                @include textEllipsisClamp(2);
                                white-space: normal;
                            }
                            .options {
                                margin-top:3px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* card list */
.card-list-wrap {
    .card-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        font-size:0;
        > li {
            display:inline-block;
            margin-top:24px;
            vertical-align:top;
            margin-left:57px;
        }
        .item {
            display:block;
            &-image {
                overflow:hidden;
                position:relative;
                height:0;
                margin-bottom:12px;
                padding-top:100%;
                border-radius:8px;
                background:#ddd;
                img {
                    position:absolute;
                    left:0;
                    top:50%;
                    width:100%;
                    transform:translateY(-50%);
                }
            }
            &-conts {
                .flag-wrap {
                    margin-bottom:2px;
                }
                .tit {
                    margin-bottom:8px;
                    font-size:16px;
                    font-weight:500;
                    line-height:26px;
                    @include textEllipsisClamp(2);
                }
                .infos {
                    @include clearfix;
                    > li {
                        float:left;
                        color:#767676;
                        font-size:14px;
                        line-height:24px;
                        & + li {
                            position:relative;
                            margin-left:8px;
                            padding-left:9px;
                            &:before {
                                content:'';
                                position:absolute;
                                left:0;
                                top:6px;
                                width:1px;
                                height:14px;
                                background:#ddd;
                            }
                        }
                    }
                }   
            }
        }
        &.type-tips {
            > li {
                border-radius: 8px;
                box-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.14);
            }
            .item {
                &-image {
                    margin-bottom: 0;
                    border-radius: 8px 8px 0 0;
                }
                &-conts {
                    padding: 24px;
                }
            }
        }
    }
    @media screen and (min-width: 1024px) {
        .card-list {
            > li {
                width: calc(25% - 43px);
                &:nth-child(4n+1) {
                    margin-left: 0;
                }
                &:nth-child(-n+4) {
                    margin-top: 0;
                }
            }
            &.type-tips {
                > li {
                    margin-left: 24px;
                    width: calc(25% - 18px);
                    &:nth-child(4n+1) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
        .card-list {
            > li {
                width: calc(33.3333% - 38px);
                &:nth-child(-n+3) {
                    margin-top: 0;
                }
                &:nth-child(3n+1) {
                    margin-left: 0;
                }
            }
            &.type-tips {
                > li {
                    margin-left: 24px;
                    width: calc(33.3333% - 16px);
                    &:nth-child(3n+1) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
    @include mobile {
        .card-list { 
            margin:0;
            > li {
                width:100%;
                padding:0;
                margin-left: 0;
                &:first-child {
                    margin-top:0;
                }
            }
            .item {
                &-conts {
                    .tit {
                        font-size:14px;
                        line-height:22px;
                    }
                    .infos {
                        li {
                            line-height:22px;
                            & + li {
                                &:before {
                                    width:1px;
                                    height:10px;
                                }
    
                            }
                        }
                    }
                }
            }
            &.type-tips {
                > li {
                    margin-top: 12px;
                }
                .item {
                    &-conts {
                        padding: 20px;
                    }
                }
            }
        }
    }
}

/* video list */
.video-list-wrap {
    .video-list {
        & > li {
            padding:24px 0;
            border-bottom:1px solid #ddd;
            & > a {
                display:block;
                font-size:0;
            }
        }
        .video-thumb {
            display:inline-block;
            position:relative;
            overflow:hidden;
            width:120px;
            height:80px;
            vertical-align:middle;
            border-radius:8px;
            &:before {
                content:'';
                position:absolute;
                left:0;
                top:0;
                width:100%;
                height:100%;
                background:#000;
                opacity:0.4;
                z-index:1;
            }
            &:after {
                content:'';
                position:absolute;
                top:50%;
                left:50%;
                width:32px;
                height:32px;
                background:url(/lg5-common/images/btn-video-play-s.svg) no-repeat 0 0;
                background-size:100% 100%;
                transform:translate(-50%, -50%);
                z-index:1;
            }
            img {
                position:relative;
                width:100%;
                height:auto;
                top:50%;
                left:0;
                transform:translateY(-50%);
            }
        }
        .video-cont {
            display:inline-block;
            @include calc('width', 100%, 120px);
            padding-left:24px;
            vertical-align:middle;
            .info-box {
                margin-bottom:8px;
                font-size:0;
                display:flex;
                
                .topic, .category {
                    display:inline-block;
                    position:relative;
                    @include font-small;
                    vertical-align:middle;
                }
                .topic {
                    flex:1;
                    margin-left:8px;
                    padding-left:9px;
                    color:#666;
                    @include textEllipsis;
                    &::before {
                        content: '';
                        display: block;
                        position:absolute;
                        left:0;
                        top: 50%;
                        transform: translateY(-50%);
                        width:1px;
                        height:14px;
                        background-color:#ddd;
                    }
                }
                .category {
                    color:$color-primary;
                }
            }
            .tit {
                font-weight:$font-weight-medium;
                font-size:16px;
                line-height:26px;
                font-weight:bold;
                @include textEllipsisClamp(2);
                .keyword {
                    color:$color-primary;
                }
            }
        } 
    }
    @include mobile {
        .video-list {
            > li {
                padding:16px 0;
            }
            .video-thumb {
                width:105px;
                height:70px;
            }
            .video-cont {
                @include calc('width', 100%, 120px);
                padding-left:12px;
                .info-box {
                    flex-direction: column;
                    .topic, .category {
                        display:block;
                    }   
                    .topic {
                        margin:4px 0 0 ;
                        padding:0;
                        &:before {
                            content:none;
                        }
                    }
                }
            } 
        }
    }
}
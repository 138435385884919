@charset "UTF-8";

.container {
    .cont-wrap {
        padding-top:0;
        .section {
            margin-top:0;
            .tit-wrap {
                &.type-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding-bottom:16px;
                    border-bottom:2px solid #000;
                    .tit {
                        padding-bottom:0;
                        border-bottom:0;
                    }
                    .btn-link {
                        font-size:14px;
                        &:after {
                            display: inline-block;
                            position: static;
                            vertical-align: middle;
                            margin-left:8px;
                        }
                    }
                    @include tablet {
                        display: block;
                        .btn-link {
                            margin-top:17px;
                        }
                    }
                }
            }
            & ~ .section {
                margin:120px 0 0;
            }
            &[style*="display: none"]:first-child + .section {
                margin:0;
            }
            &-box {
                // padding-right:16.9%;
                & + .section-box {
                    margin-top:80px;
                }
                .sub-tit {
                    margin-bottom:24px;
                    font-size:24px;
                    line-height:1.63;
                    font-weight:$font-weight-bold;
                }
            }
            &.related-info-list {
                margin-top: 120px;
                .list-wrap {
                    &.icon-type {
                        .list {
                            >li {
                                a {
                                    .options {
                                        position: absolute;
                                        right: 0;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        margin-top: 0;
                                    }
                                }
                            }
                        }
                    }
                }
                @include mobile {
                    margin-top: 60px;
                    padding-top: 60px;
                    border-top: 12px solid #f4f4f4;
                    .list-title {
                        .tit {
                            font-size: 16px;
                        }
                    }
                    .list-wrap {
                        &.icon-type {
                            .list {
                                >li {
                                    a {
                                        .options {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.pc-connect-section {
                @include pc {
                    margin-top:0;
                }
            }
        }
        .form-infos + .section,
        .form-infos + .section[style*="display: none"] + .section {
            margin:0;
        }
    }
    .step-box {
        display:none;
        &.active {
            display:block;
            & + .step-box.active {
                margin:80px 0 0;
            }
        }
        & ~ .btn-group {
            display:none;
        }
        .step-btn-wrap {
            margin-top:24px;
            text-align:center;
        }
        .form-infos {
            margin-top: 8px;
            margin-bottom: 64px;
        }
        .agree-all-chk {
            .desc {
                display: block;
                margin-left: 0;
            }
        }
    }

    *:not([style*="display: none"]) ~ .cont-wrap {
        padding-top:70px;

        .form-infos {
            &.first-type {
                margin-top:-62px;
            }
        }
    }
    
    
    @include mobile {
        .cont-wrap {
            .section {
                padding:0 16px;
                border:0;

                & ~ .section {
                    margin:60px 0 0;
                    padding-top:32px;
                    border-top:12px solid #f4f4f4;
                }
                &[style*="display: none"] + .section {
                    margin:0;
                    border:0;
                }
                &-box {
                    padding-right:0;
                    & + .section-box {
                        margin-top:40px;
                    }
                    .sub-tit {
                        font-size:20px;
                        margin-bottom:24px;
                    }
                }
                .tabs-wrap .tabs {
                    padding:0;
                }
            }
        }
        *:not([style*="display: none"]) ~ .cont-wrap {
            padding-top:60px;
    
            .form-infos {
                &.first-type {
                    //margin-top:-47px;
                    margin-top:0px; // BTOCSITE-3216 add
                }
            }
            /* BTOCSITE-3216 add */
            .step-box {
                .section:first-child {
                    .form-infos {
                        &.first-type {
                            margin-top:-47px;
                        }
                    }
                    
                }
            }
            /* //BTOCSITE-3216 add */
        }
        .step-box {
            &.active {
                & + .step-box.active {
                    margin:60px 0 0;
                    padding-top:32px;
                    border-top:12px solid #f4f4f4;
                }
            }
            & ~ .bullet-list {
                margin-left:16px;
                margin-right:16px;
            }
        }
        
    }
}

.double-section {
    margin:0 auto;
    &:first-child {
        margin-top:0;
    }
    .sections {
        display:flex;
    }
    .item {
        display:flex;
        flex-direction:column;
        flex:1;
        width:50%;
        margin:0;
    }
    .btn-group {
        margin-top:32px;
    }
    @include pc {
        .item {
            margin-left: 24px;
            &:first-child {
                margin-left: 0;
            }
        }
    }
    @include mobile {
        display:block;
        .sections {
            display:block;
        }
        .item {
            display:block;
            position: relative;
            width:100%;
            margin-top:60px;
            padding-top:67px;
            &:before {
                content:"";
                position: absolute;
                top:0;
                left:50%;
                width:calc(100% + 32px);
                height:12px;
                transform:translateX(-50%);
                background: #f4f4f4;
            }

            &:first-child {
                margin-top:0;
                border-top:0;
                padding-top:0;
                &:before {
                    display: none;
                }
            }
        }
        .btn-group {
            margin-top:40px;
            padding:0;
        }
    }
}
@charset "UTF-8";

/* layer popup */
.popup-wrap {
    .pop-dscl {
        font-size:14px;
        margin-bottom:40px;
    }
    &.align-between {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .pop-conts {
            flex:1;
        }
    }
    .pop-conts.support {
        &.align-center {
            text-align: center;
        }
        .input-info .tit-wrap {
            margin-bottom:24px;
        }
        .info-desc {
            margin-bottom:48px;
            color:#616161;
            font-size:16px;
            @include mobile {
                margin-bottom:40px;
                font-size:14px;
                line-height:18px;
                text-align:center;
            }
        }
        .text-box {
            font-size: 14px;
            margin-bottom: 0;
        }
        .paragraph {
            &-1 {
                margin-top: 18px;
            }
        }
        &-section {
            margin-top: 32px;
            &.type-A {
                margin-top: 64px;
            }
            &.top-border {
                padding-top: 32px;
                margin-top: 28px;
                border-top: 1px solid #ddd;
            }
            &:first-child {
                margin-top: 0;
            }
            @include mobile {
                &.type-A {
                    margin-top: 56px;
                }
            }
        }
        .modal-gray-box {
            border-radius: 4px;
            background-color: #f4f4f4;
            padding: 40px 32px;
            line-height: 1.5;
            &-title {
                font-size: 20px;
                font-weight: bold;
                line-height: 1.5;
                padding-bottom: 24px;
                margin-bottom: 24px;
                border-bottom: 1px solid #ddd;
                .tit {
                    font-size: inherit;
                }
            }
            &.type-A {
                padding: 16px 40px;
                color: #616161;
                border-radius: 8px;
                @include clearfix;
                .text {
                    float: left;
                }
                [class^="btn"] {
                    color: #212121;
                    font-size: 14px;
                    float: right;
                    line-height: 1;
                }
            }
            @include mobile {
                &.type-A {
                    padding: 16px 20px;
                    .text {
                        width: 156px;
                    }
                }
            }
        }
        
    }
    @include mobile {
        .pop-conts {
            .modal-gray-box {
                padding: 40px 20px;
                &-title {
                    font-size: 16px;
                    padding-bottom: 32px;
                    margin-bottom: 32px;
                    text-align: center;
                    .tit {
                        font-size: inherit;
                    }
                }
            }
        }

        &#certificationPopup,
        &#agreePrivacyPopup,
        &#fileSendToEmail,
        &#ratesWarrantyGuidePopup,
        &#choiceEngineerPopup,
        &#reservationTimePopup,
        &#solutionsPopup,
        &#cancelServicePopup {
            position: fixed !important;
            left:0 !important;
            top:0 !important;
            margin-left:0 !important;
            transform:none !important;
            margin-top:0 !important;
            min-height:auto !important;
            width:100% !important;
            height:100% !important;
        }
    }





    .pop-conts {        
        .section {
            margin-top:80px;
            &:first-child {
                margin-top:0;
            }
            .tit-wrap {
                margin-bottom:24px;
                .tit {
                    @include font-large-1;
                }
            }
            @include mobile {
                margin-top:60px;
                &:first-child {
                    margin-top:0;
                }
            }
        }


        // 설치 전 주의사항 및 사용법 팝업
        &.file-popup {
            .tb-col {
                th {
                    text-align:center;
                }
                .model-list {
                    position:relative;
                    overflow:hidden;
                    max-height:24px;
                    padding-right:22px;
                    transition: max-height .8s cubic-bezier(0, 1, 0, 1) -.1s;
                    &.on {
                        max-height:9999px;
                        transition-timing-function: cubic-bezier(0.5, 0, 1, 0);
                        transition-delay: 0s;
                    }
                    .list {
                        margin:0 -8px;
                        @include clearfix;
                    }
                    li {
                        float:left;
                        width:25%;
                        padding:0 8px;
                        &.on {
                            color:$color-default;
                            font-weight:$font-weight-bold;
                        }
                    }
                    .btn-more {
                        position:absolute;
                        top:2px;
                        right:2px;
                        width:20px;
                        height:20px;
                        background:url(/lg5-common/images/icons/btn-more-20.svg) no-repeat;
                    }
                }
                .file-wrap {
                    @include clearfix;
                    p {
                        float:left;
                        margin:4px 0 0;
                    }
                    .btn {
                        float:right;
                    }
                }
            }
            #driverDatilContent {
                p {
                    @include font-small;    
                    white-space:pre-line;
                }
                img {
                    max-width:100%;
                }
            }
            @include tablet {
                .tb-col {
                    .model-list li {
                        width:calc(100% / 3);
                    }
                }
            }
            @include mobile {
                .tb-col {
                    .model-list li {
                        width:50%;
                    }
                    .file-wrap { 
                        p {
                            float:none;
                            margin:0 0 8px;
                        }
                        button {
                            float:none;
                        }
                    }
                }
            }
        }

        // 콘텐츠 만족도 평가
        &.survey-pop {
            padding-top:12px;
        }

        // 엔지니어 선택 
        &.engineer-pop {
            .engineer-slider {
                .slide-controls {
                    button {
                        &.prev {
                            left:-24px;
                        }
                        &.next {
                            right:-24px;
                        }
                    }
                }
                .indi-wrap {
                    position:absolute;
                    bottom:0;
                    width:100%;
                }
                .slide-content {
                    margin:-12px -12px 0;
                    padding:0 0 12px;
                }
                .slide-track {
                    @include clearfix;
                }
                .slide-conts {
                    float:left;
                    width:25%;
                    padding:12px 12px 24px;
                }
                .engineer-box {
                    border-radius:8px;
                    box-shadow:2px 4px 16px 0 rgba(0, 0, 0, 0.14);
                    label {
                        display:block;
                        position:relative;
                        padding:32px;
                        text-align:center;
                        cursor:pointer;
                        .img {
                            width:100%;
                            margin-bottom:24px;
                            img {
                                width:100%;
                            }
                        }
                        .tit {
                            @include font-large;
                            font-weight:700;
                        }
                        .desc {
                            margin-top:4px;
                            @include font-small;
                        }
                    }
                    input {
                        @include blind;
                        &:checked + label:before {
                            content:'';
                            position:absolute;
                            left:0;
                            top:0;
                            width:100%;
                            height:100%;
                            border-radius:8px;
                            border:2px solid #EC455A;
                        }
                    }
                }
            }
            @include mobile {
                .slide-controls {
                    display:none;
                }
                .engineer-slider {
                    .slide-conts {
                        width:calc((100vw - 16px) / 3 * 2);
                        padding:0 8px 24px;
                    }
                    .engineer-box {
                        .label {
                            padding:24px;
        
                            .img {
                                margin-bottom:24px;
                            }
                            .tit {
                                font-size:14px;
                            }
                            .desc {
                                margin-top:4px;
                                font-size:14px;
                            }
                        }
                    }
                }
            }
        }

        // 예약 일시 변경
        &.reservation-time-pop {
            overflow-x:hidden;
            padding-top:8px;
            .tit-wrap {
                position:relative;
                .info {           
                    position:absolute;
                    right:0;
                    top:3px;
                    font-size:0;
                    & > li {
                        display:inline-block;
                        @include font-small;
                        & + li {
                            margin-left:24px;
                        }
                        &:before {
                            content:'';
                            display:inline-block;
                            width:12px;
                            height:12px;
                            border-radius:50%;
                            vertical-align:middle;
                        }
                        &.black:before {
                            background:#222;
                        }
                        &.red:before {
                            background:#ec455a;
                        }
                    }
                }
            }
            @include mobile{
                .tit-wrap {
                    .info {
                        position:static;
                        margin-top:12px;
                        & > li {
                            & + li {
                                margin-left:20px;
                            }
                        }
                    }
                }
            }
        }

        // 예약 취소
        &.cancel-pop {
            margin-bottom:32px;
            .guide-box {
                margin-bottom:8px;
                padding:24px;
                background:#f4f4f4;
                border-radius:10px;
                .desc {
                    font-size:24px;
                    line-height:1.45;
                    color:#222;
                    text-align:center;
                    border-radius:8px;
                    .type {
                        color:$color-primary;
                    }
                }
            }
            // S : BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지
            .form-wrap {
                .text-form {
                    textarea + .inner-text {
                        margin-top:-1px;
                    }
                }
            }
            // E : BTOCSITE-77476 [웹앱 접근성 개선활동] 전시_마이페이지
            @include mobile {
                margin-bottom:0;
                .guide-box {
                    padding:22px 18px;

                    .desc {
                        font-size:18px;
                    }
                }
            }
        }
    }
}

/* page init popup */ 
.ui_modal_wrap {
    &.init-type {
        display: flex;
        margin:0;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        background: rgba(0,0,0,0.7);

        .popup-wrap {
            position: relative;
            height: 660px;
            max-height:none;
            border-radius:8px;

            &.small{
                left: inherit !important;
                top: initial !important;
                transform: initial !important;
                height: auto; 
                .pop-conts {
                    padding: 32px 40px 32px;
                }  
    
            }

            &.active + .active {
                margin-left:24px;
            }

            .pop-conts {
                overflow:auto;
                height:calc(100% - 78px - 116px);

                &.support {
                    > .mCustomScrollBox {
                        > .mCSB_container {
                            // padding: 0 40px;
                        }
                    }
                    @include mobile {
                        padding: 0 16px;
                    }
                }
                &.mCustomScrollbar {
                    overflow:hidden;
                    // padding:0;
                    > .mCustomScrollBox {
                        > .mCSB_container {
                        //    padding:48px 40px 0
                        }
                        > .mCSB_scrollTools {
                           right:10px;
                        }
                    }
                }
            }
            
            .img-box {
                a {
                    display: block;
                }

                .pc-img {
                    display: block;
                    @include mobile {
                        display: none;
                    }
                }
                .mo-img {
                    display: none;

                    @include mobile {
                        display: block;
                    }
                }
                img {
                    width:100%;
                }
            }
            
            .headline {
                margin-bottom:24px;
                line-height:1.63;
                &+.text-cont {
                    margin-top:32px;
                }
            }
            .text-cont {
                font-size:16px;
                line-height:1.63;
            }
                
            .video-container {
                .video-box {
                    position: relative;
                    height:0;
                    padding-top:56.25%;
                    margin-bottom:32px;
                    iframe, video {
                        position: absolute;
                        top:0;
                        left:0;
                        width:100%;
                        height:100%;
                    }
                }
                .video-tit {
                    font-size:24px;
                    font-weight:bold;
                    color:#000;
                    line-height:1.42;
                }
    
                .video-figure {
                    overflow:auto;
                    height:160px;
                    margin-top:15px;
                    padding:3px 0;
                    border:1px solid #ddd;
                    border-radius:10px;
                    box-sizing:border-box;

                    &.mCustomScrollBox {
                        overflow:hidden;
                    }
    
                    .figure-inner {
                        padding:24px;
                        font-size:14px;
                        line-height:1.71;
                        word-break: break-all;
                        white-space: pre-line;
                    }
                }

            }
            .btn-wrap {
                font-size:0;
                margin-top:24px;
                text-align: center;

                .btn {
                    margin:0 4px;
                }
            }

            .btn {
                &.full {
                    width:100%;
                    max-width:none;
                }
            }
        }
        .pop-footer {
            padding: 32px 40px 32px;
            &.check-type{
                padding:26px 40px 30px;
       
                input[type=checkbox] + label::after{
                    top: 55%;
                    transform: translateY(-55%);
                    
                }
                .btn {
                    padding: 14px 25px;
                    font-size: 14px;
                    line-height: 20px;
                    &.size-m{
                        min-width: 86px;
                        padding: 9px 16px;
                        line-height: 20px;
                    }
                }
            }
        }

    }
    
    @include tablet {
        &.init-type {
            overflow:auto;
            padding:40px;

            &.center-only {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
    
            .popup-wrap {
                height:506px;
                margin:0 auto;

                &.active + .active {
                    margin-left:auto;
                    margin-top:24px;
                }

                .pop-conts {
                    height:calc(100% - 78px - 76px);
                    &.mCustomScrollbar {
                        // padding:0;
    
                        > .mCustomScrollBox > .mCSB_container {
                            // padding:0;
                        }
                    }
                }

                .video-container {
                    .video-figure {
                        height:160px;
                        margin-top:12px;
                    }
    
                }
             
                .pop-footer {
                    &.check-type{
                        padding: 16px;
                   
                    }
                }
            }
        }
    }
    @include mobile {
        &.init-type {
            overflow:auto;
            padding:0;

            .popup-wrap {
                padding: 0;
                &.small{
                    .pop-conts {
                        padding: 32px 16px 40px;
                    }
                }
            }
            .pop-footer {
                padding: 0;
            }
        }
    }

}

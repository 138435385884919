@charset "UTF-8";

@import "../../utilities/variables";
@import "../../utilities/mixins";
@import "../../support/layout";
@import "../../support/board-list";
@import "../../support/board-view";
@import "../../support/form-field";
@import "../../support/form-result";
@import "../../support/modal";
@import "../../support/process";
@import "../../support/banner";
@import "../../support/quick-menu";
@import "../../support/selectModel";
@import "../../support/mcustom-scrollbar";


// tablet
@mixin tablet-size-s {
    @media screen and (max-width: 768px) {
        @content;
    }
}


/* 삭제 예정 */
label img {
    pointer-events: none;
}
/* //삭제 예정 */

.a-link {
    text-decoration: underline;
}
.underline {
    text-decoration: underline;
}

.tablet-only { display: none; }
@include tablet {
    .tablet-only {
        display: block;
    }
}

// header
.container {
    .page-header {
        .desc .txt {
            // BTOCSITE-42725 스스로해결 안내 문구 삽입
            .icn-mark {
                display: inline-block;
                width: 20px;
                height: 20px;
                background: url('/lg5-common/images/icons/icon-nodata-32.svg') no-repeat 0 0 / 100%;
                vertical-align: middle;
                position: relative;
                top: -2px;
                margin-right: 5px;
            }
        }
    }
}

/* 공통컴포넌트 변경 */
// COLORS
$color-primary      :#ea1917;//da0f47->ea1917
//$color-secondary    :#a50034;

.req::after,
.form-infos p.req::before,
.form-wrap .forms .tit .req::after,
.err-msg,
.bullet-list .b-txt .point {
    color: $color-primary;
}

.btn {
    font-size: 14px;
    border: 1px solid $color-primary;
    background-color: $color-primary;
    background-image: linear-gradient(280deg, $color-primary, $color-primary);

    &.gray:hover, &.gray:focus, &.gray:active {
        border-color: #ededed;
        background: #ededed;
        color: #666;
    }
}

.btn:hover, .btn:focus, .btn:active {
    border-color: $color-primary;
	background-color: $color-primary;
	background-image: none;
	color: #fff;
}

.btn.mix:hover, .btn.mix:focus, .btn.mix:active {
	background-color: #000;
}

// 센터방문 > 방문예약 버튼
.btn.border {
    &:hover, 
    &:focus, 
    &:active {
        border-color: initial !important;
        background: initial !important;
        color: initial;
    }
    &:disabled,
    &.disabled {
        border-color:#ddd !important;
        background:#f4f4f4;
        color:$color-gray-4;
        cursor:default;
        &:hover,
        &:focus,
        &:active {
            border-color:#ddd !important;
            background:#f4f4f4 !important;
            color: $color-gray-4 !important;
        }
    }
}


.btn.black:hover, .btn.black:focus, .btn.black:active {
    border-color: #000;
    background: #000;
    color: #fff;
}

.btn.border.white {
    border-color: #000;
    background: #fff !important;
}

.btn.border.pink {
    color: $color-primary;
    &:hover {
        border-color: $color-primary;
        background: inherit !important;
        color: $color-primary;
    }
}

.btn.size {
    line-height: 25px;
    padding: 3px 16px 2px;
    font-size: 12px;
}

.btn.size-m {
    padding: 7px 15px;
    // min-height: 40px;
}

.btn.size-xs {
    padding: 0 16px 1px;
    min-height: 29px;
    font-size: 12px;
}

// .btn.square {
//     border-radius: 6px !important;
// }

.btn-group.type-footer {
    .btn {
        min-width: 218px;
    }
}

.btn.plus {
    span {
        position: relative;
        padding-right: 22px;
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
            background: url("/lg5-common/images/CS/icon/icon_plus_white_16.svg") no-repeat right center;
        }
    }
}

.btn.ico-toggle {
    span {
        display: flex;
        align-items: center;
        &::after {
            content: "";
            display: block;
            width: 20px;
            height: 18px;
            transform: rotate(180deg);
            margin-left: 2px;
            background: url("/lg5-common/images/CS/icon/ic_up_16.svg") no-repeat right center;
            background-size: 18px auto;
        }
    }
    &.size {
        padding-right: 8px;
    }
    &.active {
        span {
            &::after {
                transform: rotate(0deg);
            }
        }
    }
}

.btn-group {
    margin-top:56px;
    &.closeToInput {
        padding-left:calc(18.4% + 24px);
    }
}

// input
// .input-wrap input, 
// .input-wrap textarea,
// .text-form textarea + .inner-text,
// .agree-cont-wrap .agree-cont-box,
// .agree-cont-wrap .agree-input {
//     border-color: #929292;
// }
.agree-wrap {
    margin-top:32px;
}

/* input */
// .input-wrap {
//     input[readonly=''], textarea[readonly=''] {
//         background: #eee;
//         border-color: #8f8f8f;
//         &::-webkit-input-placeholder,
//         &:-moz-placeholder,
//         &:-ms-input-placeholder {
//             color:#666;
//         }
//         &::placeholder {
//             color: #666;
//         }
//     }
// }


// .text-form input[type=text] + .inner-text em {
//     color: $color-primary;
// }


// .text-form input[type=text].noData + .inner-text,
// .text-form input[type=text].noData + .inner-text em,
// .text-form textarea.noData + .inner-text,
// .text-form textarea.noData + .inner-text em{
//     color: #666;
// }
//BTOCSITE-70879 고객지원 > 칭찬해요 변경
// .text-form input[type=text].noData:focus + .inner-text em,
// .text-form textarea.noData:focus + .inner-text em{
//     color: $color-primary;
// }

.text-form {
    textarea + .inner-text {
        border-color:#8f8f8f;
    }
    textarea[disabled=''] + .inner-text {
        border-color: #8f8f8f;
    }
}

.rdo-wrap input + label {
    padding-left: 32px;
}

.chk-wrap {
    input[type=checkbox] + label {
        position: relative;
        padding-left: 32px;
    }
    input[type=checkbox] + label::after{
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        border: none;
        position: absolute;
        left: 0;
        top:55%;
        transform: translateY(-55%);
        background-image: url(/lg5-common/images/icons/btn-checkbox-off_new.svg);
        background-size: 24px 24px;
    }
    input[type=checkbox]:checked + label::after{
        background-image: url(/lg5-common/images/icons/btn-checkbox-on_new.svg);
    }
    input[type=checkbox]:checked:disabled + label::after {
        background-image: url(/lg5-common/images/icons/btn-checkbox-disabled_new.svg);
    }
}
.form-wrap .forms .conts .phoneWrap {
    display:flex;
    justify-content: space-between;
    align-items:center;
    .input-wrap {
        width:calc((100% / 3) - 12px);
        margin:0;
    }
    .dash {
        font-size:14px;
        color:#666;
    }
}

/* controls - switch */
.switch-wrap {
    display: inline-block;
    .label {
        font-size: 14px;
        margin-right: 8px;
        line-height: 26px;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #8F8F8F;
        -webkit-transition: .4s;
        transition: .4s;
        &:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            left: 2px;
            bottom: 2px;
            background-color: #F7F7F7;
            -webkit-transition: .4s;
            transition: .4s;
        }
    }

    input:checked + .slider {
        background-color: #000;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #000;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }
}

.slider.round {
    border-radius: 24px;
    &:before {
        border-radius: 50%;
    }
}

.switch:has(input:disabled) {
    .slider {
        border: 1px solid #8f8f8f;
        background-color: #EAEAEA;
        &.round {
            &::before {
                border: 1px solid #8f8f8f;
                top: 1px;
                background-color: #F7F7F7;
            }
        }
    }
}
.switch:has(input[type="checkbox"]:checked:disabled) {
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #8F8F8F;
        -webkit-transition: .4s;
        transition: .4s;
        &:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            left: 2px;
            bottom: 2px;
            background-color: #F7F7F7;
            -webkit-transition: .4s;
            transition: .4s;
        }
        &.round {
            &::before {
                background-color: #F7F7F7;
            }
        }
    }
}

// .switch:has(input[type="checkbox"]:enabled:hover) {
.switch:has(input[type="checkbox"]:checked:hover) {
    .slider {
        &.round {
            &::before {
                border: 1px solid #8f8f8f;
                background-color: #F7F7F7;
            }
        }
    }
}

.flag-wrap.bg-type .flag {
    padding: 0 6px;
}

.flag-wrap.bg-type .flag.black {
    border-color: #222;
}

.rdo-wrap input:checked + label::after {
    background-image: url("/lg5-common/images/CS/icon/ic_radio_on_24.svg");
}

.select-wrap.is-title-hide {
    .ui-selectbox-list {
        li {
            &:first-of-type {
                display:none;
            }
        }
    }
}

.select-wrap .ui-selectbox-wrap .ui-select-button {
    border: 1px solid #929292;
}

.text-form textarea + .inner-text {
    padding: 0 20px 10px;
    //BTOCSITE-70879 고객지원 > 칭찬해요 변경
    // em {
    //     color: $color-primary;
    // }
}

.tabs-wrap-new .tabs > li {
    padding: 0 24px;
    margin-left: 0;
    a {
        padding: 17px 0 20px;
    }
}
.tabs-wrap-new .tabs > li.on > a:after {
    height: 3px;
}

.tabs-wrap-new.dpn {
    display: none;
}

.section:not([style*="display: none"]) + .tabs-wrap-new {
    margin-top: 120px !important;
}

.calendar-area .info > li:before {
    margin-bottom: 2px;
}

.calendar-area .info > li.red:before {
    background: $color-primary;
}


//검색창 : 인기검색어 위치변경
.keyword-search .search-layer .keyword-box .recently-keyword {
    width: calc(100% - 5px);
}
.keyword-search .search-layer .keyword-box .popular-keyword {
    display: none;
}

//paging
.pagination .prev, .pagination .next, 
.pagination .first, .pagination .last {
    background-size: 16px auto;
    background-position: center;
    border: 1px solid #ddd;
}

.pagination .disabled {
    background-color: #f4f4f4;
    border: 1px solid #eee;
}

.pagination .page_num a, 
.pagination .page_num button, 
.pagination .page_num strong, 
.pagination .page_num span {
    color:#767676;
    padding: 3px 15px 5px;
}

.pagination .page_num strong {
    color: #000;
}

.lay-wrap .laypop.btn-wrap {
    padding: 0 40px 40px;
    display: flex;
    .btn {
        width: 100%;
    }
}

.slick-dots li button {
    width:8px !important;
    height:8px !important;
}
.slick-dots li.slick-active button {
    width:24px !important;
    background:#000 !important;
}
.slick-arrow:before {
    content: ' ';
    display: block;
    width: 40px;
    height: 40px;
    background: #fff !important;
    border-radius: 50%;
    box-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.14);
}
.slick-arrow:after {
    position: absolute;
    top: 20px;
    left: 20px;
    transform: rotate(0deg);
    content: '';
    display: inline-block;
    border: solid black;
    border-width: 0 1px 1px 0;
    padding: 4px;
}
.slick-arrow.slick-next:before {
    background:none;
}
.slick-arrow.slick-prev:before {
    background:none;
}
.slick-arrow.slick-next:after {
    left: auto;
    right: 22px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    @include tablet {
        left:18px !important;
        top:19px !important;
    }
    @include mobile {
        left:11px !important;
        top:14px !important;
    }
}
.slick-arrow.slick-prev:after {
    right: 17px;
    left: auto;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    @include tablet {
        right:18px !important;
        top:19px !important;
    }
    @include mobile {
        left:16px !important;
        top:14px !important;
    }
}
// 툴팁
.tooltip-wrap {
    .tooltip-box {
        .tit-tip {
            display: block;
            font-size: 20px;
            margin-bottom: 12px;
        }
        .bullet-list {
            font-weight: 400;
            line-height: 21px;
            .b-txt {
                &:before {
                    width: 2px;
                    height: 2px;
                    margin-top: 7px;
                }
                &+.b-txt {
                    margin-top: 5px;
                }
            }
        }
    }

    .btn-close {
        &:after {
            width: 24px;
            height: 24px;
        }
    }
}





// BTOCSITE-18112 웹 접근성 관련 수정 요청
// BTOCSITE-27131 페이지네이션 UI 수정요청
// BTOCSITE-71268 고객지원 > 웹접근성
// .blind, caption, legend {
//     //BTOCSITE-71268 고객지원 > 웹접근성
//     // font-size: 0 !important;
//     opacity: 0;
//     top: -9999px;
//     text-indent: 100%;
// }
html {
    min-width:768px;
    overflow:auto;
}
.container {
    min-height:737px;
    //BTOCSITE-69400 고객지원 > 출장예약 > 구매정보 입력 단계 수정 및 스타일러 도어 대응
    // .cont-wrap .section~.section {
    //     margin-top:80px !important;
    // }
    // BTOCSITE-20648 고객지원 일부 화면 변경된 GNB 미적용(모바일)건 수정
    &.receipt {
        .page-header {
            @include mobile {
                .title {
                    display: block;
                    margin-top: 10px;
                }
            }
        }
    }

    .page-header {
        @include mobile {
            padding: 0 16px;
        }

        .progress-wrap {
            @include mobile {
                margin-top: 20px;
                margin-bottom: 32px;
            }
        }

        .btn-desc {
            @include mobile {
                .btn + .btn {
                    margin-top:8px;
                }
            }
        }
    }
}

.support {
    // BTOCSITE-62364 모델번호, 제조번호 입력
    .prod-wrap{
        .prod-search-wrap {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top:30px !important;
        }
    }

    @include tablet {
        .prod-model-box {
            .tit-wrap {
                .btn-prod-open {
                    padding-left:0 !important;
                    &::before {
                        content: none !important;
                    }
                }
            }
        }
    }
    
    .prod-model-box {
        opacity: 0.5;
        display:inline-block;
        width:calc(50% - 12px);
        padding: 42px 40px;
        background-color: #FFF;
        box-shadow:0 4px 16px 0 rgba(0, 0, 0, 0.14);
        border-radius: 16px;

        & + .prod-model-box {
            margin-left: 24px;
        }

        &:nth-child(2) {
            .tit-wrap {
                .btn-prod-open {
                    &::before {
                        background: url(/lg5-common/images/CS/icon/icon_prod_model_02.svg) no-repeat 0 0;
                        background-size: 100% auto;
                    }
                }
            }
        }
        .keyword-search {
            &.on {
                .search-input {
                    input[type=text] {
                        border: 0;
                    }
                }
                .search-layer {
                    border:1px solid #0F0F0F;
                }
            }

            .search-input {
                display: flex;
                input[type=text] {
                    width: calc(100% - 82px);
                    padding-right: 40px;
                    border-color: #8F8F8F;
                    &:focus {
                        outline:none;
                        border-color:#000;
                    }
                }
            }
            .btn-search {
                display: inline-block;
                width: 74px;
                height: 48px;
                color: #FFF;
                background-color: #0F0F0F; 
                margin-left: 8px;
                border-radius: 6px;
                span {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            .btn-delete {
                right: 92px;
            }
            .search-layer {
                top: -1px;
                left: -1px;
                right: 81px;
                box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.10);
                border-radius: 6px;
                border:1px solid #0F0F0F;
                min-width: auto;
                width: auto;
                padding: 50px 24px 40px 15px;
                .autocomplete-box .keyword-list ul {
                    margin-left: 0;
                    margin-right: 0;
                    li {
                        padding: 4px 12px 4px 0;
                        &:hover {
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
        &.open {
            opacity: 1;
            border: 2px solid #666;
           .tit-wrap {
                margin-bottom: 24px !important;
                .btn-prod-open {
                    padding-left: 0;
                    min-height: auto;
                    &:after {
                        display: none;
                        transform: rotate(-180deg);
                    }
                    &::before {
                        content: none;
                    }
                    .tit {
                        padding-left: 10px;
                    }
                    .noti-msg {
                        display:none;
                    }
                }
            }
            .form-wrap {
                display: block;
            }
        }

        .tit-wrap {
            margin-bottom: 0 !important;
            .btn-prod-open {
                display:block;
                width: 100%;
                position: relative;
                padding-left: 116px;
                min-height: 88px;
                .tit {
                    width: 100%;
                    text-align: left;
                    font-size: 22px;
                    line-height: 30px;
                    border: none;
                    margin: 0 !important;
                    padding-bottom: 0;
                    padding-right: 40px;
                    
                }
                .noti-msg  {
                    position:relative;
                    margin-top: 16px;
                    text-align: left;
                    font-size: 14px;
                    line-height: 20px;
                    color: #FF3723;
                    padding-left: 16px;
                    &.red {
                        color: #FF3723;
                    }
                    &:before {
                        position: absolute;
                        left: 0;
                        top: 5px;
                        content: '';
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        background: url(/lg5-common/images/icons/icon-error.svg) no-repeat 0 0;
                        background-size: 100% auto;
                        
                    }
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 14px;
                    right: 0;
                    width: 24px;
                    height: 24px;
                    background: url(/lg5-common/images/CS/icon/btn-arr-down-24.svg) no-repeat center center;
                    background-size: 14px auto;
                }
                
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    display:inline-block;
                    width: 88px;
                    height: 88px;
                    background: url(/lg5-common/images/CS/icon/icon_prod_model_01.svg) no-repeat 0 0;
                    background-size: 100% auto;
                    margin-right: 28px;
                }
            }
        }

        .btn-area {
            margin-top: 34px;
            .btn.black {
                width: 100%;
                height: 50px;
                background-color: #0F0F0F;
                border-radius: 100px;
                padding: 15px;
                color: #FFF;
                font-size: 14px;
                line-height: 20px;
                &:disabled {
                    color: #666666;
                    background-color: #EEEEEE;
                }
            }
        }

        .form-wrap {
            display: none;
            .forms {
                padding: 0 10px;
                .btn-group {
                    margin-left:-10px;
                    margin-right:-10px;

                }

            }
        }

        .input-wrap {
            width: 100%;
            input {
                border-color: #8F8F8F;
                &:focus {
                    outline:none;
                    border-color:#000;
                }
            }
        }
        
        // BTOCSITE-64881 고객지원 > 서비스예약 > 모델명, 제조번호 입력 > UI일부 수정
        .txt-wrap {
            .noti-txt {
                margin-top: 8px;
                text-align: left;
                font-size: 14px;
                line-height: 20px;
                color: $color-negative;
            }
        }
        .search-more {
            position: relative;
            text-align: right;
            margin-top: 16px;
            .success-msg {
                position: absolute;
                left:0;
            }
        }
        .cate-wrap {
            margin-top: 10px;
        }

        .btn.question {
            color: #959595;
            font-size: 16px;
            border-radius: 0;
            padding: 0;
            margin-right: 24px;
            height: auto;
            border: none;
            span {
                border-bottom: 1px solid #959595;
            }
            &::before{
                content: "";
                left: inherit;
                right: -24px;
                width: 20px;
                height: 20px;
                top: 60%;
                transform: translateY(-60%);
                background-size: 20px 20px;
                background: url(/lg5-common/images/CS/icon/icon_question_20.svg) center no-repeat;
            }
        }

        .success-msg {
            color: #76892d;
            font-size: 13px;
            line-height: 20px;
            white-space: nowrap;
            &::before {
                content: ' ';
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-top: 2px;
                margin-right: 2px;
                background: url(/lg5-common/images/icons/icon-comp.svg) center no-repeat;
                vertical-align: top;
                position: relative;
                top: -2px;
            }
        }
        .select-wrap{
            .ui-selectbox-wrap{
                &.on {
                    .ui-selectbox-list {
                        
                        // data-value="all" 숨김
                        li:first-of-type {
                            display: none;
                        }
                    }
                }
            }
        }
        
        //BTOCSITE-64551 고객지원 > 출장접수 > 시스템에어컨 안내 추가
        .noti-txt {
            color: #666;
            font-size: 14px;
            line-height: 20px;
            margin-top: 20px;
            em {
                color: #ff3723;
            }
        }
    }

    // BTOCSITE-3200 출장서비스 예약 > 추가정보_건물유형 수정 등 
    .cause-desc {
        margin-top:16px;

        p {
            position: relative;
            padding-left:16px;
            color: $color-primary;
            font-size:14px;
            line-height:1.4;
            font-weight:bold;
            &:before {
                position: absolute;
                top:0;
                left:0;
                content:"※";
                display: inline-block;
            }

            @include mobile {
                font-size:13px;
            }
        }
    }
    .tooltip-wrap {
        &.share {
            .tooltip-box {
                padding-right:32px;

            }
            .btn-close {
                top:32px;
                right:32px;
            }
        }
        .btn-close {
            top:10px;
            right:10px;
            
        }

        .tooltip-box {
            padding-right:40px;
        }

        @include mobile {
            &.share {
                .tooltip-box {
                    padding-right:24px;
                }

                .btn-close {
                    top:24px;
                    right:24px;
                }
            }
            .btn-close {
                top:8px;
                right:10px;
            }
        }
    }

    .login-guide {
        padding:20px 0;
        text-align:center;
        background: url(/lg5-common/images/CS/img-banner01.jpg) no-repeat;
        background-position: center 15%;
        background-size: cover;
        .txt {
            margin:0 auto 16px;
            line-height:34px;
            font-size:24px;
            font-weight:$font-weight-bold;
            word-break:keep-all;
        }
        @include mobile {
            padding:16px;
            .txt {
                margin-bottom:16px;
                line-height:28px;
                font-size:20px;
            }
        }
    }
    .service-menu-wrap {
        .inner {
            max-width:1380px + 40px;
            margin:0 auto;
            padding:120px 40px;
        }
        .title {
            margin-bottom:48px;
            font-size:32px;
            font-weight:700;
            line-height:48px;
            text-align:center;
            word-break:keep-all;
        }
        .service-list {
            margin:0 -12px;
            font-size:0;
            > li {
                display:inline-block;
                width:calc(100% / 6);
                padding:0 12px;
                vertical-align:top;
                a {
                    display:block;
                }
                .icon {
                    width:80px;
                    height:80px;
                    margin:0 auto;
                    img {
                        width:100%;
                    }
                }
                .tit {
                    display:block;
                    margin-top:8px;
                    @include font-large-1;
                    font-weight:700;
                    text-align:center;
                }
            }
        }
        @include tablet {
            .service-list {
                margin:-24px -12px 0;
                > li {
                    padding:24px 12px 0;
                    width:calc(100% / 3);
                }
            }
        }
        @include mobile {
            border-top:12px solid #f4f4f4;
            .inner {
                padding:32px 16px 60px;
            }
            .title {
                margin-bottom:24px;
                font-size:24px;
                line-height:33px;
            }
            .service-list {
                margin:-40px -4px 0;
                > li {
                    display:inline-block;
                    width:50%;
                    padding:40px 4px 0;
                }
            }
        }
    }
    .held-product {
        padding-left: 22px;
        font-size: 14px;
        font-weight: 500;
        color: #767676;
        background: url(/lg5-common/images/icons/icon-more-pk-20.svg) no-repeat left center;
    }


    .search-info {
        background-color: #f4f4f4;
        text-align: center;
        padding: 32px 0;
        border-radius: 8px;
        margin-bottom: 80px;
        .tit {
            margin-bottom:16px;
            font-size:20px;
            font-weight:700;
            line-height:24px;
        }
        .desc {
            font-size:16px;
            line-height:24px;
            .point {
                color:#ea1917;
                font-weight:normal;
            }
        }

        &.type-A {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 24px 48px;
            margin-top:10px;
            margin-bottom: 60px;
            text-align: left;
            .text {
                font-size: 16px;
                line-height: 1.5;
            }
        }
        &.type-B {
            font-size: 24px;
            line-height: 1.42;
            padding: 24px;
            margin-bottom: 12px;
        }
        @include tablet {
            &.type-A {
                display: block;

                .btn-link {
                    margin-top: 12px;
                }
            }
        }
        @include mobile {
            padding: 20px;
            margin-bottom: 40px;
            .tit {
                margin-bottom:12px;
                font-size:16px;
                line-height:24px;
            }
            .desc {
                font-size:14px;
                line-height:22px;
            }
            &.type-A {
                display: block;
                padding: 20px 24px;
                margin:0;
                .text {
                    font-size: 14px;
                }
               
            }
            &.type-B {
                font-size: 18px;
                padding: 20px 16px;
            }
        }
    }
    .scroll-x {
        overflow-x: auto;
        table {
            min-width: 720px;
            width: calc(100% - 1px);
        }
    }

    .accordion-wrap {
        &.border-type {
            > ul {
                > .lists {
                    border-radius: 8px;
                    border: solid 1px #dddddd;
                    margin-top: 12px;
                    box-shadow: none;
                    .head {
                        .accord-btn {
                            padding: 24px 32px;
                            &::after {
                                content: '보기';
                                border: none;
                                width: auto;
                                height: auto;
                                right:0;
                                font-size: 16px;
                                color: #000;
                                line-height: 1.63;
                                transform: translateY(-50%);
                                margin-top: 0;
                                padding-left: 20px;
                                background: url(/lg5-common/images/icons/icon-more-plus_16_w.svg) no-repeat left center;
                                background-size: 16px;
                                text-decoration: underline;
                            }

                            .subtitle {
                                &.gray {
                                    color:#666;
                                }
                                strong {
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                    &.on {
                        .head {
                            .accord-btn {
                                &::after {
                                    content: '닫기';
                                    background-image: url(/lg5-common/images/icons/btn-close-24.svg);
                                }
                            }
                        }
                    }
                    .accord-cont {
                        margin: 0 24px 24px 24px;
                        padding: 24px 32px;
                        border-radius: 8px;
                        background-color: #f4f4f4;
                        display: none;
                        &.no-bg-type {
                            padding: 24px 32px 32px;
                            background-color: transparent;
                        }
                    }
                }
            }
        }
        @include mobile {
            &.border-type {
                > ul {
                    > .lists {
                        .head {
                            .accord-btn {
                                padding: 20px;

                                .subtitle {
                                    font-size:14px;
                                }

                                &::after {
                                    font-size:14px;
                                }
                            }
                        }
                        
                        .accord-cont {
                            margin: 0 20px 20px 20px;
                            padding: 24px 16px 27px;
                            &.no-bg-type {
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    

    .search-wrap {
        &.narrow {
            max-width:912px;
            margin:0 auto;
        }
        &.board-top-search {
            margin-bottom:45px;
        }

        .sort-search {
            display:flex;
            border-radius:8px;
            border:1px solid #ddd;
            
            .select-wrap {
                width:152px;
                // BTOCSITE-28887 [요금/보증안내] 모니터 보증기간 제품 추가 : 로딩시 깜박이는 현상 수정
                select {
                    color: #767676;
                    font-size: 14px;
                    line-height: 22px;
                    margin: 11px;
                    // BTOCSITE-30896 [모니터링]재생부품 가격 공지 페이지 최초 진입시 일시적으로 제품선택 문구가 비정상 노출 후 정상적으로 노출됨
                    @include pc {
                        font-size: 16px;
                        line-height: 26px;
                        margin: 10px 15px;
                    }
                }
                .ui-selectbox-wrap {
                    .ui-select-button {
                        border:0;
                    }

                    &.on {
                        .ui-select-button {
                            border:1px solid #000;
                            border-bottom:0;
                        }
                    }
                }

            }
            .input-wrap {
                position:relative;
                flex:1;

                input[type="text"] {
                    width:100%;
                    border:0;
                    text-transform:uppercase;
                }
            }
        }
        @include pc {
            .search-box {
                .forms {
                    display: flex;
                    border: 1px solid #ddd;
                    border-radius: 8px;
                    .box {
                        margin-top: 0;
                    }
                    .select-wrap {
                        width: 152px;
                        .ui-selectbox-wrap {
                            margin: -1px 0 -1px -1px;
                            .ui-select-button {
                                border-right-color: transparent;
                                border-bottom: none;
                                border-bottom-right-radius: 0;
                                border-top-right-radius: 0;
                            }
                            &.on {
                                .ui-select-button {
                                    border-top-right-radius: 8px;
                                    border-right-color: #222;
                                }
                            }
                        }
                    }
                }
            }
            &.w-block {
                max-width: 100%;
            }
        }
        @include mobile {
            &.w-block {
                padding: 0;
            }
        }
    }

    .select-wrap {
        .ui-selectbox-wrap {
            .ui-selectbox-list {
                z-index: 12;
            }
        }
    }

    

    .directCall-wrap {
        max-width: 1380px + 80px;
        margin: 0 auto 40px;
        padding:0 40px;
        >ul {
            >.lists {
                margin-top: 0;
            }
        }
        @include mobile {
            margin: 0 0 32px;
            padding:0 16px;
        }
    }

    

    .box-info {
        margin-top: 16px;
        @include pc {
            .status-icon {
                margin-left: 6px;
                &:first-of-type {
                    margin-left: 0;
                }
            }
        }
    }
    .status-icon {
        display:inline-flex;
        align-items:center;
        .status {
            @include font-small;
            font-weight:$font-weight-medium;
        }
        .text  {
            @include font-small;
            color: #666;
            margin-left:9px;
        }
        &:before {
            content:'';
            display:inline-block;
            width:12px;
            height:12px;
            border-radius:50%;
            vertical-align:middle;
            margin-right:5px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center center;
        }
        &.gray:before {
            background-color: #ccc;
        }
        &.gray2:before {
            background-color: #e4e4e4;
        }
        &.green:before {
            background-color: #51b1ac;
        }
        &.red:before {
            // background-color: #e51346;
            background-image: url("/lg5-common/images/CS/icon-state-no.svg");
        }
        &.black:before {
            background-color: #222;
        }
        &.skyblue:before {
            // background:#21a9aa;
            background-image: url("/lg5-common/images/CS/icon-state-ok.svg");
        }
        &.olive:before {
            // background:#76892d;
            background-image: url("/lg5-common/images/CS/icon-state-mid.svg");
        }
        &.gradient1::before {
            background-image: linear-gradient(135deg, #8d71ec, #ff537c);
        }
    }

    .optionIcon {
        display: flex;
        margin-top: 16px;
        li {
            &:nth-child(n+2) {
                margin-left: 32px;
            }
        }
    }

    .consulting-info-tit,
    .consulting-info-desc {
        text-align: center;
        color: #000;
    }
    .consulting-info-tit {
        font-size: 28px;
        line-height:1.63;
        font-weight: bold;
        word-break: keep-all;
        margin-bottom: 8px;
        @include mobile {
            font-size: 18px;
            line-height: 1.44;
        }
    }
    .consulting-info-desc {
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 32px;
        @include mobile {
            font-size: 13px;
            line-height: 1.62;
        }
    }
    .consulting-info {
        color: #000;
        max-width: 912px;
        margin: 0 auto;
        .info-tit,
        .info-desc {
            text-align: center;
        }

        @include mobile {
            .bullet-list {
                margin-top:32px;
            }
        }
    }

    .consulting-keyword {
        margin-bottom: 32px;
        .item {
            border-radius: 8px;
            padding:16px 0;
            color:#fff;
            text-align:center;
            flex:1;
            &:nth-child(1) {
                background-image:linear-gradient(to right, #a58bee, #f86791);
            }
            &:nth-child(2) {
                background-image:linear-gradient(to right, #a58bee, #f86791);
            }
            &:nth-child(3) {
                background-image:linear-gradient(to right, #a58bee, #f86791);
            }
            .title {
                line-height:30px;
                font-size:20px;
                font-weight:$font-weight-bold;
            }
            .text {
                line-height:24px;
                font-size:14px;
                font-weight:$font-weight-medium;
            }
        }
        @include pc {
            display:flex;
            justify-content:center;
            .item {
                margin-left:16px;
                &:first-child {
                    margin-left:0;
                }
            }
        }
        @include mobile {
            .item {
                margin-top:12px;
                &:first-child {
                    margin-top:0;
                }
                .title {
                    line-height:23px;
                    font-size:15px;
                }
                .text {
                    line-height:21px;
                    font-size:13px;
                }
            }
        }
    }

    .step-list {
        display: flex;
        justify-content: center;
        text-align: center;
        li {
            width: 104px;
            height: 104px;
            background-color: #f4f4f4;
            border-radius: 50%;
            padding: 0 20px;
            margin-left: 40px;
            font-size: 16px;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            &:first-child {
                margin-left: 0;
                &::before {
                    display: none;
                }
            }
            &::before {
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                background:url(/lg5-common/images/icons/btn-arr-16-black.svg) no-repeat;
                position: absolute;
                left: -25px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        @include mobile {
            flex-wrap: wrap;
            li {
                font-size: 14px;
                width: 80px;
                height: 80px;
                margin-left: 23px;
                margin-bottom: 12px;
                &::before {
                    left: -19px;
                }
            }
        }
    }

    .sw-notice {
        &-title {
            font-size: 24px;
            font-weight: bold;
            line-height: 1.42;
            color: #222222;
            margin-bottom: 16px;
            text-align: center;
        }
        &-desc {
            font-size: 16px;
            line-height: 1.63;
            color: #222222;
            text-align: center;
            margin-bottom: 32px;
        }
        &-ul {
            font-size: 16px;
            line-height: 1.63;
            color: #222222;
        }
    }
    .patch-note {
        padding: 24px 40px;
        border-radius: 8px;
        background-color: #f4f4f4;
        &-title {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.63;
            color: #222222;
            padding-left: 23px;
        }
        .bullet-list {
            font-size: 16px;
        }
        @include mobile {
            padding: 20px;
        }
    }

    

    .mobile-notice{
        margin:0 auto;font-size:16px;
        .notice_title{
            font-size: 24px;
            color: #222222; 
            margin-bottom: 16px;
            text-align: center;
        }
        .patch-note{
            margin-top: 38px;
            padding: 24px 40px;
            border-radius: 8px;
            background-color: #f4f4f4;
            em{
                display: block;
                font-size: 16px;
                font-weight: bold;
                line-height: 1.63;
                color: #222222;
                padding-left: 23px;
                margin-bottom: 12px;
                background: url(/lg5-common/images/icons/icon-error.svg) no-repeat left center;
            }
            ul { 
                margin-top:0;
                li{
                    font-size: 14px;
                    line-height: 1.63;
                    color: #767676;
                    text-indent: -0.5em;
                    padding-left: 0.5em;
                }
            }
        }
        .txt_thx {
            margin-top: 12px;
        }
        @include mobile {
            .patch-note {
                padding: 16px;
                em {
                    font-size: 14px;
                }
            }
        }
    }
    
    .mobile-contents{
        margin:30px 0 0;
        p {
            font-size: 20px;
            font-weight: bold;
            color: #222222;
            margin-bottom: 16px;
        }
        ul { 
            > li {
                font-size: 14px;
                line-height: 1.63;
                text-indent: -0.5em;
                padding-left: 0.5em;
            }
            strong{
                font-size: 16px;
                line-height: 1.63;
                font-weight: normal;
                color: #222222;
            }
        }
        .detail{
            margin:10px 0 20px;
        }
        .title{
            display:block;position:relative;margin:30px 0;padding-left:20px;font-size:18px;font-weight:600;
        }
        .title:before{
            content:'';display:inline-block;position:absolute;left:0;top:5px;border-radius:10px;width:10px;height:10px;background:#ce1848;
        }
        @include mobile {
            p {
                font-size: 16px;
                margin-bottom: 12px;
            }
            ul {
                strong {
                    font-size: 14px;
                }
            }
        }
    }

    .btn-group {
        @include pc {
            .block-box {
                display: inline-block;
                margin-left: 6px;
            }
        }
        @include mobile {
            .btn {
                &.wide-type {
                    width: 100%;
                    margin-bottom: 12px;
                }
            }
        }
    }

    .bullet-list {
        // BTOCSITE-21883 고객지원: 베스트케어 지원
        .a-link {
            text-decoration: underline;
        }
        .btn-link {
            margin-left: 12px;
            font-size: inherit;
            line-height: inherit;
            
            // BTOCSITE-27102 [원격상담] 안내 문구 영역 추가 및 PC/노트북 원격지원 프로그램 다운로드 버튼 제공
            &.point {
                &::after {
                    background: url(/lg5-common/images/icons/btn-arr-16-red.svg) center no-repeat;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        // BTOCSITE-21062 출장서비스 신청 약관 하단에 문구 추가 요청 건
        .b-dangu {
            padding-left: 17px;
            text-indent: -17px;
            &::before {
                content: '※ ';
                display: inline;
            }
        }
    }

    .video-box {
        height:360px;
        margin:0 auto;
        display: flex;
        .column {
            width:56%;
            height:100%;
            & + .column {
                width:calc(44% - 24px);
                margin-left:24px;
            }
            iframe {
                width:100%;
                height:100%;
            }
            .subtitles-tit {
                margin-bottom: 24px;
                color: #000;
                font-size:24px;
                font-weight:$font-weight-bold;
                line-height:34px;
                @include mobile {
                    margin-bottom: 12px;
                    font-size: 20px;
                    line-height:1.4;
                }
            }
            .subtitles-cont {
                overflow-y:auto;
                height: calc(100% - 58px);
                padding-right: 36px;
                color:#000;
                font-size:16px;
                line-height:26px;
                white-space: pre-line;
            }
        }
        @include mobile {
            height:auto;
            flex-direction: column;
            .column {
                float:none;
                width:100%;
                height:auto;
                & + .column {
                    width:100%;
                    margin:8px 0 0;
                }
                iframe {
                    height: 220px;
                }
                .subtitles-wrap {
                    height: 165px;
                    font-size:13px;
                    line-height:21px;
                }
                .subtitles-cont {
                    height: 58.3336vw;
                }
            }
        }
    }
    .request-link-box {
        max-width: 1380px;
        margin: 0 auto 24px;
        @include mobile {
            padding: 0 16px;
        }
    }
    .result-progress {
        max-width:1380px + 80px;
        margin:0 auto;
        padding:80px 40px 0;
        &:first-child {
            padding-top:0;
        }
        .desc{
            margin-bottom:24px;
            @include font-medium;
            font-weight:700;
        }
        .progress-box {
            position:relative;
            &:after {
                content:'';
                position:absolute;
                left:0%;
                top:6px;
                width:100%;
                height:12px;
                background:#f4f4f4;
            }
            .progressing {
                position:absolute;
                z-index:1;
                left:0%;
                top:6px;
                width:0;
                height:12px;
                border-radius:16px;
                background:#ea1917;
            }
            .progressbar {
                display:flex;
                justify-content:space-between;
                padding-bottom:30px;
                li {
                    position:relative;
                    text-align:center;
                    &:before {
                        content: '';
                        display:inline-block;
                        width:24px;
                        height:24px;
                        background-color:#aaa;
                        border-radius: 50%;
                        position: relative;
                        z-index:2;
                    }
                    .text {
                        display:none;
                        position:absolute;
                        left:50%;
                        bottom:-30px;
                        white-space:nowrap;
                        transform:translateX(-50%);
                        font-weight:500;
                        @include font-medium;
                    }
                    &:first-child .text {
                        left:0;
                        transform:translateX(0);
                    }
                    &:last-child .text {
                        left:auto;
                        right:0;
                        transform:translateX(0);
                    }
                    &.on {
                        &:before{
                            border:1px solid #ddd;
                            box-shadow:2px 4px 8px 0 rgba(0, 0, 0, 0.14);
                            background: url(/lg5-common/images/CS/icon-process-complete-24.svg) no-repeat center #fff;
                        }
                    }
                    &.current {
                        .text {
                            display:block;
                        }
                    }
                }
            }
        }
        @include mobile {
            padding:60px 16px 0;
            .progress-box {
                &:after {
                    height:8px;
                }
                .progressing {
                    height:8px;
                }
                .progressbar {
                    padding-bottom:28px;
                    li {
                        &:before {
                            width:20px;
                            height:20px;
                        }
                        .text {
                            bottom:-28px;
                        }
                    }
                }
            }
        }
    }

    &.error-page-cs {
        padding:0 40px;
        padding-top:((280px * 100%) / 1920px);
        padding-bottom:((280px * 100%) / 1920px);
        text-align:center;
        br.m-br {
            display:none;
        }
        .error-inner {
            display:inline-block;
            vertical-align:top;
            .tit {
                font-size:40px;
                line-height:56px;
                font-weight:normal;
                small {
                    display:block;
                    margin-top:24px;
                    margin-bottom:40px;
                    @include font-large;
                    font-weight:700;
                }
                @include mobile {
                    font-size:27px;
                    line-height:38px;
                }
                &.icons {
                    &::before {
                        content:' ';
                        display:block;
                        width:80px;
                        height:80px;
                        margin:0 auto 24px;
                        background:url('/lg5-common/images/icons/icon-fixed-80.svg') no-repeat;
                        @include mobile {
                            margin-bottom:20px;
                        }
                    }
                }
            }
            .desc {
                margin-top:24px;
                @include font-large;
                span {
                    display:inline-block;
                    vertical-align:top;
                }
                @include mobile {
                    margin-top:16px;
                }
            }
            dl {
                display:block;
                margin-top:24px;
                margin-bottom:120px;
                font-size:0;
                @include mobile {
                    margin-top:18px;
                }
                dt {
                    display:inline-block;
                    @include font-large;
                    font-weight:700;
                    vertical-align:top;
                }
                dd {
                    display:inline-block;
                    margin-left:12px;
                    color:#ea1917;
                    @include font-large;
                    font-weight:700;
                    @include mobile {
                        margin-left:8px;
                    }
                }
            }
            .ci {
                margin-top:32px;
                text-align:center;
                @include mobile {
                    margin-top:60px;
                }
                img {
                    max-width:82px;
                    height:auto;
                    vertical-align:top;
                }
            }
            .btns {
                margin-top:120px;
                @include mobile {
                    padding:0 16px 40px;
                    .btn {
                        display:block;
                        margin-top: 12px;
                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        @include mobile {
            padding:0 16px;
            padding-top:((260px * 100%) / 720px);
            br.m-br {
                display:block;
            }
        }
    }

    .card-news-tit {
        padding:24px 40px;
        @include font-medium;
        border-radius:8px;
        background:#f4f4f4;
        strong {
            font-weight:$font-weight-bold;
        }
    }

    &.popup-wrap {

        &.row-footer {

            .pop-conts {
                @include mobile {
                    height:calc(100% - 122px - 36px);
                    max-height:calc(100% - 122px - 36px);
                }
            }
        }
        .pop-footer {
            &.check-type {
                input {
                    &+label {
                        padding-left: 30px;
                        font-size: 16px;
                        line-height: 26px;

                        @include mobile {
                            font-size:13px;
                        }
                        &::before {
                            width: 20px;
                            height: 20px;
                        }
                        &::after {
                            top: 2px;
                            left: 0px;
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
            &.align-between {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
        }
    }

    // BTOCSITE-43111 철거/이동/재설치
    .inst-move-info {
        margin-top: 30px;
        padding-left: calc(18.4% + 24px);
        a {
            position: relative;
            top: -5px;
        }
    }
    // BTOCSITE-69770 서비스예약시, 폭염특보 안내문 반영의 건
    .inst-move-info-aircon {
        padding-left: calc(18.4% + 24px);
        line-height: normal;
    }
} //support


//BTOCSITE-69159 고객지원 > 출장예약 > 에어컨 > 설치장소 추가
#bdTypePopup {
    .common-pop {
        .text-area {
            padding: 0 0 0;
            line-height: 1.4;
            margin-bottom: 16px;
            border-radius: 6px;
        }
        .rdo-list-wrap {
            background-color: #f8f8f8;
            .rdo-list {
                margin: 0;
                display: flex;
                flex-direction: column;
                margin: 0 16px;
                padding: 0;
                li {
                    width: 100%;
                    padding: 16px 6px;
                    &:first-child {
                        border-bottom: 1px solid #dddddd;
                    }
                }
                .desc {
                    color: #666;
                    font-size: 12px;
                    line-height: 1.4;
                    margin-top: 14px;
                    word-break: break-word;
                }
            }
        }
    }
}

// BTOCSITE-13601 모델명 확인 방법 > 이미지 확대 창에 닫기 버튼 추가 요청
.popup-wrap {
    &.small {
        .pop-conts {
            // BTOCSITE-51736 실제 예약 시간 보다 더 빠른 연락을 원하는 고객 취향 반영
            .call-agree-input {
                margin-top: 20px;
                text-align: center;
            }
        }    
    }

    .pop-conts {
        // BTOCSITE-13599 요금 및 보증기간 안내 팝업 수정 - 모델명 확인 이미지확대 선택 시 팝업
        .zoom-area {
            display: flex;
            justify-content: center;
            align-items: center;
            height: calc(100% - 62px);
            @include mobile {
                margin-top: 10px;
            }
            img {
                width: auto;
                max-width: 100%;
            }
        }
    }
}

// BTOCSITE-21589 출장서비스 신청 후 카톡 연결 링크 페이지 디자인 가이드 준수 요청 건
.win-popup-wrap  {
    .pop-footer {
        .btn-group {
            @include mobile {
                margin: 0;
                padding: 0;
            }
        }
    }
}
/* 정리 중 추가 금지 (꼭 필요할 경우에만 사용) */
// display
.pc-only {
    display: block;
    &.ib {
        display: inline-block;
    }
    @include mobile {
        display: none;
        &.ib {
            display: none;
        }
    }
}
.mo-only {
    display: none;
    @include mobile {
        display: block;
    }
}

// text-align
.align-left {
    text-align: left !important;
}
.align-right {
    text-align: right !important;
}
.align-center {
    text-align: center !important;
}

// color
.color-1 {
    color: #000 !important;
}
.color-2 {
    color: #f04e3e !important;
}
.color-3 {
    color: #ea1917 !important;
}
.color-4 {
    color: #008182 !important;
}
//section title
.section-title{
    .tit{
        @include font-large;
        margin-top:48px;
        margin-bottom:16px;
    }
}

@include mobile{
    .contents-inner.type2{
        padding:0 5%;
    }

    .section-title{
        .tit{
            margin-top:20px;
        }
    }
}

.update-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding:28px 49px 29px;
    background: #f4f4f4;
    border-radius:10px;

    p {
        font-size:16px;
        line-height:1.63;
    }

    .btn-link {
        font-size:14px;
    }

    @include mobile {
        flex-direction: column;
        align-items: flex-start;
        padding:22px 20px;

        .btn-link {
            margin-top:30px;
            font-size:13px;
        }
    }
}

.find-model-number {
    display: block !important;
    max-width:none;
    position:relative;

    .pop-conts.no-footer {
        max-height:none;
        height:auto;
        overflow:auto;
    }
}



// BTOCSITE-1198 챗봇 상담 메뉴 추가 요청
.chat-customer {
    display: flex;
    justify-content: space-between;
    margin-left: -24px;
    @include mobile {
        flex-wrap: wrap;
        justify-content: initial;
        padding: 0 16px;
    }
    .item {
        flex: 1;
        margin-left: 24px;
        padding: 30px 25px;
        border-radius: 8px;
        border: solid 1px #ddd;
        text-align: center;
        .title {
            position: relative;
            display: block;
            padding-top: 96px;
            @include mobile {
                padding-top: 63px;
                font-size: 15px;
            }
            &::before {
                content:"";
                position: absolute;
                top: 0;
                left: 50%;
                display: inline-block;
                width: 80px;
                height: 80px;
                transform: translateX(-50%);
                @include mobile {
                    width: 48px;
                    height: 48px;
                }
            }
            &.icon-chat::before {
                background: url(/lg5-common/images/CS/icon-chat.png) no-repeat 50% 50%;
                background-size: cover;
            }
            &.icon-kakao::before {
                background: url(/lg5-common/images/CS/icon-kakao.png) no-repeat 50% 50%;
                background-size: cover;
            }
        }
        .desc {
            margin: 14px 0 30px;
            font-size: 16px;
            line-height: 1.57;
            text-align: center;
            @include mobile {
                margin: 9px 0 18px;
                font-size: 14px;
            }
            .only_pc {
                @include tablet {
                    display: none;
                }
            }
        }
        @include mobile {
            flex: auto;
            padding: 24px 25px;
            & + .item {
                margin-top: 32px;
            }
        }
    }
    .point {
        color: $color-primary;
    }
    & ~ .bullet-list {
        &.top-border {
            margin: 40px 0 0;
            @include mobile {
                margin: 40px 16px 0;
            }
        }
    }
}
.chat-info-wrap {
    margin-top: 70px;
    text-align:center;
    @include mobile {
        margin-top: 40px;
    }
    .desc {
        margin-top: 6px;
        @include font-medium;
        @include mobile {
            margin-top: 4px;
        }
    }
    .desc-bold {
        margin-top: 6px;
        font-weight:700;
    }
    .desc-thin {
        margin-top: 10px;
        @include font-small;
    }
}

// BTOCSITE-8855 s
.inquiry-sections{
    .faq-link{
        margin-top: 33px;
        a {
            img {
                width:100%;
                height:auto;
                &.mobile-only{
                    display: none;
                    @include mobile {
                        display: block;
                    }
                }
                &.pc-only{
                display: block;
                    @include mobile {
                    display: none;
                    }
                }
            }
        }
    }
}
// BTOCSITE-8855 e

.page-header {
    &.reservationComplete {
        padding-top:56px;
        .title {
            font-size: 32px;
                i {
                display: block;
                width: 64px;
                height: 64px;
                margin: 0 auto 26px;
                background: url('/lg5-common/images/icons/icon-complete-64.svg') no-repeat 50% 0 / 64px;
            }
        }
    }

    &.checkComplete {
        padding-top:56px;
        .title {
            font-size: 32px;
                i {
                display: block;
                width: 64px;
                height: 64px;
                margin: 0 auto 20px;
                background: url('/lg5-common/images/icons/icon-complete-64.svg') no-repeat 50% 0 / 64px;
            }
        }
        &.type-email {
            padding-bottom: 120px;
        }
        &.type-call,
        &.type-remote {
            padding-bottom: 80px;
        }
        .btn-wrap {
            margin-top: 32px;
        }
    }
}
.support.service-status-detail {
	.complete-summary {
		min-height: 200px;
		border-radius: 16px;
		margin-bottom: 80px;
		background-color: #f8f8f8;
        padding: 0 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
		.box {
			justify-content: center;
			min-height: 200px;
            padding:0;
            width: 100%;
            justify-content: flex-start;
            &.center {
                justify-content: center;
            }
		}
		.summary-img {
            margin-right: 40px;
			img {
				border-radius: 40px;
			}
		}
		.summary-info {
			.status {
				margin-top: 0 !important;
                font-size: 20px;
                line-height: 30px;
                text-align:left;
                word-break:keep-all;
			}
		}
        .btn-wrap {
            display: flex;
            justify-content: flex-end;
            width: 50%;
            .btn {
                min-width: auto;
            }
            .btn + .btn {
                margin-left: 8px;
            }
        }
	}
}

@include tablet {
    .support.service-status-detail {
        .complete-summary {
            flex-wrap: wrap;
            flex-direction: column;
            .box {
                justify-content: center;
                min-height: 120px;
                margin-top: 30px;
            }
            .btn-wrap {
                justify-content: center;
                margin: 30px 0;
            }
            .summary-info {
                padding-left: 0;
                text-align: center;
            }
        }
    }
}

.inquiry-list {
    .title {
        strong {
            font-weight:700;
            color:#000;
        }
    }
    .description {
        font-weight: normal;
        .btn {
            display:inline-block;
            margin: -3px 0 0 8px;
        }
        &.wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            .line {
                margin-right: 8px;
                white-space:normal;
            }
            .btn {
                margin: 0;
            }
        }
        .point {
            color: $color-primary;
        }
    }
}
@include tablet {
    .inquiry-list {
        .description {
            &.wrap {
                flex-direction: column;
                align-items: flex-start;
                .btn {
                    margin: 8px 0 0 0;
                }
            }
        }
    }
}

.bullet-list {
    color: #000;
    .b-txt {
        &::before {
            background: #000;
        }
    }
}
.bullet-list.top-border {
	margin-top: 48px;
}
.bottom-banner {
	margin-top: 80px;
	a {
		display: flex;
		align-items: center;
		width: 100%;
		height: 180px;
		padding-left: 6%;
		border-radius: 16px;
		background-repeat: no-repeat;
		background-position: calc(100% + 300px) 0;
		background-size: cover;
		.txt {
			font-size: 28px;
			font-weight: 700;
			line-height: 36px;
		}
	}
}



//신규
.contents > .tabs-wrap {
    width: 100%;
    .tabs {
        padding: 0;
    }
}

 
.tit-wrap {
    h3.title{
        margin-bottom:48px;
        text-align:left;
        font-size:32px;
    }
    .sub-tit {
        font-size: 20px;
        .point {
            color: #ea1917;
        }
        border-bottom: 2px solid #222;
        padding-bottom: 16px;
    }
    h2.tit {
        .tooltip-wrap {
            height: 32px;
        }
        .tooltip-wrap .tooltip-icon {
            width: 32px;
            height: 32px;
        }
        
        .tooltip-wrap .tooltip-box {
            display: none;
            position: absolute;
            left: -8px;
            top: 36px;
        }
    }
    
    
    & ~ .no-data {
        padding:70px 0 120px;
        border-bottom:1px solid #ddd;
    }
}

@include tablet {
    .tit-wrap {
        h2.tit {
            .tooltip-wrap {
                position: initial;
            }
            
            .tooltip-wrap .tooltip-box {
                left: 0;
                top: 90%;
            }
        }
    }
}


.step-content {
    max-width: 1380px;
    margin: 0 auto;
}

.accord-wrap .section-box,
.service-status .section-box {
    max-width: 1146px;
    &.wide {
        max-width: auto;
        

    }
}

.accord-wrap .section-box.wide {
    max-width:100%;
}
.text-form .input-wrap {
    width: 100%;
}

.req-info {
    margin-top: 80px;

    p.req {
        font-size: 14px;
        line-height: 24px;
    }
}
.accord-wrap {
    .tit-wrap {
        .tit {
            width: 100%;
        }
        .accord-btn {
            display: flex;
            position: relative;
            padding: 0;
            font-size: 0;
            &::after {
                content: " ";
                position: absolute;
                top: 50%;
                right: 0;
                width: 24px;
                height: 24px;
                margin-top: -12px;
                background: url("/lg5-common/images/icons/btn-arr-24x24-black.svg") no-repeat 0 0;
                background-size: 24px;
                transform: rotate(-270deg); //디폴트 close
                //transform: rotate(-90deg); //디폴트 open
            }
        }
    }

    .section-box {
        display: none;
    }
}

.accord-wrap.open {
    .tit-wrap {
        .accord-btn {
            &::after {
                transform: rotate(-90deg);
            }
        }
    }
    .section-box {
        display: block;
    }
}



// 온라인상담 문의유형선택
.rdo-wrap {
    &.btn-type4 {
        input + label {
            display: block;
            position: relative;
            height: 40px;
            padding: 6px 16px 8px;
            line-height: inherit;
            border-radius: 20px;
            text-align: center;
            border: 1px solid #000;
            &:before, 
            &:after {
                display: none;
            }

            span {
                display: inline-block;
                font-size: 16px;
                font-weight: 500;
                line-height: 26px;
            }
        } 

        input:checked + label {
            color: #fff;
            background-color: #000;
            &:after {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border: 2px solid #000;
                border-radius: 20px;
                background-image: none;
                color: #fff;
            }

            @mixin tablet-size-s {
                span {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }

    }
}


.topic-wrap {
    display: flex;
    flex-direction: column;

    .topics {
        ul {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            li {
                float: left;
                margin-right:8px;
                // &:nth-child(n+9) {
                //     margin-top: 8px;
                // }
                // &:nth-child(9) {
                //     clear: left;
                //     margin-left: 0;
                // }
                &:last-child{
                    margin-right: 0;
                }

                @include tablet {
                    & + li {margin-left: 6px;}
                }
            }
        }
        @include tablet {
            .rdo-wrap.btn-type4 input + label {
                padding: 6px 10px 8px;
                letter-spacing: -0.9px;
            }
        }
    }

    .topics.show { display: block; }
    .topics.hide { display: none; }
    .banner-wrap {
        margin: 32px 0;
    }
    .topic-notice {
        text-align: center;
        padding: 40px 0;
        color: #aaa;
    }
    .topic-notice.show { display: block; }
    .topic-notice.hide { display: none; }
}
// .rdo-wrap.btn-type4 input + label {
// 	display: block;
// 	position: relative;
// 	height: 40px;
// 	padding: 6px 16px 8px;
// 	line-height: inherit;
// 	border-radius: 20px;
// 	text-align: center;
//     border: 1px solid #000;
// }

// @include tablet {
//     .topic-wrap .topics ul li + li {margin-left: 6px;}
//     .topics .rdo-wrap.btn-type4 input + label {
//         padding: 6px 10px 8px;
//         letter-spacing: -0.9px;
//     }
// }

// .rdo-wrap.btn-type4 input + label:before, .rdo-wrap.btn-type4 input + label:after {
// 	display: none;
// }

// .rdo-wrap.btn-type4 input + label span {
// 	display: inline-block;
// 	font-size: 16px;
// 	font-weight: 500;
// 	line-height: 26px;
// }

// @media screen and (max-width: 767px) {
// 	.rdo-wrap.btn-type4 input + label:checked + label span {
// 		font-size: 14px;
// 		line-height: 18px;
// 	}
// }

// .rdo-wrap.btn-type4 input:checked + label {
// 	color: #fff;
//     background-color: #000;
// }

// .rdo-wrap.btn-type4 input:checked + label:after {
// 	content: '';
// 	display: block;
// 	position: absolute;
// 	left: 0;
// 	top: 0;
// 	width: 100%;
// 	height: 100%;
// 	border: 2px solid #000;
// 	border-radius: 20px;
//     background-image: none;
//     color: #fff;
// }

// .form-wrap .forms .conts .rdo-wrap + .input-wrap {
//     display:inline-block;
// }

// .topic-wrap {
//     display: flex;
//     flex-direction: column;
// }

.sub-topic {
    display: flex;
    .select-wrap { 
        margin-top: 32px;
        width: calc(50% - 12px);
    }
    .select-wrap + .select-wrap {
        margin-left: 24px;
    }
}

.forms {
    .prdGrp-wrap {
        display: flex;
        .select-wrap { 
            margin: 0;
            width: calc(50% - 12px);
        }
        .select-wrap + .select-wrap {
            margin-left: 24px !important;
        }
    }
    .conts {
        .rdo-wrap + .input-wrap {
            display:inline-block;
        }
    }

}



.goods-selected-wrap {
    width: 100%;
    height: 80px;
    //line-height: 80px;
    display: block;
    margin: 0 auto 48px;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    color: #000;
    border-radius: 16px;
    .box {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .prod-info {
            font-size:0;
            display: flex;
            //min-height: 80px;
            align-items: center;
            .flag-wrap {
                margin-right: 16px;
                .flag {
                    background-color: #fff;
                }
            }
            .tit {
                color:#000;
                @include font-medium;
                font-weight:700;
            }
            .product {
                display:inline-flex;
                font-size:0;
                li {
                    display:flex;
                    align-items: center;
                    color:#000;
                    @include font-medium;
                    font-weight:$font-weight-medium;
                    & + li {
                        position:relative;
                        margin-left:16px;
                        padding-left:30px;
                        &:before {
                            content:'';
                            position:absolute;
                            left:0;
                            top:5px;
                            width:16px;
                            height:16px;
                            background:url(/lg5-common/images/CS/ico_right_16.svg) no-repeat;
                            background-size:100%;
                        }
                        span {
                            display:inline-block;
                            margin-right:8px;
                            padding:4px 12px 6px;
                            font-size:12px;
                            font-weight:$font-weight-medium;
                            line-height:1;
                            vertical-align:top;
                            border-radius:4px;
                            border:1px solid rgba(255, 255, 255, .4);
                            transform:translateY(2px);
                        }
                    }
                }
            }
            .btn-add-product {
                display:inline-block;
                margin-left:24px;
                padding-right:20px;
                vertical-align:middle;
                background:url(/lg5-common/images/icons/icon-more-plus_16_w.svg) no-repeat;
                background-size:16px;
                background-position:right center;
                span {
                    color:#fff;
                    @include font-medium;
                    text-decoration:underline;
                }
            }
        }
        .prod-btn {
            .btn-reset {
                &.reset {
                    position:relative;
                    padding:0;
                    min-width: auto;
                    line-height: initial;
                    color:#000;
                    border-radius: 0;
                    border: none;
                    border-bottom:1px solid #000;
                    background:transparent;
                    &:before {
                        content:'';
                        position:absolute;
                        left:-18px;
                        top:50%;
                        width:16px;
                        height:16px;          
                        background:url(/lg5-common/images/CS/icon/icon_reset_16.svg) no-repeat;
                        background-size: 16px 16px;
                        transform:translateY(-50%);
                    }
                }
            }
        }
    }
    
}

.goods-selected-wrap.prodList {
    display: none;
}

.goods-selected-wrap.prodList .box .prod-info .product li:nth-child(3) {
    display: none;
}

.container .step-box .form-infos.first-type,
.form-infos.first-type {
    margin-bottom: 16px;
}

.step-box.date-info-wrap .btn-group {
    display: block !important;
}

.thumb-rdo-wrap label .img {
    border-radius: 24px;
}

.thumb-rdo-wrap label input[type=radio]:checked ~ .txt:before {
    background: url(/lg5-common/images/bg-input-default.svg) center no-repeat;
}

.thumb-rdo-wrap label input[type=radio]:checked ~ .txt:after {
    background-color: #000;
}

.rdo-list-wrap.thumb-type {
    .rdo-list {
        margin-top: 0;
        li {
            width:25%;
            padding: 0 0 24px 24px;
        }
    }
}

.rdo-list-wrap.thumb-type .rdo-list li.block {
    padding: 0 0 0 24px;
    .input-wrap {
        margin-top: 0;
    }
}

.form-wrap .forms.type4 + .forms.type4 {
    margin-top: 24px;
}

.banner-wrap .banner {
    padding: 40px 50px;
    .btn-wrap {
        margin-top: 20px;
        display: flex;
        align-items: center;
        .btn-link {
            font-size: 14px;
            &::after {
                top: 7px;
            }
            margin-right: 16px;
        }
    }
}

.banner-wrap.type-notice {
    .banner {
        padding: 25px 48px;
        .btn-link {
            font-size: 14px;
        }
    }
}

// 챗봇상담
.banner-chat-wrap {
    margin: 32px 0;
    .banner {
        padding: 40px 50px;
        border-radius: 8px;
        background-color: #F6F6F6;
        .cont {
            padding-right: 89px;
            .tit {
                font-size: 20px;
                font-weight: 700;
                line-height: 25px;
            }
        }
    }
    .btn-group {
        margin-top: 12px;
        .btn-chatbot {
            width: auto;
            height: 40px;
            color: $color-primary;
            border: 1px solid $color-primary;
            background-color: transparent;
            background-image: none;
            padding: 0 24px 0 24px;
            border-radius: 40px;
            font-size: 14px;
            line-height: 23px;
            &::before {
                content: '';
                position: relative;
                top: -1px;
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 6px;
                vertical-align: middle;
                background: url('/lg5-common/images/CS/btn-chat-icon.svg');
                background-size: 100% auto;
            }
        }
    }

    .qna-list {
        margin-top: 32px;
        dl {
            dt {
                color: #0F0F0F;
                font-size: 16px;
                line-height: 24px;

                &::before {
                    content: '';
                    position: relative;
                    top: -2px;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    margin-right: 6px;
                    vertical-align: middle;
                    background: url('/lg5-common/images/CS/icon-qna.svg');
                    background-size: 100% auto;
                }
            }
            dd {
                position: relative;
                display: inline-block;
                //height: 52px;
                font-size: 14px;
                line-height: 20px;
                padding: 15px 20px 17px 43px;
                background-color: #FFF;
                color: #000;
                border: 1px solid #EEE;
                border-radius: 8px;
                margin-top: 12px;
                &:not(:last-child) {
                    margin-right: 16px;
                }
                button {
                    font-size: 14px;
                    line-height: 20px;
                    text-align: left;
                    max-height: 40px;
                    text-overflow: initial;
                    white-space: normal;
                    @include textEllipsisClamp(2);
                    &::after {
                        content: '';
                        display: inline-block;
                        width: 18px;
                        height: 18px;
                        margin-left: 4px;
                        background: url("/lg5-common/images/icons/arr-32x32.svg") no-repeat 0 0;
                        background-size: 100%;
                        position: relative;
                        top: 4px;
                        opacity: 0.8;
                    }
                }

                &::before {
                    content: 'Q';
                    position: absolute;
                    display: inline-block;
                    left: 24px;
                    top: 14px;
                    font-size: 16px;
                    line-height: 22px;
                    color: $color-primary;
                    font-weight: bold;
                }
                
            }
        }
    }
}


.date-info-wrap {
    .info-wrap {
        padding-bottom: 32px;
        border-bottom: 1px solid #ddd;
        p {
            font-size: 18px;
            .point {
                color: #ea1917;
            }
        }
        .reser-info + .reser-info {
            margin-top: 14px;
        }
        .ref-info {
            margin-top: 24px;
            font-size: 16px;
        }
    }

    .btn-group {
        margin-top: 32px;
    }

    + .step-box {
        margin-top:120px;
    }
}


.form-wrap.type-tb .forms .conts .keyword-box {
    margin-bottom: 0;
    .keyword-search {
        display:block;
        text-align: left;
        .search-box {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            .search-input {
               width: calc(50% - 12px);
               margin: 0;
               input[type=text] {
                    padding: 10px 15px;
                    padding-right: 47px;
                    border: 1px solid #929292;
                    border-radius: 8px;
                    background: #fff;
                    box-shadow: none;
               }
               .btn-search {
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                background-size: 22px auto;
                width: 50px;
               }
            }
            .search-layer {
                max-width: calc(50% - 12px);
                min-width: auto;
                .keyword-box {
                    .recently-keyword {
                        .tit {
                            width: 100%;
                        }
                    }
                } 
            }
        }
        .search-more {
           position: inherit;
           left: inherit;
           top: inherit;
           float: right;
           transform: none;
           margin: 0 30px 0 24px;
           display: flex;
           align-items: center;
           .btn {
                font-size: 16px;
                border-radius: 0;
                padding: 0;
                height: auto;
                border: none;
                border-bottom: 1px solid #000;
                
                &.question:before {
                    left: inherit;
                    right: -28px;
                    width: 24px;
                    height: 24px;
                    top: 60%;
                    transform: translateY(-60%);
                    background-size: 24px 24px;
                    background: url(/lg5-common/images/CS/icon/icon_question_24.svg) center no-repeat;
                }
           }
           .btn.border:hover, .btn.border:focus, .btn.border:active {
                border-color: #000 !important;
                background: transparent;
                color: #000;
            }
        }
        .search-error {
            margin: 16px 0 0 0;
            line-height: 1;
            &::before {
                vertical-align: middle;
                margin-top: -1px;
            }
        }
        .search-desc {
            display:block;
            color: #8F8F8F;
            font-size: 14px;
            margin-top: 8px;
        }
    }
}


.form-wrap.type-tb {
    .forms {
        min-height: 48px;
    }
    .forms + .forms {margin-top: 24px;}
}
.form-wrap .forms .conts .text {
    font-size:16px;
    line-height:1.8em;
}

.check-info-wrap {
    padding: 40px;
    border-radius: 8px;
    background-color: #f4f4f4;
    .check-info {
        .date {
            font-size: 27px;
            margin-bottom: 22px;
            font-weight: $font-weight-bold;
            min-height: 40px;
            line-height: 40px;
        }
        .text {
            font-size: 18px;
            line-height: 120%;
            p + p { margin-top: 10px; }
        }
        .btn {
            margin-top: 25px;
        }
    }
    &.type-ref {
        margin-top: 32px;
        .tit {
            display: block;
            font-size: 16px;
            margin-bottom: 8px;
        }
        .bullet-list {
            margin-top: 0;
            li {
                .btn-rate {
                    margin: 4px 0 14px;
                }
                .telnum {
                    font-weight: 500;
                }
                &:last-of-type {
                    .btn-rate {
                        margin: 14px 0 0;
                    }
                }
            }
        }
    }
}

.bullet-list .b-txt {
    a.point {
       //border-bottom: 1px solid #ea1917;
       text-decoration: underline;
    }
}
.tooltip-wrap .tooltip-box .bullet-list {
    margin-top:0;
}

.service-engineer .tabs-wrap-new,
.service-solution .tabs-wrap-new,
.online-service .tabs-wrap-new,
.visit-center .tabs-wrap-new,
.suggestions .tabs-wrap-new {
    max-width: 1380px;
    margin: 0 auto 48px;
}

// 보유제품 타이틀 추가
h2.tit {
    & + .my-product-wrap {
        margin-top: 30px;
    }
}
//내 보유상품
.my-product-wrap.type-list {
    max-width: 1396px;
    margin: 0 -9px;
    background: none;
    padding: 0;
    .tit-wrap {
        margin-bottom: 30px;
        padding-left: 10px;
    }
    
    ul{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        li {
            //width: calc(50% - 12px);
            width: 50%;
            .goods-item {
                display: flex;
                align-items:center;
                padding: 32px;
                min-height: 152px;
                border-radius: 16px;
                box-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.14);
                background-color: #fff;
                border: 1px solid transparent;
                .thumb {
                    float: left;
                    display: flex;
                    width: 88px;
                    height: 88px;
                    align-items: center;
                    justify-content: center;
                    background-color: #f4f4f4;
                    border-radius: 100%;
                    margin-right: 32px;
                    img {
                        width: 48px;
                        height: 48px;
                    }
                }
                .info {
                    display: flex;
                    flex-direction: column;
                    width:calc(100% - 120px);
                    .cate {
                        color: #ea1917;
                        font-size: 12px;
                        font-weight: $font-weight-bold;
                        margin-bottom: 6px;
                    }
                    .name {
                        font-size: 18px;
                        font-weight: $font-weight-bold;
                        margin-bottom: 16px;
                        @include textEllipsis;
                    }
                    .code {
                        font-size: 14px;
                    }
                    .status {
                        position:absolute;
                        top:16px;
                        right:16px;
                        padding:3px 6px;
                        font-size:12px;
                        font-weight:500;
                        line-height:1;
                        border:1px solid #ddd;
                        border-radius:4px;
                        color: #666;
                    }
                }
                &:active, &:hover {
                    border:1px solid #ea1917;
                }
            }
        }
        li:nth-child(n+3) {
            margin-top: 24px;
        }
    }
}


.btn.border.more,
.btn.border.close {
	display: inline-flex;
    align-items: center;
    justify-content: space-between;
}

.btn.border.more:before,
.btn.border.close:before {
	content: '';
    display: block;
	width: 16px;
	height: 16px;
	background: url("/lg5-common/images/icons/btn-plus-16.svg") center no-repeat;
	background-size: 16px 16px;
    margin-right: 5px;
}

.btn.border.close:before {
	background: url("/lg5-common/images/icons/btn-minus-16.svg") center no-repeat;
}

.btn.border.more:hover,
.btn.border.close:hover {
	background: #fff;
	border-color: #000;
	color: #000;
}


.tvPs-list .input-wrap {
    display:inline-block !important;
    margin: -10px 0 0 15px !important;
}

// 출장예약조회
.container .page-header .progress-wrap {
    margin-top: 20px;
    margin-bottom: 32px;
    max-width:none;
    &.type2 {
        margin-top:8px;
        margin-bottom:30px;
        .progress-count {
            padding-bottom:8px;
            font-size:12px;
            line-height:1.42;
            text-align:left;
            strong {
                color:#ea1917;
            }
            span {
                color:#8F8F8F;
            }    
        }
        ol {
            overflow: hidden;
            &:before {
                top:50%;
                left:0;
                right:0;
                height:4px;
                transform:translateY(-50%);
                background-color: #8f8f8f;
            }
            li {
                position:relative;
                width:30px !important;
                height:28px;
                padding:0 2px;
                background:#FFF !important;
                z-index:2;
                &:first-of-type {
                    z-index:7;
                }
                &:nth-child(2) {
                    z-index:6;
                } 
                &:nth-child(3) {
                    z-index:5;
                } 
                &:nth-child(4) {
                    z-index:4;
                } 
                &:nth-child(5) {
                    z-index:3;
                }                          
                &:before {
                    width:24px;
                    height:24px;
                    margin:0;
                    border:4px solid #8f8f8f;
                    border-radius:50%; 
                    box-sizing:border-box;
                    content:'';
                }
                &.on {
                    &:before {
                        background-color:#ea1917;                                  
                        border-color:#ea1917;                                     
                    } 
                    &:after {
                        position:absolute;
                        right:30px;
                        width:50vw;
                        height:4px;
                        border-radius: 2px;
                        background-color:#ea1917; 
                        content:'' !important;
                        
                    }    
                }
                &.ing {
                    &:before {
                        background-color:#FFF;                                  
                        border-color:#ea1917;                                     
                    } 
                    &:after {
                        display:block;
                        position:absolute;
                        right:30px;
                        width:50vw;
                        height:4px;
                        border-radius: 2px;
                        background-color:#ea1917; 
                        content:'' !important;
                    }    
                }
                
                &:first-of-type.on,
                &:first-of-type.ing {
                    &:after {
                        display:none;
                    } 
                }
                &:last-of-type.on,
                &:last-of-type.ing {
                    &:after {
                        right:30px;
                    } 
                }
            }
        }
    }
}
.container .page-header.reservationStep {
    padding-left:0;
    padding-right:0;
    &.type2 {
        padding-bottom:0;
    }
    h3.title {
        margin-top:64px;
        text-align:left;
        font-size:32px;
    }
    .tit-wrap {
        margin-top:48px;
        text-align:left;
    }
    .step-info {
        margin-bottom:24px;
        text-align:left;
        .on {
            font-weight:700;
            color:#ea1917;
        }
    }
}


.container .step-box .step-btn-wrap {
    margin: 32px 0 0;
    max-width: 1146px;
    .btn {
        min-width: 102px;
    }
}

.calendar-info {
    .sub-tit {
        font-size: 20px;
        margin-bottom: 12px;
    }
}

//스스로 해결
.solve-sch-wrap {
    background-color: #f6f6f6;
    padding: 32px;
    border-radius: 16px;
    min-height: 140px;
    //height:144px;
    @include mobile {
        padding: 32px !important;
        border: 1px solid;
    }

    .keyword-search {
        max-width: 678px;
        margin: 0 auto;
        .search_box {
            margin: 0 auto;
            .search-input {
                input[type=text] {
                    border: none;
                    border-radius: 24px;
                    padding: 10px 54px 10px 24px;
                    box-shadow: none;
                }
            }
        }
        .btn-search {
            opacity: 1;
            background-size: 24px auto;
            background: url(/lg5-common/images/CS/icon/icon_search_24.svg) no-repeat center center;
        }
        .search-layer {
            border-top-left-radius: 24px;
            border-top-right-radius: 24px;
        }
        &.default {
            .search-box {
                width: 678px;
                margin: 0 auto;
                .search-input input[type=text] {
                    padding: 11px 54px 11px 24px;
                    border: none;
                    border-radius: 50px;
                    box-shadow: none;
                }
            }
        }
        @include tablet {
            &.default {
                .search-box {
                    width: 478px;
                }
            }
        }
    
    }

    
    .keyword-box {
        max-width: 761px;
        margin: 0 auto;
        
        .btn-search {
            opacity: 1;
            background-size: 24px auto;
            background: url(/lg5-common/images/CS/icon/icon_search_24.svg) no-repeat center center;
        }
        .keyword-search {
            text-align: left;
            .search-box {
                width: auto;
                display: flex;
                justify-content: center;
                margin:0 auto;
                .search-input {
                    margin: 0;
                    width: 561px;
                    input[type=text] {
                        border: none;
                        border-radius: 24px;
                        padding: 10px 54px 10px 24px;
                    }
                }
                .search-layer {
                    min-width: 517px;
                    max-width: max-content;
                    border-top-left-radius: 24px;
                    border-top-right-radius: 24px;
                }
                .search-more {
                    position: inherit;
                    left: inherit;
                    top: inherit;
                    float: right;
                    transform: none;
                    margin: 0 30px 0 24px;
                    display: flex;
                    align-items: center;
                    .btn {
                        font-size: 16px;
                        border-radius: 0;
                        padding: 0;
                        height: auto;
                        border: none;
                        border-bottom: 1px solid #000;
                        &.question:before {
                            left: inherit;
                            right: -28px;
                            width: 24px;
                            height: 24px;
                            top: 60%;
                            transform: translateY(-60%);
                            background-size: 24px 24px;
                            background: url(/lg5-common/images/CS/icon/icon_question_24.svg) center no-repeat;
                        }
                    }
                }
            }
            .no-keyword {
                margin-top:12px;
                padding:0 8px;
                font-size:14px;
                color:#ff3723;
                &:before {
                    content:'!';
                    display:inline-block;
                    font-size:10px;
                    width:14px;
                    height:14px;
                    margin-right:4px;
                    border:1px solid #ff3723;
                    border-radius:50%;
                    vertical-align:middle;
                    line-height:1;
                    text-align:center;
                }
                &.type2 {
                    color:#000;
                    &:before {
                        display:none;
                    }
                }
            }
            .search-desc {
                padding:0 8px;
                margin-top:8px;
                color: #767676;
                font-size: 14px;
            }
        }
    }

    .search-recommend {
        text-align: center;
        margin-top: 16px;
        dl{
            display: inline-flex;
            align-items: center;
            margin: 0 auto;
            font-size: 14px;
            color: #666;
            dt {
                position: relative;
                padding-right: 16px;
                margin-right: 16px;
                font-weight: $font-weight-bold;
                &:after {
                    position: absolute;
                    top: 57%;
                    right: 0;
                    transform: translateY(-57%);
                    content: "";
                    display: block;
                    width: 1px;
                    height: 10px;
                    background-color: #ddd;
                }
            }
            dd {
                
            }
            dd + dd {
                margin-left: 16px;
            }
        }
    }

    .btm-more {
        width: 678px;
        margin: 0 auto;
        .err-msg {
            margin-top: 8px;
            color: #ea1917;
            &::before {
                margin-right: 0;
            }
        }
    }

    @include tablet {
        .btm-more {
            width: 90%;
        }
    }

    #solutions-tab {
        margin-top:-40px;
    }
}

.solve-sch-wrap + .form-infos.first-type {
    margin-top: 48px;
}

.solve-sch-wrap + .step-box {
    margin-top: 64px;
}

.list-sorting.type-line {
    margin-top: 48px;
    border-bottom: 1px solid #666;
    padding-bottom: 14px;
    .list-count {
        line-height: 26px;

        em {
            color:#ea1917;
        }
    }
    .switch-wrap {
        float: right;
        margin-right: 16px;
        &.left {
            float: left;
            margin: 0 0 0 16px;
        }
    }
}

.solution-result-wrap {
    .tit-wrap {
        margin-bottom: 0 !important;
        .sub-tit {
            padding-bottom:0;
            border-bottom:none;
        }
        .tit {
            position:relative;
            .btn-more {
                position:absolute;
                right:0;
                top:10px;
                font-size:14px;
                font-weight:400;
                padding-right:20px;
                background:url('/lg5-common/images/icons/btn_icon_arrow_right_32.svg') no-repeat 100% 50% / 16px;
            }
        }
    }

    .no-data {
        padding: 120px 0;
        border-bottom: 1px solid #ddd;
    }
}
.no-data {
    &.guide {
        line-height:24px;
        color:#666;
    }
}


//BTOCSITE-52307 LG닷컴 콘텐츠 목록에 "조회수" 숫자 표기 
// 스스로해결 결과 목록
.solution-result-list {
    ul {
        li {
            border-bottom: 1px solid #ddd;
            @include tablet {
                a {
                    // flex-wrap: wrap;
                    // gap: 6px;
                    padding: 16px 0;                    
                }
            }
            a {
                // display: flex;
                // justify-content: space-between;
                display: table;
                width: 100%;
                min-height: 74px;
                font-size: 16px;
                color: #666;
                div {
                    // display: flex;
                    // align-items: center;
                    display: table-cell;
                    vertical-align: middle;
                    padding: 0 24px;
                }
                @include tablet {
                    div {
                        display: block !important;
                        padding: 0;
                    }
                }
                .category {
                    min-width: 180px;
                    font-size: 14px;
                    @include tablet {
                        min-width: auto;
                        margin-bottom: 6px;
                        font-size: 13px;
                        line-height: 15px;
                    }
                }
                .title {
                    font-size: 16px;
                    // border-bottom: none;
                    // margin-bottom: 0;
                    // padding-bottom: 0;
                    font-weight: $font-weight-bold;
                    text-align: left;
                    width: 100%;
                    color: #000;
                    @include tablet {
                        line-height: 22px;
                        margin-bottom: 6px;
                    }
                    .txt {
                        @include textEllipsisClamp(1);
                        line-height: 1.5;
                    }
                    .keyword {
                        color: #ea1917;
                    }
                    .icon-movie {
                        display: inline-block;
                        width: 25px;
                        height: 25px;
                        background: url(/lg5-common/images/icons/btn-play-32-black.svg) no-repeat;
                        background-size: 25px;
                        margin-left: 8px;
                        vertical-align: text-top;
                    }
                }
                .info {
                    min-width: 128px;
                    text-align: center;
                    font-size: 14px;
                    @include tablet {
                        float: left;
                        min-width: auto !important;
                        font-size: 11px;
                        line-height: 13px;
                        color: #666;
                    }
                }
                .hits {
                    min-width: 128px;
                    text-align: center;
                    font-size: 14px;
                    @include tablet {
                        position: relative;
                        float: left;
                        min-width: auto !important;
                        font-size: 14px;
                        margin-left: 6px;
                        padding-left: 7px;
                        font-size: 11px;
                        line-height: 13px;
                        color: #666;
                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 2px;
                            width: 1px;
                            height: 10px;
                            background: #ddd;
                        }
                    }
                }
            }
        }
    }
}

/* BTOCSITE-55571 스스로 해결 콘텐츠 Survey 화면 변경 및 데이터 Tracking */

/*
.common-banner.type-col3 .service-list-wrap .service-list > li {
	display: inline-block;
	width: calc(100% / 3);
	margin: 0 12px;
    padding: 0;
    max-width: 288px;
}

.common-banner.type-col5 .service-list-wrap .service-list > li {
	display: inline-block;
	width: calc(100% / 5);
	margin: 0 12px;
    padding: 0;
    max-width: 288px;
}

@include tablet {
    .common-banner.type-col3 .service-list-wrap .service-list {
        margin-top: 0;
        & > li {
            width: calc((100% / 3) - 36px);
        }
    }

}

.common-banner.type-col5 .service-list-wrap .service-list{
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 10px;
    > li {
        display: inline-block;
        width: calc(100% / 5);
        padding: 0;
        max-width: 252px;
    }
}

@media screen and (max-width: 1450px) {
    .common-banner.type-col5 .service-list-wrap .service-list {
        flex-wrap: wrap;
        justify-content: center;
        gap: 50px 10px;
        > li {
            width: calc(100% / 3);
            max-width: 30%;
            .desc {
                width:50%;
                min-width:200px;
                margin:0 auto;
                letter-spacing: -0.8px;
            }
        }
    }
}
*/

.service-list-wrap .service-list > li a .icon img {
    height: 80px;
    width: auto;
}


.service-list-wrap .service-list > li .desc {
	display: flex;
	font-size: 14px;
	line-height: 150%;
    color: #666;
    margin-top: 8px;
    word-break:keep-all;
}

.container .cont-wrap .section .tit-wrap .tit .point {
    color:#ea1917;
}

// BTOCSITE-55571 스스로 해결 콘텐츠 Survey 화면 변경 및 데이터 Tracking
// 스스로해결
.common-banner {
    &.type-col3 {
        .service-list-wrap {
            .service-list {
                > li {
                    display: inline-block;
                    width: calc(100% / 3);
                    margin: 0 12px;
                    padding: 0;
                    max-width: 288px;
                }
            }
            @include tablet {
                .service-list {
                    margin-top: 0;
                    & > li {
                        width: calc((100% / 3) - 36px);
                    }
                }
            }
        }
    }
    &.type-col5 {
        .service-list-wrap {
            .service-list {
                margin: 0;
                display: flex;
                justify-content: center;
                gap: 10px;
                > li {
                    display: inline-block;
                    //width: calc(100% / 5);
                    margin: 0 12px;
                    padding: 0;
                    max-width: 252px;
                    .tit {
                        .icon-arrow {
                            display:none;
                        }
                    }
                }
                
            }
        }
    }
}


//온라인상담
// .online-service {
//     .step-content {
//         // padding-top:48px;
//     }
// }

.service-include-wrap {
    margin-top:80px;
    .rdo-list-wrap .rdo-list {
        margin-left:-16px;
        li {
            padding-left:16px;
        }
    }
}

#ratesWarrantyGuidePopup {
    .pop-conts {
        position:relative;
        padding-top:20px;
        @include mobile {
            padding-top:10px;
        }
    }
    .tooltip-wrap.share {
        right:30px;
        top:130px;
        z-index:1;
        @include mobile {
            top:105px;
        }
        .tooltip-box {
            padding-right:32px;
        }
    }
}

//온라인상담 서비스 선택탭
.select-service-tab {
    margin-top:120px;
    .title {
        padding-bottom:16px;
        border-bottom:2px solid #000;
        font-size:28px;
        font-weight:700;
    }
    .select-service {
        margin-top:48px;
        ul {
            display:flex;
            gap:0 24px;
            li {
                position:relative;
                width:257px;
                height:160px;
                @include tablet {
                    width:25%;
                    height:0;
                    padding-top:12.5%;
                }
                a {
                    position:absolute;
                    left:0;
                    top:0;
                    display:flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction :column;
                    width:100%;
                    height:100%;
                    border-radius:8px;
                    border:1px solid #fff;
                    box-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.16);
                    font-weight: 700;
                    &:before {
                        content:'';
                        display:block;
                        width:31%;
                        height:0;
                        padding-top:31%;
                        margin-bottom:8px;
                        background-repeat:no-repeat;
                        background-position:0 0;
                        background-size:100%;
                    }
                    &:hover {
                        border-color:#ea1917;
                        color:#ea1917;
                    }
                    @include tablet {
                        font-size:13px;
                    }
                }
                // BTOCSITE-68966 고객지원 > EMS 선셋에 따른 이메일문의 수정
                &:nth-child(1) a:before {background-image:url('/kr/support/images/icon/icon_consulting-inquiry.svg');}
                &:nth-child(2) a:before {background-image:url('/lg5-common/images/CS/icon/icon_call_us_80.svg');}
                &:nth-child(3) a:before {background-image:url('/lg5-common/images/CS/icon/icon_remote_80.svg');}
                &:nth-child(4) a:before {background-image:url('/lg5-common/images/CS/icon/icon_video_80.svg');}
                &:nth-child(5) a:before {background-image:url('/lg5-common/images/CS/icon/icon_sign_language_80.svg');}
                &.on a {
                    border-color:#ea1917;
                    color:#ea1917;
                }
            }
        }
    }
}
//스스로해결
// BTOCSITE-44508 노트북/PC용 통합 LG Update 배너 노출 추가 및 배너 시인성 강화
.service-solution {
    .solve-sch-wrap + .banner-wrap {
        margin-top: 40px;
    }
    .banner-wrap.toggle-banner {
        margin: 0 0 40px;
        .cont {
            padding-right: 22px;
        }
        .banner {
            justify-content: left;
            background:url('/lg5-common/images/CS/bg_downloadcenter_banner_pc.jpg') #D3E5FD no-repeat right 50% / auto 100%;
        }
    }
}

.form-downset {
	margin-bottom: 64px;
    &:after {
        content: "";
        display: block;
        clear: both;
    }
    .forms {
        float: left;
        width: calc(50% - 12px);
        .tit {
            display: block;
            width: 100%;
            margin-bottom: 8px;
            padding: 0;
            label {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
            }
        }
        .conts {
            display: block;
            width: 100%;
        }
    }

    .forms + .forms {
        float: right;
        margin: 0;
    }    
}


@media screen and (max-width: 767px) {
    .service-solution .section .form-downset {
        margin-bottom: 52px;
        .forms {
            float: none;
            width: 100%;
            .tit {
                display: block;
                width: 100%;
                margin-bottom: 6px;
                padding: 0;
                label {
                    font-size: 13px;
                    line-height: 21px;
                }
            }
            .conts {
                display: block;
                width: 100%;
            }
        }
        .forms + .forms {
            float: none;
            margin-top: 24px;
        }
    }
}

// 제품사용설명서
.contents.support {
    .download-list-wrap {
        margin-top: 32px;
        & > .desc {
            position:relative;
            margin-bottom:16px;
            padding-left:11px;
            @include font-small;
            &:before {
                content:'';
                position:absolute;
                left:0;
                top:50%;
                transform:translateY(-50%);
                width:3px;
                height:3px;
                border-radius:50%;
                background:#000;
            }
        }
    
        .download-list {
            display: flex;
            flex-wrap: wrap;
            margin: -16px -8px 0;
            &:after {
                content: "";
                display: block;
                clear: both;
            }
            .tit {
                display: block;
                font-size: 16px;
                line-height: 26px;
                font-weight: 700;
                min-height: 28px;
                a, button {
                    font-size: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                    vertical-align: top;
                    text-align: left;
                }    
                @include tablet-size-s {
                    font-size: 14px;
                    line-height: 22px;
                    min-height: auto;
                }
            }
            > li {
                float: left;
                width: calc(50% - 16px);
                margin: 16px 8px 0;
                padding: 24px 32px;
                border-radius: 8px;
                box-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.14);
                .desc {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
            .options {
                color: #767676;
                font-size: 14px;
                line-height: 24px;
                @include tablet-size-s {
                    font-size: 13px;
                    line-height: 21px;
                }
                &:before {
                    content: "";
                    display: block;
                    clear: both;
                }
                > li {
                    position: relative;
                    display: inline;
                    float: left;
                    &:before {
                        content: '';
                        display: inline-block;
                        width: 1px;
                        height: 10px;
                        margin: 7px 8px 0;
                        background: #ddd;
                    }
                    &:after {
                        display: none;
                    }
                    &:first-child {
                        margin: 0;
                        padding: 0;
                        &:before {
                            content: none;
                        }
                    }
                    &:nth-child(2) {
                        margin-right: initial !important;
                    }
                    &.part {
                        margin-left: 15px;
                        &:before {
                            width: 0;
                            height: 0;
                        }
                    }
                }
            }
    
            .desc {
                height: 48px;
                font-size: 14px;
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                @include tablet-size-s {
                    font-size: 13px;
                    line-height: 21px;
                }
            }
    
            .btn-wrap {
                &:after {
                    content: "";
                    display: block;
                    clear: both;
                }
                .btn {
                    float: left;
                }
                .btn + .btn {
                    margin-left: 8px;
                }
                &:only-child {
                    margin-left: auto;
                }    
            }
        
            .info-wrap {
                display: flex;
                margin-top: 22px;
                align-items: center;
                justify-content: space-between;
            }
            @include tablet {
                // .info-wrap {
                //     flex-wrap: wrap;
                // }

                margin: 0;
                .desc {
                    height: auto;
                }
                .info-wrap {
                    display: flex;
                    margin-top: 8px;
                    .btn-wrap {
                        margin-top: 8px;
                        text-align: center;
                        .btn {
                            float: none;
                        }
                    }
                }
                .options > li:after {
                    margin: 6px 6px 0;
                }

                & > li {
                    float: none;
                    width: 100%;
                    margin: 12px 0 0;
                    padding: 20px;
                    border-radius: 4px;
                    box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.14);
                }
            }
        }
    }
}

// .support .download-list-wrap .download-list .tit a, 
// .support .download-list-wrap .download-list .tit button {
// 	font-size: inherit;
// 	font-weight: inherit;
// 	line-height: inherit;
// 	vertical-align: top;
// 	text-align: left;
// }

// @media screen and (max-width: 768px) {
// 	.support .download-list-wrap .download-list .tit {
// 		font-size: 14px;
// 		line-height: 22px;
//         min-height: auto;
// 	}
// }

// @media screen and (max-width: 767px) {
// 	.support .download-list-wrap .download-list .desc {
// 		font-size: 13px;
// 		line-height: 21px;
// 	}
//     .support .download-list-wrap .download-list .options {
// 		font-size: 13px;
// 		line-height: 21px;
// 	}
// }


// @media screen and (min-width: 1025px) and (max-width: 1200px) {
//     .support .download-list-wrap .download-list .info-wrap {
//         display: flex;
//         flex-direction: column;
//         align-items: flex-start;
//         justify-content: space-between;
//         .btn-wrap {
//             margin-top: 8px;
//         }
//     }
// }

// @include tablet {
// 	.support .download-list-wrap .download-list {
// 		margin: 0;
//         .desc {
//             height: auto;
//         }
//         .info-wrap {
//             display: flex;
//             margin-top: 8px;
//             .btn-wrap {
//                 margin-top: 8px;
//                 text-align: center;
//                 .btn {
//                     float: none;
//                 }
//             }
//         }
//         .options > li:after {
//             margin: 6px 6px 0;
//         }
// 	}
// 	.support .download-list-wrap .download-list > li {
// 		float: none;
// 		width: 100%;
// 		margin: 12px 0 0;
// 		padding: 20px;
// 		border-radius: 4px;
// 		box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.14);
// 	}

// }


.board-view-wrap .board-view-body .view-content .view-content-img.full img{
    width: 100%;
}

.container .cont-wrap .section.survey-box,
.container .cont-wrap .section.survey-form-box {
    margin-top: 64px;
}
.survey-box {
    background-color: #f4f4f4;
    padding:54px 117px;
    display: flex;
    justify-content: space-between;
    border-radius: 16px;
    align-items: center;
    .tit {
        position:relative;
        padding:0 0 0 84px;
        font-size: 24px; 
        font-weight:600;
        min-height: 60px;
        display: flex;
        align-items: center;
        &:before {
            content:'';   
            position:absolute;
            left:0;
            top:50%;
            width:60px;
            height:60px;
            transform:translate(0, -50%);
            background:url("/lg5-common/images/CS/icon/icon_evaluation_80px.svg") no-repeat;
            background-size:100%;
        }
    }
    .btn-wrap {
        .btn {
            float: left;
        }
        .btn + .btn { margin-left: 10px;}
    }
}

// 스스로해결 -문제해결 상세 서베이
.survey-form-box {
    background-color: #f4f4f4;
    padding:54px 117px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 16px;
    align-items: center;
    .tit {
        position:relative;
        @include font-large-2;
        font-weight:600;
        min-height: 60px;
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        width: 100%;
    }
    .text-form {
        width: 100%;
        textarea + .inner-text {
            background-color: #fff;
        }
        // BTOCSITE-55571 스스로 해결 콘텐츠 Survey 화면 변경 및 데이터 Tracking
        .tit {
            @include font-large;
            margin-bottom: 10px;
        }
    }
    .btn-wrap {
        margin-top: 24px;
        .btn {
            min-width: 100px;
        }
    }
}

@include tablet {
    .survey-box,
    .survey-form-box {
        padding: 54px 57px;
        
    }
    // BTOCSITE-55571 스스로 해결 콘텐츠 Survey 화면 변경 및 데이터 Tracking
    .survey-form-box {
        .text-form {
            .tit {
                @include font-medium;
            }
        }
    }
}
.board-view-wrap .board-view-head {
    position:relative;
    .share {
        position:absolute;
        right:0;
        bottom:15px;
    }
}
.board-view-wrap .board-view-head .head-infos > li:before {
    content: '';
	position: absolute;
	left: 0;
	top: 8px;
	width: 1px;
	height: 10px;
	background: #ddd;
}

.contents.support {
    .bullet-list {
        .b-txt {
            color: #000;
            &::before {
                background-color: #000;
            }
        }
    }

    .image-file-wrap {
        .file-name {
            .name {
                &::placeholder {
                    color: #8F8F8F;
                }
            }
        }
        .file-item {
            &.on {
                .file-image {
                    .file-preview {
                        //BTOCSITE-77120 고객지원 > 출장예약 > 소음증상일 때 파일업로드 기능 추가
                        &.icon-mov {
                            background: none;
                            img {
                                display: none;
                            }
                            &::after {
                                content: '';
                                display: inline-block;
                                width: 50px;
                                height: 50px;
                                position: absolute;
                                top: calc(50% - 25px);
                                left: calc(50% - 25px);
                                background:url(/lg5-common/images/icons/icon-complete-64.svg) no-repeat;
                                background-size:100%;
                                opacity: 0.7;
                            }
                        }
                    }
                }
            }

            .file-image {
                .file-preview {
                    &.icon-mov {
                        background: url(/lg5-common/images/CS/icon/icon_addfile.svg) no-repeat;
                        background-size: 100%;
                    }
                }
                .file-btns {
                    .btn-upload {
                        //transform: rotate(135deg);
                        background: url(/lg5-common/images/CS/icon/ic_addfile_24.svg) no-repeat;
                    }
                    .btn-del {
                        transform: rotate(135deg);
                        background: url(/lg5-common/images/CS/icon/ic_addfile_24.svg) no-repeat;
                    }
                }
            }
        }
    }

}

.email-wrap{
    display: flex;
    align-items: center;
    .input-wrap {
        width:calc((100% - 25px - 30%));
    }
    .select-wrap {
        width: 30%;
        margin-top: 0 !important;
        @include mobile {
            width: 40%;
        }
        select {
            display: inline-block;
            width: 100%;
            border: 1px solid #929292;
            border-radius: 8px;
            color: #000;
            font-size: 16px;
            line-height: 26px;
            vertical-align: top;
            resize: none;
            height: 48px;
            padding: 10px 15px;
            top: -23px;
        }
    }
    .mark {
        font-size: 16px;
        color: #666;
        width: 25px;
        text-align: center;
    }
    &.no-mark {
        .mark {
            display: none;
        }
        .input-wrap {
            width: calc((100% - 30%));
        }
        .select-wrap {
            margin-left: 10px;
        }
    }
}

.form-wrap .forms .conts .input-wrap.input-direct {
    display: none;
}

.btm-more {
    .desc-text {
        font-size: 14px;
        color: #8F8F8F;
    }
}

.service-tit {
    font-size: 28px;
    margin-top:120px;
    .point {
        color:#ea1917;
    }
}

.service-tit + .form-infos.first-type{
    margin-top: 48px;
}

.check-info-wrap .directCall-wrap {
    max-width: 100%;
    margin: 32px auto 40px;
    padding:0;
    >ul {
        >.lists {
            margin-top: 0;
            border-radius: 16px;
            padding:0 32px;
            .head {
                .accord-btn {
                    padding:24px 0;
                    &::after {
                        content: '더보기';
                        border: none;
                        width: auto;
                        height: auto;
                        right: 0;
                        font-size: 16px;
                        color: #000;
                        line-height: 1.63;
                        transform: translateY(-50%);
                        margin-top: 0;
                        padding-right: 20px;
                        background: url(/lg5-common/images/icons/btn-plus-16.svg) no-repeat right center;
                        background-size: 15px;
                        text-decoration: none;
                    }
                    .subtitle {
                        strong {
                            font-weight: $font-weight-bold;
                        }
                    }
                }
            }
            .accord-cont {
                padding: 32px 0;
                border-top: 1px solid #ddd;
                .inquiry-dl {
                    dt {
                        display: none;
                    }
                    .inquiry-list {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 24px 0;
                        li{
                            width: calc(100% / 3);
                            display: flex;
                            flex-direction: column;
                            margin: 0;
                            .title {
                                width: 100%;
                                strong {
                                    font-weight: normal;
                                }
                                
                            }
                            .description {
                                width: 100%;
                                margin-top: 4px;
                                font-weight: $font-weight-bold;
                            }
                        }
                        
                    }
                }
            }
            &.on {
                box-shadow : none;
                .head {
                    .accord-btn {
                        &::after {
                            content: '접기';
                            background-image: url(/lg5-common/images/icons/btn-minus-16.svg);
                        }
                    }
                }
            }
        }
    }
}


@include tablet {
    .check-info-wrap .directCall-wrap > ul > .lists .accord-cont .inquiry-dl .inquiry-list li {
        width: 50%;
    }
}

.bullet-list {
    li {
        .btn-download {
            padding:3px 36px 3px 8px;
            vertical-align:middle;
            font-size: 12px;
            font-weight: $font-weight-medium;
            position: relative;
            color: $color-primary;
            text-decoration: underline;
            margin-top: -3px;
            &:after {
                content:'';
                position:absolute;
                top:50%;
                right:16px;
                width:16px;
                height:16px;
                margin-top:-8px;
                background:url(/lg5-common/images/CS/icon/ic_download_16.svg) center no-repeat;
                background-size:100%;
            }
        }
        .b-txt {
            .point {
                color: $color-primary;
            }
        }
    }
}

//출장서비스
.contents.service-engineer {
    .section-box {

        h5.sub-tit {
            font-size: 16px;
        }
    }
    .step-box:not([style*="display: none"]) + .step-box {
        margin-top: 120px;
    }

    .info-list {
        margin-top: 30px;
        padding: 0 10px;
        .tit {
            font-size: 16px;
            font-weight: bold;
        }
        .bullet-list {
            margin-top: 20px;
        }   
    }
}

.keyword-search .search-layer .keyword-box .keyword-list,
.keyword-search .search-layer .autocomplete-box .keyword-list {
    max-height: 210px;
    overflow-x: hidden;
    overflow-y: auto;
}
.keyword-search .search-layer .keyword-list ul > li .btn-delete {
    display: inline-block;
    position: static;
    margin-left: 8px;
    margin-top: 4px;
    vertical-align: middle;
    width: 14px;
    height: 14px;
    background: url(/lg5-common/images/icons/icon-delete-20.svg) no-repeat 0 0;
    background-size: 14px;
    margin-top:0;
    
}

.keywordBoxGap {
    margin-bottom: 150px;
}

.keyword-search .search-layer .keyword-list ul > li a {
	display: inline-block;
	width: calc(100% - 22px);
	font-size: 16px;
	line-height: 26px;
	vertical-align: middle;
	text-overflow: ellipsis;
	white-space: nowrap;
	word-wrap: normal;
	overflow: hidden;
}

.ui-select-scrollarea {
    ul {
        li.dpn {
            display: none !important;
        }
    }
}

// 센터방문예약
.support.visit-center {
    .center-info-wrap.type-new {
        padding: 40px;
        font-size: 20px;
        .info-list {
            .key {
                margin-right: 4px;
                color: #000;
            }
            .value {
                font-weight: $font-weight-bold;
            }
        }
        .bullet-list.top-border {
            margin-top: 24px;
            padding-top: 20px;
        }
    }
    #stepEngineer {
        margin-top: 120px;
    }
    #changDT {
        margin-top:120px;
    }
    .tabs-contents {
        padding-top:0;
    }
    .adress-input-box {
        .btn-cell {
            display:flex;
        }
        .search-current {
            display:block !important;
            margin-right:8px;
        }
    }
    .center-result-wrap {
        .title {
            font-size:20px !important;
        }
        .center-result-list {
            margin-top:16px !important;
            border-top:1px solid #666;
            li {
                position:relative;
                border-bottom:1px solid #ddd;
                .center-info {
                    display:block;
                    padding:24px;
                    .center-info-name {
                        font-size:18px;
                        font-weight:700;
                        @include tablet {
                            font-size:16px;
                        }
                    }
                    .center-info-distance {
                        margin-left:16px;
                        font-size:16px;
                        @include tablet {
                            font-size:14px;
                        }
                    }
                    .center-info-address {
                        margin-top:12px;
                        line-height:1.4em;
                        font-size:16px;
                        color:#000;
                        word-break:keep-all;
                        @include tablet {
                            margin-right:280px;
                            font-size:14px;
                        }
                    }
                    .fastdt-txt {
                        font-size: 14px;
                        margin-top: 6px;
                        color: #767676;
                        em {
                            color:$color-primary;
                        }
                    }
                }
                .btn-box {
                    position:absolute;
                    right:24px;
                    top:50%;
                    transform:translateY(-50%);
                    a, button {
                        vertical-align:middle;
                        &.btn {
                            min-width:107px;
                            padding:6px 11px;
                            margin-left:20px;
                        }
                    }
                }
                // &:first-of-type {
                //     border-top:1px solid #666;
                // }
                &.on {
                    .btn-box {
                        .btn {
                            position:relative;
                            background:#000;
                            color:#fff;
                            &:after {
                                position:absolute;
                                right:-6px;
                                top:-6px;
                                content:'';
                                width:24px;
                                height:24px;
                                background:url('/lg5-common/images/CS/icon/icon_check.svg') no-repeat;
                            }
                            &:hover, &:focus {
                                background:#000 !important;
                                color:#fff !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .keyword-search.default .search-input .btn-st-search {
        position: absolute;
        top: 0;
        right: 0;
        width: 54px;
        height: 48px;
        background: center/20px 20px url(/lg5-common/images/icons/icon-search-20.svg) no-repeat;
    }
}

// 센터정보
.store-info-wrap {
    .cont-wrap {
        .headline {
            margin: 0 40px;
        }
        .detail-info-wrap {
            .center-info {
                display: table-cell;
                width: 100%;
                padding-left: 72px;
                vertical-align: middle;
                .info-list {
                    margin-bottom: 64px;
                }
            }
        }
    }
}

.store-info-wrap {
    .cont-wrap {
        .tit-wrap {
            .tit-info {
                h2.name {
                    font-size: 32px;
                    line-height: 28px;
                    font-weight: $font-weight-bold;
                    vertical-align: middle;
                    word-break: keep-all;
                }
            }
            .tit-info-r {
                .tooltip-wrap {
                    margin-left: 16px;
                }
            }
        }
        .detail-info-wrap {
            .center-info {
                display: block;
                padding: 45px 0 0 0;
                .info-list {
                    margin-bottom: 64px;
                    .info-tit-wrap {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-bottom: 16px;
                        border-bottom: 1px solid #000;
                        .h3-tit {
                            position: relative;
                            font-size: 20px;
                            line-height: 36px;
                            font-weight: $font-weight-bold;
                        }
                        .h3-tit + dl {
                            padding-top: 24px;
                        }
                    }
                    .bullet-list .b-txt {
                        word-break: break-all;
                        white-space: pre-line;
                    }
                    dl {
                        display: flex;
                        padding-top: 24px;
                        &:first-of-type {
                            padding-top: 32px;
                        }
                        dt {
                            width: 93px;
                            color: #000;
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 26px;
                        }
                        dd {
                            flex: 1;
                            padding-left: 24px;
                            font-size: 16px;
                            line-height: 26px;
                            .item {
                                &:after {
                                    content: "";
                                    display: block;
                                    clear: both;
                                }
                                li {
                                    display: flex;
                                    color: #111;
                                    font-size: 16px;
                                    line-height: 26px;
                                    &:first-child {
                                        margin: 0;
                                    }
                                    .wktime {
                                        margin-right: 22px;
                                        min-width: 150px;
                                        font-weight: 500;
                                    }
                                    .key {
                                        .sp {
                                            display: inline-block;
                                        }        
                                    }
                                    .bullet-text {
                                        &:before {
                                            margin-top: 11px;
                                        }
                                    }
                                    &+li {
                                        margin-top: 5px;
                                    }          
                                }
                                &.dir-v {
                                    li {
                                        float: none;
                                        margin-left: 0;
                                    }
                                }                 
                            }
                            .bullet-list {
                                margin-top: 8px;
                            }
                            .addr-info {
                                font-size: 16px;
                                line-height: 20px;
                                &:first-child {
                                    margin-top: 0;
                                }
                                .key {
                                    display: inline-block;
                                    vertical-align: middle;
                                }
                                .addr {
                                    display: inline-block;
                                    vertical-align: middle;
                                }
                                &+.addr-info {
                                    margin-top: 8px;
                                }
                            }
                            .btn-toggle-sms {
                                margin-top: 16px;
                            }
                            .noti {
                                .point {
                                    color: #ea1917;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                    .btn-area {
                        margin: 12px 0 0 93px;
                        padding-left: 24px;
                    }
                    .map {
                        width: 100%;
                        height: 420px;
                        margin-top: 16px;
                        overflow: hidden;
                    }
                    .tabs-wrap-new {
                        margin-top: 32px;
                        text-align: left;
                    }
                    &.center-data {
                        dl {
                            dt {
                                width: 182px;
                            }
                            dd {
                                ul {
                                    li {
                                        .btn-link {
                                            margin-left: 16px;
                                            font-weight: 500;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .notice-info {
                    position: relative;
                    padding: 24px 40px 24px 32px;
                    background: #f4f4f4;
                    border-radius: 8px;
                    .txt {
                        position: relative;
                        display: inline-block;
                        padding: 0 145px 0 80px;
                        font-size: 16px;
                        line-height: 26px;
                        vertical-align: middle;
                        font-weight: 700;
                        .point {
                            color: #da0f47;
                        }
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            width: 64px;
                            height: 64px;
                            -webkit-transform: translateY(-50%);
                            transform: translateY(-50%);
                            background: url("/lg5-common/images/icons/img-evaluation-64.svg") no-repeat;
                            background-size: 64px 64px;
                        }
                    }
                    .btn {
                        position: absolute;
                        top: 50%;
                        right: 40px;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                    }
                    
                }
            }
        }
    }
}


@media screen and (max-width: 767px) {
	.store-info-wrap .cont-wrap .detail-info-wrap .center-info 
	.store-info-wrap .cont-wrap .detail-info-wrap .center-info .info-list.photo .slide-controls {
		display: none;
	}
    .store-info-wrap {
        .cont-wrap {
            .detail-info-wrap {
                .center-info {
                    .info-list {
                        .h3-tit {
                            font-size: 16px;
                            line-height: 24px;
                        }
                        .h3-tit + dl {
                            padding-top: 12px;
                        }
                        dl {
                            padding-top: 14px;
                            dt {
                                font-size: 14px;
                                line-height: 22px;
                                width: 96px;
                            }
                            dd {
                                font-size: 14px;
                                line-height: 22px;
                                padding-left: 12px;
                                .bullet-list {
                                    margin-top: 4px;
                                }
                                .item {
                                    li {
                                        font-size: 14px;
                                        line-height: 22px;
                                        float: none;
                                        margin: 6px 0 0 0;
                                        .key .sp {
                                            padding-left: 14px;
                                        }
                                    }
                                }
                                .addr-info {
                                    display: table;
                                    font-size: 14px;
                                    line-height: 22px;
                                    .key {
                                        display: inline-block;
                                        padding-right: 4px;
                                    }
                                    .addr {
                                        display: inline;
                                    }
                                }
                            }
                        }
                        .map {
                            height: 190px;
                        }
                    }
                    .notice-info {
                        padding: 16px 24px;
                        border: 0;
                        text-align: center;
                        .btn {
                            display: inline-block;
                            position: static;
                            width: auto;
                            margin-top: 24px;
                            -webkit-transform: translateY(0%);
                            transform: translateY(0%);
                        }
                        .txt {
                            display: block;
                            padding: 72px 0 0 0;
                            text-align: left;
                            font-size: 14px;
		                    line-height: 22px;
                            &:before {
                                top: 0;
                                left: 50%;
                                -webkit-transform: translate(-50%, 0%);
                                transform: translate(-50%, 0%);
                            }
                        }
                    }
                }
            }
        }
    }
}


// @media screen and (max-width: 767px) {
// 	.store-info-wrap .cont-wrap .detail-info-wrap .center-info .info-list.photo {
// 		margin-right: -16px;
// 	}
// }

// @media screen and (max-width: 767px) {
// 	.store-info-wrap .cont-wrap .detail-info-wrap .center-info .info-list.photo .slide-wrap .slide-content .slide-conts .cont-wrap {
// 		width: 92.224vw;
// 	}
// }

// .store-info-wrap .cont-wrap .detail-info-wrap .center-info .info-list.event .slide-wrap .slide-content .slide-conts .cont-wrap {
// 	width: 100%;
// }

// .store-info-wrap .cont-wrap .detail-info-wrap .center-info .info-list.event .slide-wrap .slide-content .slide-conts .cont-wrap .img {
// 	height: 246px;
// 	margin: 0 6px;
// }

// .store-info-wrap .cont-wrap .detail-info-wrap .center-info .info-list.event .slide-wrap .slide-content .slide-conts .cont-wrap .img img {
// 	width: 100%;
// }


.indi-wrap li{
    .btn-indi {
        width: 24px !important;
        &:before {
            width: 8px !important;
            height: 8px;
            background: #000  !important;
            opacity: 0.2;
        }
    }
    &.on .btn-indi:before {
        width: 24px !important;
        opacity: 1;
    }
}


@media screen and (max-width: 767px) {
	.btn-arrow {
		width: 36px;
		height: 36px;
	}
}

.btn-arrow {
    position: relative;
	display: block;
	width: 48px;
	height: 48px;
	padding: 4px;
	border-radius: 50%;
	font-size: 0;

    &::before {
        content: ' ';
        display: block;
        width: 40px;
        height: 40px;
        background-color: #fff;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: 100%;
        box-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.14);
    }
    &::after {
        position: absolute;
        top: 20px;
        left: 22px;
        transform: rotate(0deg);
        content: '';
        display: inline-block;
        border: solid black;
        border-width: 0 1px 1px 0;
        padding: 4px;
    }
}

@media screen and (max-width: 767px) {
	.btn-arrow::before {
		width: 28px;
		height: 28px;
	}
}

.btn-arrow.prev {
    &::before {
        background-image: none;
    }
    &::after {

        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }
}

.btn-arrow.next {
    &::before {
        background-image: none;
    }
    &::after {
        left: auto;
        right: 22px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
}


.store-info-wrap {
    .center-info {
        .toggle-layer {
            margin: 24px 0 0 0;
            border-radius: 8px;
            .layer-inner {
                padding: 24px;
                .form-infos {
                    margin-bottom: 16px;
                }
                .form-wrap {
                    margin-top: 32px;
                }
                .btn-wrap {
                    margin-top: 56px;
                    .btn {
                        min-width: auto;
                    }
                }
                .agree-wrap {
                    display: none;
                    &.active {
                        display: block;
                    }
                }
            }
        }
        .info-list {
            dl.forms.align-type-col {
                display: flex;
                flex-direction: column;
                padding-top: 0 !important;
                dt.tit {
                    width: auto;
                    padding: 0;
                    margin-bottom: 10px;
                }
                dd.conts {
                    padding-left: 0;
                }
            }
        }
    }
}

//센터찾기
.find-store-wrap.type-new {
    border-top: 1px solid #ddd;
    .map-container {
        .store-list-wrap {
            .tit-wrap {
                position: relative;
                margin-bottom: 0;
                h2.tit {
                    padding: 0 32px;
                    font-size: 28px;
                    line-height: 38px;
                    margin: 32px 0 16px;
                    min-height: 64px;
                    display: flex;
                    align-items: center;
                    position: relative;
                    border-bottom: none;
                    .tooltip-wrap {
                        padding-top: 0;
                        height: 32px;
                        position: initial;
                        .tooltip-icon {
                            width: 32px;
                            height: 32px;
                        }
                        .tooltip-box {
                            display: none;
                            position: absolute;
                            left: 32px;
                            top: 60px;
                            width: calc(100% - 64px);
                            min-width: 456px;
                            p {
                                font-size: 16px;
                                font-weight: normal;
                            }
                        }
                    }
                }
            }
            
            .sch-box {
                .tabs-wrap-new {
                    position: absolute;
                    top: -80px;
                    right: 0;
                    margin: 0;
                    &:before {
                        display: none;
                    }
                    li {
                        padding: 0 16px;
                    }
                    .tabs {
                        padding-right: 0;
                    }
                }
                .tabs-contents {
                    padding-top: 0;
                }
                .btn-wrap {
                    display: flex;
                    gap: 8px;
                    margin-bottom: 32px;
                    .btn {
                        width: 50%;
                    }
                }
                .form-wrap {
                    padding-bottom: 0;
                    margin-bottom: 8px;
                    .keyword-search {
                        margin-top: 0;
                    }
                }
            }
            .btn-list-fold {
                top: -10px;
            }
            .sch-list {
                padding-top: 0;
                height: calc(100% - 50px);
                .list-item > li {
                    .store-info-list {
                        .info-wrap {
                            .tit-wrap {
                                .name {
                                    line-height: 24px;
                                }
                                .distance {
                                    font-size: 16px;
                                    line-height: 22px;
                                }
                            }
                        }
                    }
                    .btn-box {
                        margin:24px 0 0 50px;
                        .btn-link {
                            
                        }
                        .btnVisit {
                            position: absolute;
                            bottom: 24px;
                            right: 0;
                        }
                    }
                }
            }
            .list-wrap {
                .head-wrap {
                    width: calc(100% - 32px);
                    border-bottom: 1px solid #666;
                    padding-bottom: 16px;
                    margin-bottom: 24px;
                    .tit {
                        font-weight: 400;
                    }
                    .near {
                        font-size:16px;
                        strong {
                            font-weight:700;
                            color:#ea1917;
                        }
                    }
                }
            }
            .no-data.type-center {
                width: calc( 100% - 64px);
                p.tit {
                    color:#000;
                    font-size: 16px;
                    line-height: 1.5;
                }
                p.ref {
                    color:#000;
                    margin-top: 8px;
                    span.red {
                        color: #ea1917;
                    }
                }
            }
        }
        .map-area {
            .info-overlaybox {
                .tit-wrap {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 0;
                    .distance {
                        font-size: 14px;
                    }
                    .status-icon {
                        .status {
                            font-size: 12px;
                        }
                    }
                }
                .inner {
                    .btn-group {
                        .btn {
                            background-color: initial;
                        }
                    }
                }
            }
        }
        .status-icon {
            &:before {
                display: none;
            }
            &.red {
                .status {
                    color: #ea1917;
                }
            }
            &.nodata {
                .status {
                    color:#767676;
                }
            }
            &.close {
                .status {
                    color:#767676;
                }
            }
            .status {
                font-weight: 400;
            }
        }
    }
}


@include tablet {
    .find-store-wrap.type-new {
        .map-container {
            .store-list-wrap {
                .tit-wrap {
                    h2.tit {
                        justify-content: center;
                        font-size: 24px;
                        line-height: 32px;
                    }
                    .view-info-btn {
                        display: block;
                        left: initial;
                        right: 32px;
                        bottom: 20px;
                        top: initial;
                    }
                }
                .sch-box {
                    .tabs-wrap-new {
                        right: auto;
                        left: 0;
		                padding-bottom: 0;
                        .tabs {
                            padding-right: 0;
                            li {
                                height: 64px;
                            }
                        }
                    }
                }
                .sch-list .list-item>li>.btn-link {
                    left: 44px;
                    font-size: 14px;
                    &:after {
                        top: 7px;
                    }
                }
            }
        }
    }
}


.service-history-wrap {
    padding: 40px;
    border-radius: 16px;
    border: 1px solid #ddd;
    margin-bottom: 80px;
    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 24px;
        border-bottom: 1px solid #ddd;
        .tit {
            .count {
                color: #ea1917;
            }
        }
    }
    .cont {
        padding-top: 32px;
        .item {
            .inner {
                display: flex;
                flex-wrap: wrap;
                gap: 16px 6%;
                .cate-list {
                    display: flex;
                    width: calc(88% / 3);
                    justify-content: space-between;
                    align-items: center;
                    dt {
                        font-size: 20px;
                        font-weight: $font-weight-bold;
                    }
                    dd {
                        ul {
                            display: flex;
                            li {
                                font-size: 14px;
                                position: relative;
                                padding-left: 12px;
                                .point {
                                    color: #ea1917;
                                    font-weight: $font-weight-bold;
                                    background-color: transparent;
                                }
                                &::before {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    left: 0;
                                    top: 55%;
                                    width: 1px;
                                    height: 10px;
                                    background-color: #ddd;
                                    transform: translateY(-55%);
                                }
                                & + li {
                                    margin-left: 12px;
                                }
                                &:first-child::before {
                                    display: none;
                                }
                            }
                        }
                        .no-status {
                            display: inline-flex;
                            align-items: center;
                            min-height: 20px;
                            &:after {
                                content: "";
                                display: block;
                                width: 5px;
                                height: 1px;
                                background-color: #222;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1450px) {
    .service-history-wrap {
        .cont {
            .item {
                gap: 16px 10%;
                .cate-list {
                    width: calc(90% / 2);
                }
            }
        }
    }
}

@include tablet {
    .service-history-wrap {
        .cont {
            .item {
                gap: 16px 0;
                .cate-list {
                    width: 100%;
                }
            }
        }
    }
}


.list-wrap.icon-type {
    &.icon-type .list > li {
        a {
            .flag-wrap {
                margin-bottom: 6px;
            }
            .options {
                display: flex;
                color: #666;
                margin-top: 10px;
            }
        }
        a:before {
            width: 64px;
            height: 64px;
            background-size: 64px 64px;
            background-position: center center;
            border-radius: none;
            background-color: none;
            left: 24px;
        }
        
        &.menu-request a:before {
            background-image: url("/lg5-common/images/CS/icon/icon_call_us_80.svg");
        }
        &.menu-remote a:before {
            background-image: url("/lg5-common/images/CS/icon/icon_remote_80.svg");
        }
        &.menu-video a:before {
            background-image: url("/lg5-common/images/CS/icon/icon_video_80.svg");
        }
        &.menu-engineer a:before {
            background-image: url("/lg5-common/images/CS/icon/icon_service_reserv_80.svg");
        }
        &.menu-center a:before {
            background-image: url("/lg5-common/images/CS/icon/icon_find_center_80.svg");
        }
        &.menu-signLanguage a:before {
            background-image: url("/lg5-common/images/CS/icon/icon_sign_language_80.svg");
        }
        // &.menu-resolution a:before {
        //     background-image: url("/kr/support/images/icon/icon_product-resolution-64.svg");
        // }
        // &.menu-email a:before {
        //     background-image: url("/lg5-common/images/CS/icon/icon_email_us_80.svg");
        // }
        // &.menu-installation a:before {
        //     background-image: url("/kr/support/images/icon/icon_installation-reservation.svg");
        // }
    }
}


@media screen and (max-width: 767px) {
	.list-wrap.icon-type .list > li a {
		padding-left: 80px;
	}
	.list-wrap.icon-type .list > li a:before {
		left: 0;
		top: 16px;
		width: 60px;
		height: 60px;
		transform: translateY(0);
	}
	.list-wrap.icon-type .list > li a .options li {
		float: none;
		margin: 0;
		padding: 0;
	}
	.list-wrap.icon-type .list > li a .options li:before {
		display: none;
	}
	.list-wrap.icon-type .list > li.menu-resolution a:before {
		width: 40px;
		height: 40px;
		left: 12px;
		top: 50%;
		transform: translateY(-50%);
	}
}


.check-info-wrap.type-bullet {
    margin-top: 64px;
    .bullet-list {
        margin-top: 0;
    }
}

// 예약 조회/변경
.contents.service-status-detail {
    // 센터방문 예약 취소 완료
    .section.only-header {
        .page-header {
            padding-bottom: 0;
            display: table;
            width: 100%;
            .progress-wrap.type2 {
                margin-bottom: 56px;
            }
        }
        &+ .section {
            margin-top: 64px ;
        }
    }
    .page-header {
        //BTOCSITE-79119 ERMS -> Smile+ 전환 개발
        .progress-wrap.type2 {
            overflow:hidden;
            padding-bottom:30px;
            margin-bottom:0;
            ol {
                overflow: initial;
                li {
                    &.on {
                        &.curr {
                            .txt {
                                display:block;
                            }
                        }
                    }
                    .txt {
                        display:none;
                        position: absolute;
                        left: 50%;
                        bottom: -30px;
                        transform: translateX(-50%);
                        color: #000;
                        @include font-medium;
                        font-weight: 500;
                        text-align: center;
                        white-space: nowrap;
                    }
                    &:first-of-type {
                        .txt {
                            left: 0;
                            transform: translateX(0);
                        }
                    }
                    &:last-of-type {
                        .txt {
                            left: 100%;
                            transform: translateX(-100%);
                        }
                    }
                }
            }
        }
    }
}

.contents.service-status-detail.email .page-header.reservationStep{
    padding-bottom:0;
}


.popup-wrap .pop-conts.cancel-pop {
    padding-top: 14px;
    .guide-box {
        padding: 40px;
        margin-bottom: 24px;
        .desc {
            font-size: 20px;
        }
    }

    .form-wrap .forms .tit,
    .form-wrap .forms .conts {
        width: 100%;
    }

    .form-infos {
        margin-bottom: 0;
    }

    .form-infos + .forms {
        margin-top: 6px;
    }
}

.centermap-wrap {
    border: 1px solid #ddd;
    border-radius: 8px;
    height: 740px;
    overflow: hidden;
}

.contents.visit-center .step-area.active {
    display: block;
}

.contents.visit-center #stepCenter,
.contents.visit-center #stepMap {
    margin-top: 120px;
}

// PSP
.support.psp {
    .product-header {
        margin-top: 40px;
        .product-header-inner {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .visual {
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                flex-shrink: 0;
                .img-wrap {
                    width: 240px;
                }
            }
            .conts {
                // padding-left: 80px;
                // padding-right: 130px;
                width: 100%;
                .flag-wrap {
                    margin-bottom: 6px;
                    .flag {
                        height: 17px;
                        line-height: 17px;
                    }
                }
                .tit {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    p {
                        font-size: 24px;
                        line-height: 1.42;

                    }
                }
                .desc {
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    line-height: 24px;
                    margin-top: 6px;
                    .model {
                        display: flex;
                        align-items: center;
                        line-height: 24px;
                        margin: 0;
                        &:before {
                            content: "";
                            display: block;
                            width: 1px;
                            height: 10px;
                            background-color: #ddd;
                            margin: 0 8px;
                        }
                    }
                }
                .link-wrap {
                    .link-list {
                        .btn-link {
                            font-size: 14px;
                            text-decoration: none;
                            font-weight: 500;
                            &:after {
                                background-size: auto 14px;
                            }
                        }
                    }
                }
            }
            .btn-wrap {
                // display: flex;
                // width: auto;
                // position: absolute;
                // right: 40px;

                display: inline-block;
                width: auto;
                .btn {
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
        
    }

    .anchors-tabs-wrap {
        margin-top: 40px;
        .tabs-wrap.border-type .tabs {
            .swiper-slide {
                a {
                    padding: 17px 24px 19px;
                }
                &.on {
                    > a {
                        &:after {
                            width: calc(100% - 48px);
                            left: 24px;
                            height: 2px;
                            border-radius: none;
                        }
                    }
                }
            }
        }
    }
    
    .banner-wrap.toggle-banner {
        margin: 0 0 40px;
        .cont {
            padding-right: 22px;
        }
        .banner {
            justify-content: left;
            background:url('/lg5-common/images/CS/bg_downloadcenter_banner_pc.jpg') #D3E5FD no-repeat right 50% / auto 100%;
        }
    }
    // 상단 로그인 툴팁 박스
    .tooltip {
        &-login {
            display: block;
            max-width:100%;
            margin:0 auto;
            background-color: #DA0F47; //BTOCSITE-50181 상단 띠배너 추가] 이브닝 서비스 홍보 활동 지속 // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
            // BTOCSITE-24154 [로그인유도] 고객지원 홈 띠배너 (Hello Bar) 노출을 모바일 웹/앱으로 확대
            // @include mobile {
            //     display:none; //0625
            // }
        }
        &-inner {
            position: relative;
            max-width:1380px;
            margin:0 auto;
            padding:17px 0;
            text-align: center;
            p {
                display: inline-block;
                color:#fff;
                font-size:16px;
                line-height:26px;
                vertical-align: middle;
                strong {
                    font-weight:bold;
                }
            }

            .btn-link {
                margin-left:16px;
                color:#fff;
                font-weight:bold;
                text-decoration: none;

                &:after {
                    width:16px;
                    height:16px;
                    background-image: url("/lg5-common/images/icons/btn-arr-16-white.svg");
                    background-size:100% 100%;
                }
                
            }
            .btn-tooltip-close {
                position: absolute;
                top:50%;
                right:0;
                transform:translateY(-50%);
                width:48px;
                height:48px;
                background: url("/lg5-common/images/icons/btn-close-24-white.svg") no-repeat center center/24px 24px;
            }
            @include mobile {
                text-align: left;
                padding:10px 16px; // BTOCSITE-24154 [로그인유도] 고객지원 홈 띠배너 (Hello Bar) 노출을 모바일 웹/앱으로 확대
                p {
                    display: block;
                    font-size:13px;
                }
                .btn-link {
                    margin-left:0;
                }
            }
        }
    }
}

@include tablet {
    .support.psp {
        .product-header {
            .product-header-inner {
                .visual {
                    height: 200px;
                    .img-wrap {
                        width: 200px;
                        height: 200px;
                    }
                }
                .conts {
                    // padding-left: 30px;
                    // padding-right: 0;
                    .desc {
                        flex-wrap: wrap;
                        .model {
                            &:before {
                                display: none;
                            }
                        }
                    }
                }
                // .btn-wrap {
                    // position: absolute;
                    // right: 40px;
                    // bottom: -25px;
                // }
            }
        }
    }
}


// .support {
//     .solution-result-list {
//         ul {
//             li {
//                 a {
//                     .title {
//                         font-size: 16px;
//                         border-bottom: none;
//                         margin-bottom: 0;
//                         padding-bottom: 0;
//                     }
//                 }
//                 @include tablet {
//                     a {
//                         flex-wrap: wrap;
//                         padding: 8px 0;
//                         gap: 6px;
//                         .info {
//                             font-size: 14px;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

.contents.psp {
    .section-topic {
        margin-top: 48px !important;
        .form-infos {
            margin-bottom: 16px;
        }
    }
    .common-banner {
        .product-slider .item {
            .item-inner {
                min-height: 390px;
            }
        }
    }

    @include tablet {
        .common-banner .product-slider .item {
            .item-inner {
                min-height: auto;
            }
        }
    }
}

//예약내역 아이콘리스트
.list-wrap.icon-type .list>li.menu-center a:before {
	background-image: url("/lg5-common/images/CS/icon/icon_call_us_80.svg");
}
.list-wrap.icon-type .list>li.menu-remote a:before {
	background-image: url("/lg5-common/images/CS/icon/icon_remote_80.svg");
}
.list-wrap.icon-type .list>li.menu-video a:before {
	background-image: url("/lg5-common/images/CS/icon/icon_video_80.svg");
}
.list-wrap.icon-type .list>li.menu-engineer a:before {
	background-image: url("/lg5-common/images/CS/icon/icon_service_reserv_80.svg");
}
.list-wrap.icon-type .list>li.menu-center a:before {
	background-image: url("/lg5-common/images/CS/icon/icon_find_center_80.svg");
}
// 수어
// .list-wrap.icon-type .list>li.menu-center a:before {
// 	background-image: url("/lg5-common/images/CS/icon/icon_sign_language_80.svg");
// }
.list-wrap.icon-type .list>li.menu-email a:before {
	background-image: url("/lg5-common/images/CS/icon/icon_email_us_80.svg");
}
.list-wrap.icon-type .list>li.menu-request a:before {
	background-image: url("/lg5-common/images/CS/icon/icon_contact_us_80.svg");
}
.list-wrap.icon-type .list>li.menu-installation a:before {
	background-image: url("/lg5-common/images/CS/icon/icon_move_80.svg");
}
.list-wrap.icon-type .list>li.menu-installation a:before {
    //background-image:url('/lg5-common/images/icons/icon-product-resolution-64.svg');
    background-image:url('/kr/support/images/icon/icon_product-resolution-64.svg');
}

// 제품문제해결
.sorting-wrap {
    .switch-wrap {
        float: right;
        margin-right: 16px;
    }
}

// BTOCSITE-65147 고객지원 > 출장예약 > 세척서비스 추가
#stepInput {
    .clean-banner-wrap {
        display: flex;
        margin-bottom: 50px;
        .img-wrap {
            width: 50%;
            max-width: 660px;
            overflow: hidden;
            .img {
                border-radius: 16px;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .item-wrap {
            padding: 20px 0px 20px 55px;
            .text-wrap {
                font-size: 32px;
                font-weight: 700;
                line-height: 40px;
                color: #0f0f0f;
                span{
                font-weight: 400;
                }
            }
            .banner-button {
                width: fit-content;
                margin-top: 20px;
                padding: 15px 24px;
                background-color: #EA1917;
                color: #ffffff;
                border-radius: 100px;
                font-size: 14px;
                font-weight: 700;
            }
        }
    }
}

// BTOCSITE-67844 고객지원 > 메인 > 베스트케어 구좌 추가 요청
.bestcare-section {
    .bestcare-header {
        max-width: 1380px;
        margin: 0 auto;
        .bestcare-title {
            font-size: 28px;
            line-height: 36px;
            color: #000;
            font-weight: 700;
        }
        .bestcare-description {
            margin-top: 8px;
            font-size: 20px;
            line-height: 24px;
            color: #000;
        }
    }
    .bestcare-content {
        max-width: 1380px;
        margin: 0 auto;
        padding-top: 32px;
        .bestcare{
            &-list {
                display: flex;
            }
            &-banner {
                margin-right: 24px;
                border-radius: 16px;
                width: 100%;
                background: #f6f6f6;
                & + .bestcare-banner:last-child {
                    margin-right: 0;
                }
            }
            &-link {
                height: 200px;
                padding: 50px 168px 50px 32px;
                display: block;
                position: relative;
            }
            &-text-wrap {
                .bestcare-desc {
                    font-size: 16px;
                    line-height: 24px;
                    color: #0f0f0f;
                }
                .bestcare-title {
                    font-size: 24px;
                    line-height: 30px;
                    font-weight: 700;
                }
                .bestcare-desc + .bestcare-title {
                    margin-top:4px;
                }
            }
            // [BTOCSITE-76716] [UI]LGE.COM 홈 > 베스트케어 이벤트 구좌 추가 요청
            &-text-link {
                display: inline-flex;
                align-items: center;
                margin-top: 8px;
                p {
                    font-size: 16px;
                    line-height: 24px;
                    color: #0f0f0f;
                }
                &:after {
                    content: "";
                    display: inline-block;
                    margin-left: 2px;
                    margin-top: 2px;
                    width: 16px;
                    height: 16px;
                    background: url('/lg5-common/images/MA/mainRenewal/icon-arrow-right-16.svg') center no-repeat;
                    background-size: 16px 16px;
                }
            }
            &-image {
                bottom:0;
                right:0;
                position: absolute;
                img {
                    width:100%;
                    height:100%;
                }
                &.bestcare-image--clean {
                    bottom:40px;
                    right:30px;
                    width:120px;
                    height:120px;
                }
                &.bestcare-image--install {
                    bottom: 52px;
                    right: 35px;
                    width: 120px;
                    height: 83px;
                }
                &.bestcare-image--campaign {
                    width: 190px;
                    height: 200px;
                }
            }
        }
    }
}
@media screen and (max-width: 1024px) {
    .bestcare-section {
        max-width: 1024px;
        margin: 0 auto;
        padding: 0 16px 16px;
        .bestcare-header {
            margin-bottom: 20px;
            padding-right: 55px;
            .bestcare-title {
                font-size: 20px;
                line-height: 26px;
                font-weight: 700;
                color: #000;
            }
            .bestcare-description {
                margin-top: 4px;
                font-size: 15px;
                line-height: 21px;
                color: #000;
            }
        }
        .bestcare-content {
            .bestcare{
                &-list {
                    display: flex;
                    flex-wrap: wrap;
                }
                &-banner {
                    width: 100%;
                    margin-bottom: 12px;
                    margin-right: 0;
                    border-radius: 8px;
                    background: #f6f6f6;
                }
                &-link {
                    display: block;
                    position: relative;
                    height: 110px;
                    padding: 20px 96px 20px 16px;
                }
                &-text-wrap {
                    .bestcare-desc {
                        color: #666;
                        font-size: 14px;
                        line-height: 20px;
                    }
                    .bestcare-title {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 22px;
                    }
                    .bestcare-desc + .bestcare-title {
                        margin-top: 2px;
                    }
                }
                // [BTOCSITE-76716] [UI]LGE.COM 홈 > 베스트케어 이벤트 구좌 추가 요청
                &-text-link {
                    display: inline-flex;
                    align-items: center;
                    margin-top: 8px;
                    p {
                        font-size: 11px;
                        line-height: 15px;
                        color: #666;
                    }
                    &:after {
                        content: "";
                        display: inline-block;
                        margin-left: 2px;
                        margin-top: 2px;
                        width: 12px;
                        height: 12px;
                        background: url('/lg5-common/images/MA/mainRenewal/icon-arrow-right-16.svg') center no-repeat;
                        background-size: 12px 12px;
                        opacity: 0.7;
                    }
                }
                &-image {
                    position: absolute;
                    img {
                        width:100%;
                        height:100%;
                    }
                    &.bestcare-image--clean {
                        bottom: 15px;
                        right: 16px;
                        width: 80px;
                        height: 80px;
                    }
                    &.bestcare-image--install {
                        bottom: 24px;
                        right: 16px;
                        width: 80px;
                        height: 54px;
                    }
                    &.bestcare-image--campaign {
                        bottom: 0;
                        right: 0;
                        width:110px;
                        height:110px;
                    }
                }
            }
        }
    }
}
@charset "UTF-8";

/* result-list */
.inquiry {
    &-list {
        > li {
            @include clearfix;
            margin-top: 20px;
            display:flex;
            &:first-child {
                margin-top: 0;
            }
        }
        .title {
            font-size:16px;
            color: #666;
            font-weight:normal;
            line-height: 26px; // BTOCSITE-18612 출장서비스 예약 조회/변경 화면구성 변경
            width:210px;
            strong {
                font-weight: normal;
            }
        }
        .description {
            width:calc(100% - 210px);
            color: #000;
            @include font-medium;
            font-weight:500;
            .point {
                color:$color-primary;
            }
            .line {
                display:block;
                white-space:pre-line;
                word-break: break-all;
                p {
                    font-family:'Noto Sans', sans-serif !important;
                    @include pc {
                        font-size:16px !important;
                    }
                    @include mobile {
                        font-size:14px !important;
                    }
                }
            }
            .btn {
                margin-top:8px;
            }
            .held-product {
                margin-left: 24px;
            }
            .engineer-box {
                font-size:0;
                .image {
                    display:inline-block;
                    width:120px;
                    height:auto; // BTOCSITE-18612 출장서비스 예약 조회/변경 화면구성 변경
                    vertical-align:middle;
                    img {
                        width:100%;
                        height:100%;
                    }
                }
                .txt {
                    display:inline-block;
                    padding-left:16px;
                    @include font-medium;
                    vertical-align:middle;
                }
            }
            @include mobile {
                .held-product {
                    margin-left: 0;
                    margin-top: 8px;
                    display: block;
                }
                // BTOCSITE-18612 출장서비스 예약 조회/변경 화면구성 변경
                .engineer-box {
                    .txt {
                        display: block;
                        padding-left:0;
                    }
                }
            }
        }
        &.type-bullet {
            display: flex;
            flex-wrap: wrap;
            li {
                display: block;
                position: relative;
                &::before {
                    display: block;
                    content: '';
                    width: 3px;
                    height: 3px;
                    background-color: #666;
                    position: absolute;
                    top: 10px;
                    left: 0;
                }
            }
            .title,
            .description {
                margin-left: 12px;
                width:100%;
            }
            @include pc {
                li {
                    width: 33.3333%;
                    &:nth-child(n+1):nth-child(-n+3) {
                        margin-top: 0;
                    }
                }
            }
            @include mobile {
                display: block;
                li {
                    margin-top: 20px;
                }
                .title {
                    width: 100%;
                }
            }
        }
        &.type-A {
            display: flex;
            > li {
                &:nth-child(n+2) {
                    margin-top: 0;
                    margin-left: 23px;
                    padding-left: 24px;
                    position: relative;
                    ::before {
                        content: '';
                        display: block;
                        width: 1px;
                        height: 16px;
                        background-color: #ddd;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
            .title {
                width: auto;
            }
            .description {
                margin-left: 0;
            }
            @include pc {
                > li {
                    &:nth-child(n+2) {
                        margin-left: 23px;
                        padding-left: 24px;
                        position: relative;
                        ::before {
                            content: '';
                            display: block;
                            width: 1px;
                            height: 16px;
                            background-color: #ddd;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
            @include mobile {
                display: block;
            }

        }
    }
    &-dl {
        border-top: 1px solid #ddd;
        padding-top: 24px;
        margin-top: 24px;
        &:first-child {
            border-top: 0;
            padding-top: 0;
            margin-top: 0;
            }
        dt {
            font-size: 16px;
            color: #000;
            font-weight:500;
            margin-bottom: 20px;
        }
    }
    @include mobile {
        &-list {
            .title {
                width:100px; // BTOCSITE-22532 엔지니어 → 서비스매니저 명칭 변경
                line-height: 22px; // BTOCSITE-18612 출장서비스 예약 조회/변경 화면구성 변경
            }
            .description {
                width:calc(100% - 96px);
                padding-left:0;
                .btn {
                    margin-top:12px;
                }
            }
            & > li {
                margin-top:20px;
            }
        }
        &-dl {
            dt {
                font-size:14px;
                margin-bottom: 20px;
            }
        }
    }
}
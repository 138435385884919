@charset "UTF-8";

/* notice view */
.board-view-wrap {
    padding-top:64px;
    @include mobile {
        padding-top:32px;
    }
    .board-view {
        position:relative;
        &-head {
            padding-bottom:16px;
            border-bottom:1px solid #666;
            .flag-wrap {
                margin-bottom:2px;
            }
            .head {
                &-title {
                    font-weight:$font-weight-bold;
                    font-size:32px;
                    line-height:1.63;

                    @include mobile {
                        font-size:24px;
                    }
                }
                &-infos {
                    margin-top:8px;
                    display: inline-block;
                    @include clearfix;
                    > li {
                        float:left;
                        color:#000;
                        @include font-small;
                        font-weight:normal;
                        position:relative;
                        margin-left:8px;
                        padding-left:9px;
                        &:before {
                            content:'';
                            position:absolute;
                            left:0;
                            top:6px;
                            width:1px;
                            height:14px;
                            background:#ddd;
                        }
                        .point {
                            color:#ec455a;
                        }
                    }
                    &:first-of-type {
                        > li {
                            &:first-child {
                                padding-left: 0;
                                margin-left: 0;
                                &::before {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            @include mobile {
                .flag-wrap {
                    margin-bottom:4px;
                }
                .head {
                    &-infos {
                        margin-top:10px;
                        display: block;
                        > li {
                            &.category, &.topic {
                                width:100%;
                                margin-left:0;
                                padding-left:0;
                                &:before {
                                    display: none;
                                }
                            }
                            &.topic + li {
                                margin-left:0;
                                padding-left:0;
                                &:before {
                                    display: none;
                                }
                            }
                            & + li {
                                &:before {
                                    height:10px;
                                }
                            }
                        }
                        &.category-type {
                            > li {
                                float: none;
                                & + li {
                                    margin-left: 0;
                                    padding-left: 0;
                                    &:before {
                                        display: none;
                                    }
                                }
                            }
                        }
                        &:nth-of-type(n+2) {
                            margin-top: 0;
                            > li {
                                &:first-child {
                                    padding-left: 0;
                                    margin-left: 0;
                                    &::before {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &-sub {
            display:flex;
            align-items:center;
            justify-content:space-between;
            padding:12px 0;
            border-bottom: 1px solid #ddd;
            .tooltip-wrap:only-child {
                margin-left:auto;
            }
        }
        &-body {
            padding:60px 0 120px;
            .view-content {
                img {
                    max-width: 100%;
                }
                /* video detail */
                .video-content {
                    padding:48px 116px;
                    border-radius: 8px;
                    background-color: #f4f4f4;
                    margin-bottom: 64px;
                    @include mobile {
                        padding: 32px 16px;
                        margin: 0 -16px 40px;
                    }
                }
                .text-content {
                    margin: 0 auto;
                    @include mobile {
                        width: 100%;
                    }
                }   
            }
            #viewContent {
                padding:0 24px;
                // BTOCSITE-43599 GSCS 콘텐츠 CSS 홈페이지 적용
                // 공지사항, 업데이트소식, 서비스뉴스
                .noticeViewWrap,
                .mobile-notice,
                .card-news-tit {
                    p {
                        color:#000;
                        @include font-medium;
                    }
                    strong {
                        color:inherit;
                        font-size:inherit;
                        line-height:inherit;
                    }
                }
                .point {
                    color:$color-primary;
                }
                img {
                    max-width:100%;
                }
                .hiddenN,
                .hidden {
                    @include blind;
                }
                > div {
                    width: auto !important;
                    img {
                        width: auto !important;
                        max-width: 100%;
                    }
                }
            }
            @include mobile {
                padding:40px 0 60px;
                .survey-banner-wrap {
                    padding: 0;
                }
                #viewContent {
                    padding:0;
                }
            }
        }
        + .common-banner {
            margin-top: 40px;
        }
        @include mobile {
            padding:0 16px;
        }
    }
    .board-navi {
        .navi {
            display:flex;
            padding:24px;
            font-size:0;
            border-radius:4px;
            background-color: #fff;
            border-top: 1px solid #ddd;
            @include clearfix;
            &-label, &-desc, &-date, &-views {
                display:inline-block;
                @include font-medium;
                vertical-align:middle;
            }
            &-label {
                position: relative;
                margin-right:64px;
                color: #666;
            }
            &-desc {
                color:#000;
                font-weight:normal;
                flex: 1;
                @include textEllipsisClamp(2);
            }
            &-date,
            &-views {
                color: #767676;
                margin-left: 64px;
            }
            &:last-child {
                border-bottom: 1px solid #ddd;
            }
        }
        @include mobile {
            padding: 0 16px;
            .navi {
                padding:16px 12px;
                flex-wrap: wrap;
                &-label {
                    margin-right:24px;
                }
                &-desc {
                    flex: auto;
                    width: calc(100% - 70px);
                }
                &-date {
                    margin:4px 0 0 64px;
                }
                &-views {
                    position: relative;
                    margin:4px 0 0 8px;
                    padding-left:9px;
                    &::before {
                        content: '';
                        display: block;
                        width: 1px;
                        height: 10px;
                        position: absolute;
                        background-color: #ddd;
                        top: 6px;
                        left: 0;
                    }
                }
            }
        }
    }
    .survey-content {
        position:relative;
        max-width:1146px;
        margin:64px auto 0;
        .title-wrap {
            .tit {
                margin-bottom:8px;
                font-size:20px;
                font-weight:$font-weight-bold;
                line-height:28px;
            }
            .desc {
                color:#767676;
                font-size:16px;
                font-weight:$font-weight-medium;
                line-height:26px;
            }
        }
        .star-rating-wrap {
            margin-top:24px;

            .current_rating_score {
                display: inline-block;
                font-size:16px;
                line-height:24px;
                vertical-align: middle;
                margin-left:4px;

                @include mobile {
                    font-size:14px;
                    line-height:22px;
                }
            }
        }
        .input-wrap {
            margin-top:8px;
        }
        .btn-wrap {
            margin-top:24px;
            text-align:center;
        }
        @include mobile {
            margin-top:40px;
            .title-wrap {
                .tit {
                    font-size:14px;
                    line-height:22px;
                }
                .desc {
                    font-size:13px;
                    line-height:21px;
                }
            }
            .btn-wrap {
                margin-top:32px;
                padding:0 20px;
                .btn {
                    width:100%;
                }
            }
        }
    }
    + .btn-group {
        margin-top: 64px;
        @include mobile {
            margin-top: 40px;
        }
    }
}

.page-header + .contents .board-view-wrap {
    padding-top:0;
}
@charset "UTF-8";

.process-wrap {
    max-width:1066px;
    margin:0 auto;
    background:#fff;
    .process-list {
        > li {
            background-repeat:no-repeat;
            & + li {
                position:relative;
                &:before {
                    content: '';
                    position:absolute;
                    background:url(/lg5-common/images/icons/icon-btn-arrow-right2.svg) no-repeat center;
                    background-size:100%;
                }
            }
            &:nth-child(1) {
                background-image: url(/lg5-common/images/icons/icon-process-list1.svg);
            }
            &:nth-child(2) {
                background-image: url(/lg5-common/images/icons/icon-process-list2.svg);
            }
            &:nth-child(3) {
                background-image: url(/lg5-common/images/icons/icon-process-list3.svg);
            }
            &:nth-child(4) {
                background-image: url(/lg5-common/images/icons/icon-process-list4.svg);
            }
        }
    }
    .num {
        display:block;
        font-size:14px;
        margin-bottom:8px;
    }
    .tit {
        display:block;
        font-size:16px;
        font-weight:700;
        line-height:24px;
        word-break:keep-all;
    }

    @include pc {
        padding:36px 45px 42px;
        border-radius:100px;
        .process-list {
            font-size:0;
            text-align:center;
            > li {
                display:inline-block;
                width:25%;
                padding:100px 32px 0;
                vertical-align:top;
                background-size:64px;
                background-position:center top;
                + li {
                    &:before {
                        left:-16px;
                        top:50%;
                        transform:translateY(-50%);
                        width:32px;
                        height:32px;
                    }
                }
            }
        }
    }
    @include mobile {
        padding:30px 40px;
        .process-list {
            text-align:left;
            > li {
                padding:0 0 0 60px;
                background-size:40px;
                background-position:left center;
                + li {
                    margin-top: 40px;
                    &::before {
                        width:24px;
                        height:24px;
                        transform:rotate(90deg);
                        left:calc(50% - 12px);
                        top:-32px;
                    }
                }
            }
        }
    }
}

.process-bar-wrap {
    max-width:842px;
    margin:0 auto;
    padding-top:66px;
    .bar-track {
        height:8px;
        border-radius:4px;
        background:#f4f4f4;
        .bar {
            position:relative;
            width:0;
            height:100%;
            border-radius:4px;
            background:#ec455a;
            .txt {
                position:absolute;
                bottom:100%;
                left:100%;
                padding-bottom:48px;
                color:#ec455a;
                font-size:14px;
                font-weight:700;
                line-height:20px;
                white-space:nowrap;
                background:url('/lg5-common/images/icons/icon-alarm2.svg') no-repeat 100%;
                background-size:26px 34px;
                background-position:center bottom 8px;
                transform:translateX(-50%);
            }
        }
    }
    @include mobile {
        padding:66px 20px 0;
        .bar-track {
            .bar {
                .txt {
                    padding-bottom:40px;
                    font-size:12px;
                    line-height:16px;
                    background-size:21px 87px;
                    background-position:center bottom 6px;
                }
            }
        }
    }
}